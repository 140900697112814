<template>
  <div>
    <div class="search-head">
      <a-input
        size="large"
        v-model="searchName"
        :placeholder="$t('supplierManagement.brand.list.search')"
        allowClear
        class="search-input"
        @keydown.enter="onSearch"
      />
      <a-button type="primary" @click="onSearch">
        {{ $t("button.filter") }}
      </a-button>
    </div>
    <ECTable
      showTitle
      :title="$t('supplierManagement.brand.list.title')"
      :columns="tableColumns"
      :data-source="dataList"
      bordered
      rowKey="id"
      key="id"
      :loading="loading"
      :pagination="{ total, current, pageSize }"
      @change="handleTableChange"
    >
      <div slot="tableHeaderRight" class="table-buttons">
        <EcUploadButton url="/api/product/brand/import">
          {{ $t("supplierManagement.brand.list.import") }}
        </EcUploadButton>
        <a-button type="primary" @click="onExport">
          {{ $t("supplierManagement.brand.list.export") }}
        </a-button>
        <a-button type="primary" @click="onAdd">
          {{ $t("supplierManagement.brand.list.add") }}
        </a-button>
      </div>
      <template slot="sort" slot-scope="text, record, index">
        {{ (current - 1) * pageSize + (index + 1) }}
      </template>
      <template slot="state" slot-scope="text, record">
        <span v-if="record.state === true">
           {{ $t("supplierManagement.brand.list.enable") }}
        </span>
        <span class="ec-color-danger" v-else>
           {{ $t("supplierManagement.brand.list.disabled") }}
        </span>
      </template>
      <template slot="icon" slot-scope="text">
        <img style="width: 30px;max-height: 60px;" :src="text" alt="" v-if="text" />
        <span v-else>--</span>
      </template>
      <!-- 操作列 -->
      <template slot="action" slot-scope="text, record">
        <div class="table-operations">
          <a-button type="link" class="table-btn" @click="edit(record)">
            <a-icon type="edit" />
            {{ $t("button.edit") }}
          </a-button>
          <a-popconfirm
            :title="$t('button.confirmDelete')"
            :ok-text="$t('button.yes')"
            :cancel-text="$t('button.no')"
            @confirm="onDelete(record)"
          >
            <a-button type="link" class="table-btn danger">
              <a-icon type="delete" />
              {{ $t("button.remove") }}
            </a-button>
          </a-popconfirm>
        </div>
      </template>
    </ECTable>
  </div>
</template>

<script>
import ECTable from "@/components/ECTable";
import {deleteBrand, exportBrandList, getBrandList} from "../api";
import { FETCH_CODE } from "@/config";
import { showMsg } from "@/utils";
import EcUploadButton from "@/components/EcUploadButton.vue";

export default {
  name: "ProductBrandList",
  data() {
    return {
      searchName: "",
      dataList: [],
      tableColumns: [
        {
          title: this.$t("supplierManagement.brand.list.cols.sort"),
          dataIndex: "sort",
          width: 75,
          scopedSlots: { customRender: "sort" },
          align: "center",
        },
        {
          title: this.$t("supplierManagement.brand.list.cols.brandName"),
          dataIndex: "brandName",
          width: 150,
          align: "left",
        },
        {
          title: this.$t("supplierManagement.brand.list.cols.logo"),
          dataIndex: "icon",
          width: 110,
          scopedSlots: { customRender: "icon" },
          align: "center",
        },
        {
          title: this.$t("supplierManagement.brand.list.cols.remark"),
          dataIndex: "remark",
          width: 170,
          align: "left",
          scopedSlots: { customRender: "categoryParentCode" },
        },
        {
          title: this.$t("table.createBy"),
          dataIndex: "createBy",
          width: 120,
          align: "left",
        },
        {
          title: this.$t("table.createTime"),
          dataIndex: "createTime",
          width: 160,
          align: "center",
        },
        {
          title: this.$t("table.updateBy"),
          dataIndex: "updateBy",
          width: 120,
          align: "left",
        },
        {
          title: this.$t("table.updateTime"),
          dataIndex: "updateTime",
          width: 160,
          align: "center",
        },
        {
          title: this.$t("action"),
          dataIndex: "action",
          key: "action",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 130,
        },
      ],
      current: 1,
      pageSize: 10,
      total: 0,
      loading: false,
    };
  },
  components: {
    EcUploadButton,
    ECTable,
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const res = await getBrandList({
        brandName: this.searchName,
        pageNum: this.current,
        pageSize: this.pageSize,
      });
      this.dataList = res.data.records || [];
      this.current = res.data.current;
      this.total = res.data.total;
      this.pageSize = res.data.size;
      this.loading = false;
    },
    edit(record) {
      this.$router.push({
        name: "ProductBrandEdit",
        params: {
          id: record.id,
          brandCode: record.brandCode,
          brandName: record.brandName,
          website: record.website,
          icon: record.icon,
          sort: record.sort,
          remark: record.remark,
          state: record.state ? "1" : "0",
          isRecommend: record.isRecommend ? "1" : "0",
        },
      });
    },
    async onDelete(record) {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const res = await deleteBrand({
        id: record.id,
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        this.loading = false;
        return;
      }
      showMsg({ flag: 0, msg: this.$t("showMsg.success.delete") });
      this.loading = false;
      this.getList();
    },
    onAdd() {
      this.$router.push({
        name: "ProductBrandAdd",
      });
    },
    async onExport() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const res = await exportBrandList({
        search: this.searchName,
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        this.loading = false;
        return;
      }
      this.loading = false;
    },
    onSearch() {
      this.current = 1;
      this.getList();
    },
    handleTableChange(page) {
      this.current = page.current;
      this.pageSize = page.pageSize;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.search-head {
  padding: @ec-gutter20 0;
}
.search-input {
  width: 300px;
  margin-right: @ec-gutter;
}
.table-buttons {
  &> * {
    margin-left: @ec-gutter;
  }
}
.table-operations {
  display: flex;
  justify-content: center;
  align-items: center;

  .table-btn {
    &.danger {
      color: @error-color;
    }
  }
}
</style>
