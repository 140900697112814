<template>
  <a-upload
    :name="name"
    :data="data"
    :id="id"
    :disabled="disabled"
    :multiple="multiple"
    :action="url"
    :withCredentials="true"
    :show-upload-list="false"
    :headers="headers"
    :before-upload="onBeforeUpload"
    @change="onFileChange"
  >
    <a-button type="primary">
      <slot></slot>
    </a-button>
  </a-upload>
</template>

<script>
import { cookies } from "@/config";
import { showMsg } from "@/utils";
import { fileSizeFormatter } from "@/utils/tools";

export default {
  name: "EcUploadButton",
  props: {
    // 文件列表
    fileList: Array,
    url: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: "file",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    // 文件限制类型 比如：["image/jpeg", "image/png"]
    format: {
      type: Array,
    },
    // 文件最大限制 单位：bit，比如限制1kb=1024，限制2mb=1024*1024*2=2097152
    // 默认2mb
    sizeMax: {
      type: Number,
      default: 2097152,
    },
    id: String,
    disabled: Boolean,
    data: Object,
  },
  data() {
    return {
      headers: {
        Authorization: cookies.EC_TOKEN,
      },
    };
  },
  methods: {
    onFileChange({ fileList }) {
      const _fileList = [];
      fileList.forEach((it) => {
        if (it.status === "uploading") {
          _fileList.push(it);
          return;
        }
        // 上传不成功的提示错误
        if (it.response) {
          if (it.response.code === 200) {
            _fileList.push(it);
            return;
          }
          showMsg({ flag: 2, msg: it.response.msg });
        }
      });
      this.$emit("update:fileList", _fileList);
      this.$emit("change", _fileList);
    },
    onBeforeUpload(file) {
      return new Promise((resolve, reject) => {
        // 判断文件格式
        let isFormatValid = true;
        if (this.format) {
          isFormatValid = this.format.includes(file.type);
          if (!isFormatValid) {
            showMsg({ flag: 1, msg: this.$t("file.format") });
            this.handleChange({ fileList: [] });
          }
        }

        // 判断文件最大限制
        let isSizeMaxValid = true;
        if (this.sizeMax) {
          isSizeMaxValid = file.size < this.sizeMax;
          if (!isSizeMaxValid) {
            showMsg({ flag: 1, msg: this.$t("file.sizeMax", { value: fileSizeFormatter(this.sizeMax) }) });
            this.handleChange({ fileList: [] });
          }
        }

        const valid = isFormatValid && isSizeMaxValid;
        if (valid) {
          resolve(file);
          return;
        }
        reject(file);
      });
    },
  },
  mounted() {
  },
}
</script>

<style lang="less" scoped></style>
