<template>
<div class="edit-page">
  <a-spin :spinning="loading">
    <div class="edit-page_tips">
      <span class="ec-color-danger">*</span>为必填项
    </div>
    <div class="bg-block">
      <slot />
    </div>
  </a-spin>
</div>
</template>
<script>
export default {
  props: {
    loading: Boolean,
  },
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
.bg-block {
  box-shadow: 0px 4px 4px 0px rgba(15, 113, 222, 0.06);
  border: 1px solid #e9ebfb;
  padding: 23px 22px 34px 25px;
}
.edit-page {
  &_tips {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 12px;
  }
}
</style>
