import api from "@/utils/fetch";
import { queryUrlFormatter } from "@/utils/tools";

// 供应商列表接口
export async function getSupplierList(params) {
  return await api.post("/product/supplier/page", params);
}

// 获取供应商全部列表
export async function getSupplierAll(params) {
  return await api.post("/product/supplier/list", params);
}

// 供应商删除接口
export async function delSupplier(params) {
  let url = "/product/supplier/del";
  url = queryUrlFormatter(url, {
    id: params.id,
  });
  return await api.post(url, params);
}

// 供应商导出接口
export function exportSupplier(params) {
  return api.download("/product/supplier/export", params);
}


// 品牌列表接口
export async function getBrandList(params) {
  return await api.post("/product/brand/page", params);
}

// 品牌保存接口
export async function saveBrand(params) {
  return await api.post("/product/brand/save", params);
}

// 品牌删除接口
export async function deleteBrand(params) {
  let url = "/product/brand/del";
  url = queryUrlFormatter(url, {
    id: params.id,
  });
  return await api.post(url, params);
}

// 省市区接口（parentId传空获取所有省份，传省份获取所有市区）
export async function getArea(params) {
  return await api.get("/sys/area/list", params);
}

// 导出品牌列表
export async function exportBrandList(params) {
  return api.download("/product/brand/export", params);
}

// 导入品牌列表
export async function importBrandList(params) {
  return api.post("/product/brand/import", params);
}

// 获取品牌全部列表
export async function getBrandAll(params) {
  return api.post("/product/brand/list", params);
}

// 供应商保存接口
export async function saveSupplier(params) {
  return api.post("/product/supplier/save", params);
}

// 优惠券分页
export async function pageCoupon(params) {
  return api.get("/coupon/list", params);
}

// 优惠券新增
export async function addCoupon(params) {
  return api.post("/coupon/add", params);
}

// 优惠券详情
export async function detailCoupon(params) {
  return api.get("/coupon", params);
}

// 优惠券核销
export async function cancelCoupon(params) {
  return api.post("/coupon/cancel", params);
}

// 优惠券发放
export async function grantCoupon(params) {
  return api.post("/coupon/grant", params);
}

// 优惠券修改
export async function updateCoupon(params) {
  return api.post("/coupon/update", params);
}

// 优惠券修改状态
export async function statusCoupon(params) {
  return api.post("/coupon/updateStatus", params);
}
