<template>
  <div>
    <a-modal
      class="area-modal"
      :title="$t('express.cost.modal.title')"
      :visible="visible"
      @ok="ok"
      @cancel="cancel"
      :maskClosable="false"
      :width="700"
    >
      <template v-for="area in areaList">
        <div :key="area.code" class="area-modal_item">
          <div class="item-head">
            <a-checkbox
              :value="area.code"
              :checked="area.checked"
              :indeterminate="area.indeterminate"
              @change="e => onCheckboxChange(e, area)"
            >
              {{ area.name }} ({{ calcChildrenChecked(area) }})
            </a-checkbox>
          </div>
          <div class="item-parent">
            <template v-for="province in area.children">
              <a-dropdown
                :trigger="['click', 'hover']"
                v-model="province.visible"
                :key="province.code"
                @visibleChange="v => onDropdownChange(v, province)"
                overlayClassName="item-overlay"
              >
                <a-checkbox
                  :value="province.code"
                  :checked="province.checked"
                  :indeterminate="province.indeterminate"
                  @click.stop="() => {}"
                  @change.stop="e => onCheckboxChange(e, province)"
                  class="item-block"
                >
                  {{ province.name }} ({{ calcChildrenChecked(province) }})
                  <a-icon
                    :type="!province.visible ? 'down' : 'up'"
                    class="item_dropdown-icon"
                  />
                </a-checkbox>
                <template #overlay>
                  <div class="item-overlay_bg">
                    <a-checkbox
                      v-for="city in province.children"
                      :checked="city.checked"
                      :indeterminate="city.indeterminate"
                      :key="city.code"
                      :value="city.code"
                      class="item-block"
                      @change="e => onCheckboxChange(e, city)"
                    >
                      {{ city.name }}
                    </a-checkbox>
                  </div>
                </template>
              </a-dropdown>
            </template>
          </div>
        </div>
      </template>
      <template v-if="!areaList.length">
        <div class="area-modal_item loading" v-for="area in 5" :key="area">
          <div class="item-head"></div>
          <div class="item-parent">
            <a-skeleton active :paragraph="{ rows: 2 }" />
          </div>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { getAllArea } from "@/pages/express/api";
import { FETCH_CODE } from "@/config";
import { showMsg } from "@/utils";
import { getFakeId, list2Tree, tree2List } from "@/utils/tools";

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    selected: Array,
  },
  components: {},
  data() {
    return {
      areaList: []
    };
  },
  methods: {
    // 确定
    ok() {
      const checkedList = [];
      // 全选的显示父级
      const findChecked = (children, _checkedList) => {
        for (const child of children) {
          if (child.checked && !child.indeterminate) {
            _checkedList.push(child);
            continue;
          }
          findChecked(child.children, _checkedList);
        }
      };
      findChecked(this.areaList, checkedList);
      if (!checkedList.length) {
        showMsg({
          flag: 1,
          msg: this.$t('express.cost.modal.title'),
        });
        return;
      }
      this.$emit("ok", checkedList);
    },
    // 下拉框显示隐藏
    onDropdownChange(v, item) {
      item.visible = v;
    },
    // 取消
    cancel() {
      this.$emit("update:visible", false);
    },
    // 获取地区省市列表
    async getAreaList() {
      const res = await getAllArea();
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        return;
      }
      const groupList = [];
      const formatter = item => {
        return {
          ...item,
          checked: false,
          indeterminate: false,
          visible: false,
          children: item.sub || [],
          area: item.region || "Others"
        };
      };
      // 根据地区字段给数据分组
      res.data.forEach(it => {
        const obj = formatter(it);
        const currGroup = groupList.find(group => group.name === obj.area);
        // 当前省不在地区分组 新建
        if (!currGroup) {
          const groupCode = `area-${groupList.length + 1}`;
          obj.parentCode = groupCode;
          groupList.push(
            formatter({
              level: 0,
              code: groupCode,
              id: getFakeId(),
              name: obj.area,
              sub: [obj]
            })
          );
          return;
        }
        // 当前省份在地区分组 追加
        obj.parentCode = currGroup.code;
        currGroup.children.push(obj);
      });
      // 删除区层级 只保留 地区-省-市
      const list = tree2List(groupList)
        .map(it => {
          return {
            ...it,
            children: []
          };
        })
        .filter(it => it.level < 3);
      this.areaList = list2Tree(list, {
        keyName: "code",
        parentKeyName: "parentCode"
      });
    },
    // 地区省市勾选事件
    onCheckboxChange(e) {
      const { value, checked } = e.target;
      const areaList = this.areaList.slice();

      // 子级选中状态处理
      const handleChildrenChecked = (_children, _checked) => {
        for (const child of _children) {
          child.checked = _checked;
          handleChildrenChecked(child.children, _checked);
        }
      };
      const handleChecked = (children, code, _checked) => {
        for (const child of children) {
          if (child.code === code) {
            child.checked = _checked;
            handleChildrenChecked(child.children, _checked);
            break;
          }
          handleChecked(child.children, code, _checked);
        }
      };
      handleChecked(areaList, value, checked);

      // 递归获取所有父级节点
      // 排序：市 - 省 - 地区
      const nodeList = [];
      const findParent = (children, code, _nodeList) => {
        for (const child of children) {
          if (child.code === code) {
            _nodeList.push(child);
            return true;
          }
          const found = findParent(child.children, code, _nodeList);
          if (found) {
            _nodeList.push(child);
            return true;
          }
        }
        return false;
      };
      findParent(areaList, value, nodeList);

      // 父级选中状态处理
      for (const parent of nodeList.slice(1, nodeList.length)) {
        // 全选
        if (parent.children.every(it => it.checked)) {
          parent.checked = true;
          parent.indeterminate = false;
          continue;
        }
        // 全不选
        if (!parent.children.some(it => it.checked || it.indeterminate)) {
          parent.checked = false;
          parent.indeterminate = false;
          continue;
        }
        // 部分选择
        parent.checked = true;
        parent.indeterminate = true;
      }

      this.areaList = areaList;
    },
    // 计算旗下选中子级个数
    calcChildrenChecked(item) {
      return item.children.filter(it => it.checked || it.indeterminate).length;
    }
  },
  mounted() {
    this.getAreaList().then(() => {
      // 处理选中
      this.selected.forEach((it) => {
        this.onCheckboxChange({ target: { value: it.code, checked: true } });
      });
    });
  }
};
</script>

<style lang="less" scoped>
.area-modal {
  /deep/.ant-modal {
    top: 20px;
  }

  &_item {
    background: rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    padding: 8px;
    border-radius: 4px;
    &.loading {
      background: unset;
    }

    .item {
      &-head {
        margin-bottom: 10px;
      }

      &-parent {
        display: flex;
        flex-wrap: wrap;
        column-gap: 40px;
      }
    }
  }
}

.item-overlay {
  .ant-dropdown-content {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 4px;
    max-height: 300px;
    overflow-y: auto;
  }
  &_bg {
    background-color: #fff;
  }
}

.item-block {
  display: block;
  margin-left: 0 !important;

  &:not(&:last-child) {
    margin-bottom: 6px;
  }
}
</style>
