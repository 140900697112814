<template>
  <div>
    <ListFilter
      ref="listFilterRef"
      :formList="formList"
      @filter="onSearch"
      @reset="onReset"
      :loading="loading"
    >
      <a-form-model-item
          :label="$t('supplierManagement.supplier.list.location')"
          class="supplier-area"
          :labelCol="{ span: 10 }"
          :wrapperCol="{ span: 14 }"
      >
        <a-select
          :getPopupContainer="trigger => trigger.parentNode"
          size="large"
          v-model="form.province"
          :placeholder="$t('supplierManagement.supplier.list.province')"
          allowClear
          @change="selectProvince"
        >
          <a-select-option
            v-for="op in provinceList"
            :key="op.code"
            :value="op.code"
          >
            {{ op.name }}
          </a-select-option>
        </a-select>
        <a-select
          :getPopupContainer="trigger => trigger.parentNode"
          size="large"
          v-model="form.city"
          :placeholder="$t('supplierManagement.supplier.list.city')"
          allowClear
          @change="selectCity"
        >
          <a-select-option v-for="op in cityList" :key="op.code" :value="op.code">
            {{ op.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
    </ListFilter>
    <ECTable
      showTitle
      :title="$t('supplierManagement.supplier.title')"
      :columns="tableColumns"
      :data-source="dataList"
      bordered
      :loading="loading"
      :pagination="{ total, current, pageSize }"
      @change="handleTableChange"
    >
      <template slot="index" slot-scope="text, item, index">
        {{ index + 1 }}
      </template>
      <div slot="tableHeaderRight" class="table-buttons">
        <a-button type="primary" @click="onAdd">
          {{ $t('supplierManagement.supplier.list.add') }}
        </a-button>
        <EcUploadButton url="/api/product/supplier/import">
          {{ $t('supplierManagement.supplier.list.import') }}
        </EcUploadButton>
        <a-button type="primary" @click="onExport">
          {{ $t('supplierManagement.supplier.list.export') }}
        </a-button>
      </div>
      <!-- 操作列 -->
      <template slot="action" slot-scope="text, record">
        <div class="table-operations">
          <a-button type="link" class="table-btn" @click="edit(record)">
            <i class="iconfont icon-edit table-icon-center"></i>
            {{ $t("button.edit") }}
          </a-button>
          <a-popconfirm
            :title="$t('button.confirmDelete')"
            :ok-text="$t('button.yes')"
            :cancel-text="$t('button.no')"
            @confirm="onDelete(record)"
          >
            <a-button type="link" class="table-btn danger">
              <a-icon type="delete" />
              {{ $t("button.remove") }}
            </a-button>
          </a-popconfirm>
        </div>
      </template>
    </ECTable>
  </div>
</template>

<script>
import ListFilter from "@/pages/home/components/ListFilter";
import ECTable from "@/components/ECTable";
import {getSupplierList, getArea, delSupplier, exportSupplier} from "./api";
import Vue from "vue";
import { payMethodList } from "./data";
import {trim} from "lodash";
import {FETCH_CODE} from "@/config";
import {showMsg} from "@/utils";
import EcUploadButton from "@/components/EcUploadButton.vue";

const tableColumns = [
  {
    title: Vue.prototype.$t('supplierManagement.supplier.list.cols.sort'),
    key: "index",
    width: 75,
    scopedSlots: { customRender: "index" },
    align: "center",
  },
  {
    title: Vue.prototype.$t('supplierManagement.supplier.list.cols.number'),
    dataIndex: "supplierCode",
    width: 130,
    align: "left",
  },
  {
    title: Vue.prototype.$t('supplierManagement.supplier.list.cols.name'),
    dataIndex: "supplierName",
    width: 140,
    align: "left",
  },
  {
    title: Vue.prototype.$t('supplierManagement.supplier.list.cols.acct'),
    dataIndex: "contactPhone",
    width: 170,
    align: "left",
  },
  {
    title: Vue.prototype.$t('supplierManagement.supplier.list.cols.email'),
    dataIndex: "contactEmail",
    width: 210,
    align: "left",
  },
  {
    title: Vue.prototype.$t('supplierManagement.supplier.list.cols.person'),
    dataIndex: "contact",
    width: 100,
    align: "left",
  },
  {
    title: Vue.prototype.$t('supplierManagement.supplier.list.cols.method'),
    dataIndex: "settlementType",
    width: 110,
    align: "center",
  },
  {
    title: Vue.prototype.$t("table.createBy"),
    dataIndex: "createBy",
    width: 160,
    align: "left",
  },
  {
    title: Vue.prototype.$t("table.createTime"),
    dataIndex: "createTime",
    width: 160,
    align: "center",
  },
  {
    title: Vue.prototype.$t("table.updateBy"),
    dataIndex: "updateBy",
    width: 160,
    align: "left",
  },
  {
    title: Vue.prototype.$t("table.updateTime"),
    dataIndex: "updateTime",
    width: 160,
    align: "center",
  },
  {
    title: Vue.prototype.$t("action"),
    dataIndex: "action",
    key: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
    width: 130,
    fixed: "right",
  },
];
export default {
  components: {
    EcUploadButton,
    ListFilter,
    ECTable,
  },
  data() {
    return {
      formList: [
        {
          label: this.$t('supplierManagement.supplier.list.cols.number'),
          key: "number",
          type: "input",
        },
        {
          label: this.$t('supplierManagement.supplier.list.cols.name'),
          key: "name",
          type: "input",
        },
        {
          label: this.$t('supplierManagement.supplier.list.cols.location'),
          type: "slot",
        },
        {
          label: this.$t('supplierManagement.supplier.list.cols.person'),
          key: "person",
          type: "input",
        },
        // {
        //   label: this.$t('supplierManagement.supplier.list.cols.method'),
        //   key: "status",
        //   type: "select",
        //   options: payMethodList,
        // },
      ],
      tableColumns,
      dataList: [],
      total: 0,
      current: 1,
      pageSize: 10,
      loading: false,
      form: {
        province: undefined,
        city: undefined,
      },
      payMethodList,
      provinceList: [], //省份列表
      cityList: [], // 城市列表
      exportLoading: false,
    };
  },
  mounted() {
    this.getList();
    this.getProvinceList();
  },
  methods: {
    handleTableChange() {},
    onSearch(filterForm) {
      this.current = 1;
      this.getList(filterForm);
    },
    onReset() {
      this.form = {
        province: undefined,
        city: undefined,
      };
    },
    async getList(filterForm = {}) {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const params = {
        // 供应商名称（模糊）、供应商编码（精准）
        search: trim(filterForm.number) || trim(filterForm.name) || undefined,
        // 联系人(名称、手机号、邮箱、地址等模糊)
        contact: trim(filterForm.person) || undefined,
        // 供应商所属地（省、市）(模糊)
        suppilerTerritory: undefined,
        pageNum: this.current,
        pageSize: this.pageSize,
        state: true,
      };

      if (this.form.province) {
        const curr = this.provinceList.find((it) => it.code === this.form.province);
        params.suppilerTerritory = curr?.name;
      }
      if (this.form.city) {
        const curr = this.cityList.find((it) => it.code === this.form.city);
        params.suppilerTerritory = curr?.name;
      }
      const res = await getSupplierList(params);
      const dataList = res.data.records || [];
      this.dataList = dataList.map((it) => {
        return {
          ...it,
          key: it.id,
        };
      });
      this.loading = false;
    },
    onAdd() {
      this.$router.push({
        name: "SupplierAdd",
      });
    },
    async onExport() {
      if (this.exportLoading) return;
      this.exportLoading = true;
      const params = {
        contact: trim(this.form.person) || undefined,
        search: trim(this.form.number) || trim(this.form.name) || undefined,
        suppilerTerritory: undefined,
        state: true,
      };
      if (this.form.province) {
        const curr = this.provinceList.find((it) => it.code === this.form.province);
        params.suppilerTerritory = curr?.name;
      }
      if (this.form.city) {
        const curr = this.cityList.find((it) => it.code === this.form.city);
        params.suppilerTerritory = curr?.name;
      }
      const res = await exportSupplier(params);
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
      }
      this.exportLoading = false;
    },
    edit(record) {
      this.$router.push({
        name: "SupplierEdit",
        params: record,
      });
    },
    async getProvinceList() {
      const res = await getArea({});
      this.provinceList = res.data || [];
    },
    async getCityList() {
      this.cityList = [];
      this.form.city = undefined;
      const res = await getArea({
        parentCode: this.form.province,
      });
      this.cityList = res.data || [];
    },
    selectProvince(value) {
      if (!value) {
        this.form.province = undefined;
        this.form.city = undefined;
        this.cityList = [];
        return;
      }
      this.form.province = value;
      this.getCityList();
    },
    selectCity(value) {
      if (!value) {
        this.form.city = undefined;
        return;
      }
      this.form.city = value;
    },
    async onDelete(record) {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const res = await delSupplier({
        id: record.id,
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        this.loading = false;
        return;
      }
      showMsg({ flag: 0, msg: this.$t("showMsg.success.delete") });
      this.loading = false;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.table-buttons {
  & > * {
    margin-left: @ec-gutter;
  }
}
.supplier-area {
  display: flex;
  flex-wrap: nowrap;
}
/deep/.supplier-area {
  .ant-form-item-children {
    display: flex;
    flex-wrap: nowrap;
    gap: 10px;
  }
  .ant-form-item-label {
    width: 35%;
  }
}
.table-operations {
  display: flex;
  justify-content: center;
  align-items: center;

  .table-btn {
    &.danger {
      color: @error-color;
    }
  }
}
</style>
