<script>
import T from "ant-design-vue/es/table/Table";
const props = Object.assign({}, T.props);

export default {
  name: "OrderDetailTable",
  props,
  data() {
    return {};
  },
  methods: {},
  render() {
    return (
      <div class="order-detail-table">
        <a-table
          {...this.$props}
          scopedSlots={this.$scopedSlots}
          columns={this.columns}
          data-source={this.dataSource}
          pagination={false}
          bordered
        >
          {Object.keys(this.$slots).map((name) => (
            <template slot={name}>{this.$slots[name]}</template>
          ))}
        </a-table>
      </div>
    );
  },
};
</script>

<style lang="less" scoped>
.order-detail-table {
  /deep/ .ant-table table {
    border-radius: 0;
  }
  /deep/.ant-table-thead {
    > tr {
      > th {
        background: #f2f5fb;
      }
      &:first-child > th:first-child {
        border-top-left-radius: 0;
      }
      &:first-child > th:last-child {
        border-top-right-radius: 0;
      }
    }
  }
}
</style>
