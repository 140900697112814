<template>
  <a-upload
    list-type="picture-card"
    class="ec-form-uploader"
    action="/api/sys/file/uploadFile"
    name="file"
    :fileList="fileList"
    :data="{ name: fileName }"
    :headers="{ Authorization: cookies.EC_TOKEN }"
    :withCredentials="true"
    :show-upload-list="{ showPreviewIcon: false, showRemoveIcon: true }"
    :before-upload="beforeUpload"
    @change="handleChange"
    :id="id"
    :disabled="disabled"
  >
    <div v-if="fileList.length < numberMax">
      <a-icon type="plus" />
      <div class="ant-upload-text">
        {{ $t("file.upload") }}
      </div>
    </div>
  </a-upload>
</template>

<script>
import { showMsg } from "@/utils";
import { cookies } from "@/config";
import { fileSizeFormatter } from "@/utils/tools";

export default {
  name: "EcFormUpload",
  computed: {
    cookies() {
      return cookies
    }
  },
  props: {
    // 文件列表
    fileList: Array,
    // 上传文件名
    fileName: String,
    // 文件限制类型 比如：["image/jpeg", "image/png"]
    format: {
      type: Array,
    },
    // 文件最大限制 单位：bit，比如限制1kb=1024，限制2mb=1024*1024*2=2097152
    // 默认2mb
    sizeMax: {
      type: Number,
      default: 2097152,
    },
    // 限制上传个数
    numberMax: {
      type: Number,
      default: 1,
    },
    id: String,
    disabled: Boolean,
  },
  data() {
    return {};
  },
  methods: {
    handleChange({ fileList }) {
      const _fileList = [];
      fileList.forEach((it) => {
        if (it.url) {
          _fileList.push({
            ...it,
            response: {
              code: 200,
              data: it.url,
            },
          });
          return;
        }
        if (it.status !== "done") {
          _fileList.push(it);
          return;
        }
        // 上传不成功的提示错误
        if (it.response) {
          if (it.response.code === 200) {
            _fileList.push(it);
            return;
          }
          showMsg({ flag: 1, msg: it.response.msg });
        }
      });
      this.$emit("update:fileList", _fileList);
      this.$emit("change", _fileList);
    },
    beforeUpload(file) {
      return new Promise((resolve, reject) => {
        // 判断文件格式
        let isFormatValid = true;
        if (this.format) {
          isFormatValid = this.format.includes(file.type);
          if (!isFormatValid) {
            showMsg({ flag: 1, msg: this.$t("file.format") });
            this.handleChange({ fileList: [] });
          }
        }

        // 判断文件最大限制
        let isSizeMaxValid = true;
        if (this.sizeMax) {
          isSizeMaxValid = file.size < this.sizeMax;
          if (!isSizeMaxValid) {
            showMsg({ flag: 1, msg: this.$t("file.sizeMax", { value: fileSizeFormatter(this.sizeMax) }) });
            this.handleChange({ fileList: [] });
          }
        }

        const valid = isFormatValid && isSizeMaxValid;
        if (valid) {
          resolve(file);
          return;
        }
        reject(file);
      });
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
