// 类型 1:折扣,2:满减,3:运费券
export const Coupon_Type = [
  {
    id: 1,
    dictType: "coupon_type",
    dictCode: 1,
    dictName: "折扣",
  },
  {
    id: 2,
    dictType: "coupon_type",
    dictCode: 2,
    dictName: "满减",
    dictIcon: "",
  },
  {
    id: 3,
    dictType: "coupon_type",
    dictCode: 3,
    dictName: "运费券",
    dictIcon: "",
  },
];

// 状态 1 已启用；2 已过期; 3 未启用
export const Coupon_Status = [
  {
    id: 1,
    dictType: "coupon_status",
    dictCode: 1,
    dictName: "已启用",
  },
  {
    id: 2,
    dictType: "coupon_status",
    dictCode: 2,
    dictName: "已过期",
    dictIcon: "",
  },
  {
    id: 3,
    dictType: "coupon_status",
    dictCode: 3,
    dictName: "未启用",
    dictIcon: "",
  },
];

// 时间限制类型 1 天数；2 开始结束时间
export const Coupon_Limit = [
  {
    id: 1,
    dictType: "coupon_limit",
    dictCode: 1,
    dictName: "天数",
  },
  {
    id: 2,
    dictType: "coupon_limit",
    dictCode: 2,
    dictName: "开始结束时间",
    dictIcon: "",
  },
];

// 供应商级别
export const SupplierLevel = [
  {
    id: 1,
    dictType: "supplier_level",
    dictCode: "A",
    dictName: "A",
  },
  {
    id: 2,
    dictType: "supplier_level",
    dictCode: "B",
    dictName: "B",
  },
  {
    id: 3,
    dictType: "supplier_level",
    dictCode: "C",
    dictName: "C",
  },
  {
    id: 4,
    dictType: "supplier_level",
    dictCode: "D",
    dictName: "D",
  },
];
