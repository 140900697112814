<template>
  <ECEditPage  :loading="loading" class="coupon-edit">
    <a-form-model
      ref="couponFormRef"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item
        :label="$t('supplierManagement.coupon.form.name.label')"
        prop="name"
      >
        <a-input
          v-model="form.name"
          :placeholder="$t('supplierManagement.coupon.form.name.placeholder')"
        />
      </a-form-model-item>
      <a-form-model-item
        :label="$t('supplierManagement.coupon.form.couponId.label')"
        prop="couponId"
      >
        <a-input
          v-model="form.couponId"
          :placeholder="$t('form.auto')"
          disabled
        />
      </a-form-model-item>
      <a-form-model-item
        :label="$t('supplierManagement.coupon.form.type.label')"
        prop="type"
      >
        <a-select
          :getPopupContainer="trigger => trigger.parentNode"
          v-model="form.type"
          :placeholder="$t('supplierManagement.coupon.form.type.placeholder')"
          allowClear
        >
          <a-select-option
            v-for="op in typeList"
            :key="op.dictCode"
            :value="op.dictCode"
          >
            {{ op.dictName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item
        :label="$t('supplierManagement.coupon.form.discount.label1')"
        prop="discount"
        v-if="form.type === typeList[0].dictCode"
      >
        <a-input-number
          v-model="form.discount"
          :min="0"
          :max="100"
          :precision="0"
          :placeholder="$t('supplierManagement.coupon.form.discount.placeholder1')"
        />
      </a-form-model-item>
      <a-form-model-item
        :label="$t('supplierManagement.coupon.form.discount.label2')"
        prop="discount"
        v-else
      >
        <a-input-number
          v-model="form.discount"
          :min="0"
          :precision="2"
          :placeholder="$t('supplierManagement.coupon.form.discount.placeholder2')"
        />
      </a-form-model-item>
      <a-form-model-item
        :label="$t('supplierManagement.coupon.form.total.label')"
        prop="total"
      >
        <a-input-number
          v-model="form.total"
          :min="0"
          :precision="0"
          :placeholder="$t('supplierManagement.coupon.form.total.placeholder')"
        />
      </a-form-model-item>
      <a-form-model-item
        :label="$t('supplierManagement.coupon.form.min.label')"
        prop="min"
      >
        <a-input-number
          v-model="form.min"
          :min="0"
          :precision="2"
          :placeholder="$t('supplierManagement.coupon.form.min.placeholder')"
        />
      </a-form-model-item>
      <a-form-model-item
        :label="$t('supplierManagement.coupon.form.status.label')"
        prop="status"
      >
        <a-select
          :getPopupContainer="trigger => trigger.parentNode"
          v-model="form.status"
          :placeholder="$t('supplierManagement.coupon.form.status.placeholder')"
          allowClear
        >
          <a-select-option
            v-for="op in statusList"
            :key="op.dictCode"
            :value="op.dictCode"
          >
            {{ op.dictName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item
        :label="$t('supplierManagement.coupon.form.days.label')"
        prop="days"
      >
        <a-input-number
          v-model="form.days"
          :min="0"
          :precision="0"
          :placeholder="$t('supplierManagement.coupon.form.days.placeholder')"
        />
      </a-form-model-item>
      <a-form-model-item
        :label="$t('supplierManagement.coupon.form.startTime.label')"
        prop="startTime"
      >
        <a-date-picker
          :show-time="true"
          allowClear
          v-model="form.startTime"
          :inputReadOnly="true"
          :placeholder="$t('supplierManagement.coupon.form.startTime.placeholder')"
        />
      </a-form-model-item>
      <a-form-model-item
        :label="$t('supplierManagement.coupon.form.endTime.label')"
        prop="endTime"
      >
        <a-date-picker
          :show-time="true"
          allowClear
          v-model="form.endTime"
          :inputReadOnly="true"
          :placeholder="$t('supplierManagement.coupon.form.endTime.placeholder')"
        />
      </a-form-model-item>
      <a-form-model-item
        ref="content"
        prop="content"
        :label="$t('supplierManagement.coupon.form.content.label')"
      >
        <a-textarea
          v-model="form.content"
          :auto-size="{ minRows: 3, maxRows: 5 }"
          :placeholder="$t('supplierManagement.coupon.form.content.placeholder')"
        />
      </a-form-model-item>
    </a-form-model>
    <div class="buttons">
      <a-button @click="back">
        {{ $t("button.back") }}
      </a-button>
      <a-button type="primary" @click="submit">
        {{ $t("button.save") }}
      </a-button>
    </div>
  </ECEditPage>
</template>

<script>
import { addCoupon, detailCoupon, updateCoupon } from "../api";
import { FETCH_CODE } from "@/config/fetch";
import { showMsg } from "@/utils";
import { Coupon_Status, Coupon_Type } from "@/pages/supplier/config";
import ECEditPage from "@/components/ECEditPage.vue";
import moment from "moment";
import { DateFormatFull } from "@/config";

export default {
  name: "ProductBrandEdit",
  components: { ECEditPage },
  data() {
    return {
      form: {
        type: Coupon_Type[0].dictCode,
        status: Coupon_Status[0].dictCode,
      },
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },
      rules: {
        name: [
          {
            required: true,
            message: this.$t("supplierManagement.coupon.form.name.required"),
          },
          {
            max: 100,
            message: this.$t("supplierManagement.coupon.form.name.max", { length: 100 }),
          },
        ],
        type: [
          {
            required: true,
            message: this.$t("supplierManagement.coupon.form.type.required"),
          },
        ],
        total: [
          {
            required: true,
            message: this.$t("supplierManagement.coupon.form.total.required"),
          },
        ],
        status: [
          {
            required: true,
            message: this.$t("supplierManagement.coupon.form.status.required"),
          },
        ],
        days: [
          {
            required: true,
            message: this.$t("supplierManagement.coupon.form.days.required"),
          },
        ],
        discount: [
          {
            required: true,
            message: this.$t("supplierManagement.coupon.form.discount.required"),
          },
        ],
        content: [
          {
            required: true,
            message: this.$t("supplierManagement.coupon.form.content.required"),
          },
          {
            max: 500,
            message: this.$t("supplierManagement.coupon.form.content.max", { length: 500 }),
          },
        ],
      },
      loading: false,
      typeList: Coupon_Type,
      statusList: Coupon_Status,
    };
  },
  methods: {
    submit() {
      if (this.loading) return;
      this.$refs.couponFormRef.validate(async (valid) => {
        if (valid) {
          this.loading = true;
          const params = {
            ...this.form,
            startTime: this.form.startTime ? moment(this.form.startTime).format(DateFormatFull) : null,
            endTime: this.form.endTime ? moment(this.form.endTime).format(DateFormatFull) : null,
          };
          if (this.isEdit) {
            const res = await updateCoupon(params);
            if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
              showMsg(res);
              this.loading = false;
              return;
            }
            showMsg({
              flag: 0,
              msg: this.$t("showMsg.success.save"),
            });
          } else {
            const res = await addCoupon(params);
            if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
              showMsg(res);
              this.loading = false;
              return;
            }
            showMsg({
              flag: 0,
              msg: this.$t("showMsg.success.add"),
            });
          }
          this.loading = false;
          this.back();
        }
      });
    },
    back() {
      this.reset();
      this.$router.back();
    },
    reset() {
      this.form = {
        ...this.form,
        name: null,
        type: this.typeList[0].dictCode,
        status: this.statusList[0].dictCode,
        total: null,
        discount: null,
        min: null,
        days: null,
        startTime: null,
        endTime: null,
        content: null,
      };
    },
    async init() {
      this.loading = true;
      const { id } = this.$route.params;
      if (!id) {
        this.loading = false;
        return;
      }
      const res = await detailCoupon({ id });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        this.loading = false;
        this.back();
        return;
      }
      this.form = {
        ...this.form,
        ...res.data,
      };
      this.loading = false;
    },
  },
  computed: {
    isEdit () {
      return !!this.$route.params.id;
    }
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="less" scoped>
.coupon-edit {
  padding-top: 60px;
}
.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  & > * {
    margin-right: @ec-gutter;
  }
}
.ant-input-number {
  width: 100%;
}
.product-form-tips {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  margin-right: 25px;
  position: absolute;
  z-index: 1;
  right: -255px;
  color: #A6AAB2;

  &.file {
    right: -30px;
  }
}
.product-form-item-tips {
  /deep/.ant-form-item-children {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
</style>
