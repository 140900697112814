<template>
  <a-modal
    :title="$t('express.cost.modal.title')"
    :visible="visible"
    @ok="ok"
    @cancel="cancel"
    :maskClosable="false"
  >
    <a-form-model
      :model="form"
      ref="form"
      :rules="rules"
      :labelCol="labelCol"
      :wrapperCol="wrapperCol"
      labelAlign="right"
      :colon="false"
    >
      <a-form-model-item
        :label="$t('express.cost.filter.area')"
        prop="province"
      >
        <a-select
          :getPopupContainer="trigger => trigger.parentNode"
          size="large"
          v-model="form.province"
          :placeholder="$t('supplierManagement.supplier.list.province')"
          allowClear
          style="width: 150px;margin-right: 10px"
          @change="value => selectArea(value, 'province')"
        >
          <a-select-option
            v-for="op in provinceList"
            :key="op.code"
            :value="op.code"
          >
            {{ op.name }}
          </a-select-option>
        </a-select>
        <a-select
          :getPopupContainer="trigger => trigger.parentNode"
          size="large"
          v-model="form.city"
          :placeholder="$t('supplierManagement.supplier.list.city')"
          allowClear
          style="width: 150px"
          @change="value => selectArea(value, 'city')"
        >
          <a-select-option
            v-for="op in cityList"
            :key="op.code"
            :value="op.code"
          >
            {{ op.name }}
          </a-select-option>
        </a-select>
        <!--          <a-select-->
        <!--              size="large"-->
        <!--              v-model="form.area"-->
        <!--              :placeholder="$t('supplierManagement.supplier.list.area')"-->
        <!--              allowClear-->
        <!--              style="width: 150px"-->
        <!--          >-->
        <!--            <a-select-option v-for="op in areaList" :key="op.code" :value="op.code">-->
        <!--              {{ op.name }}-->
        <!--            </a-select-option>-->
        <!--          </a-select>-->
      </a-form-model-item>
      <a-form-model-item
        :label="$t('express.cost.filter.supplier')"
        prop="supplierCode"
      >
        <a-select
          :getPopupContainer="trigger => trigger.parentNode"
          v-model="form.supplierCode"
          :placeholder="$t('select') + $t('express.cost.modal.supplier')"
        >
          <a-select-option
            v-for="i in supplier"
            :value="i.supplierCode"
            :key="i.supplierCode"
            >{{ i.supplierName }}</a-select-option
          >
        </a-select>
      </a-form-model-item>
      <a-form-model-item
        :label="$t('express.cost.filter.amount')"
        prop="amount"
      >
        <a-input
          v-model="form.amount"
          :placeholder="$t('input') + $t('express.cost.filter.amount')"
        />
      </a-form-model-item>
      <a-form-model-item :label="$t('express.company.list.cols.start')">
        <a-switch v-model="!form.delFlag" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import { getArea } from "@/pages/supplier/api";
import { REG_DIGIT } from "@/config";

export default {
  name: "CostModal",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: () => {}
    },
    supplier: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      form: {
        amount: "",
        delFlag: false,
        areaCode: "",
        supplierCode: undefined,
        province: undefined,
        city: undefined
      },
      rules: {
        supplierCode: [
          {
            required: true,
            trigger: "blur",
            message: this.$t("select") + this.$t("express.cost.modal.supplier")
          }
        ],
        province: [
          {
            required: true,
            trigger: "blur",
            message:
              this.$t("select") +
              this.$t("right") +
              this.$t("supplierManagement.supplier.list.area")
          }
        ],
        amount: [
          {
            pattern: REG_DIGIT,
            required: true,
            trigger: "blur",
            message: this.$t("input") + this.$t("express.cost.filter.amount")
          }
        ],
        delFlag: [{ required: true, trigger: "blur", message: "" }]
      },
      cityList: [],
      provinceList: [],
      labelCol: { span: 6 },
      wrapperCol: { span: 18 }
    };
  },
  methods: {
    ok() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$emit("getForm", this.form);
        }
      });
    },
    cancel() {
      this.form = {};
      this.$refs.form.resetFields();
      this.$emit("update:visible", false);
    },
    selectArea(value, type) {
      if (type === "province") {
        if (!value) {
          this.form.province = undefined;
          this.form.city = undefined;
          this.cityList = [];
          return;
        }
        this.form.province = value;
        this.form.city = undefined;
        this.getAreaList(value, type);
        return;
      }
      if (type === "city") {
        if (!value) {
          this.form.city = undefined;
          return;
        }
        this.form.city = value;
        return;
      }
    },
    async getAreaList(value, type) {
      const params = !value ? {} : { parentCode: value };
      const res = await getArea(params);
      const key = type === "province" ? "cityList" : "provinceList";
      this[key] = res.data || [];
    },
    init() {
      const {
        supplierCode,
        provinceCode,
        amount,
        delFlag,
        areaCode
      } = this.item;
      this.form.supplierCode = supplierCode;
      this.form.city = areaCode;
      this.form.province = provinceCode;
      this.form.amount = amount;
      this.form.delFlag = delFlag;
      this.getAreaList(provinceCode, "province");
    }
  },
  computed: {
    title() {
      return `${
        this.isEdit ? this.$t("button.edit") : this.$t("button.add")
      }${this.$t("express.cost.modal.title")}`;
    }
  },
  async mounted() {
    if (this.isEdit) {
      this.init();
    }
    this.getAreaList();
  }
};
</script>
<style lang="less" scoped></style>
