<template>
  <div class="cost">
    <div class="cost-head">
      <a-form-model :model="filterForm" layout="inline" :colon="false">
        <a-row :gutter="[24, 24]">
          <a-col :span="8">
            <a-form-model-item
              :label="$t('express.cost.filter.template')"
              :labelCol="{ span: 8 }"
              :wrapperCol="{ span: 16 }"
            >
              <a-input
                v-model="filterForm.templateName"
                :maxLength="20"
                :placeholder="$t('express.cost.filter.template')"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item
              :label="$t('express.cost.filter.supplier')"
              :labelCol="{ span: 8 }"
              :wrapperCol="{ span: 16 }"
            >
              <a-select
                :getPopupContainer="trigger => trigger.parentNode"
                size="large"
                v-model="filterForm.supplier"
                :placeholder="$t('express.cost.filter.supplier')"
                allowClear
              >
                <a-select-option
                  v-for="op in supplierList"
                  :key="op.supplierCode"
                  :value="op.supplierCode"
                >
                  {{ op.supplierName }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <div class="filter-btn">
              <a-button @click="onReset" class="reset-btn" :disabled="loading">
                {{ $t("button.reset") }}
              </a-button>
              <a-button
                type="primary"
                @click="onSearch"
                class="search-btn"
                :disabled="loading"
              >
                {{ $t("button.filter") }}
              </a-button>
            </div>
          </a-col>
          <!-- <a-col :span="12">
            <a-form-model-item
              :labelCol="{ span: 6 }"
              :wrapperCol="{ span: 16 }"
              :label="$t('supplierManagement.supplier.list.location')"
              class="supplier-area"
            >
              <a-select
                :getPopupContainer="trigger => trigger.parentNode"
                size="large"
                v-model="filterForm.province"
                :placeholder="$t('supplierManagement.supplier.list.province')"
                allowClear
                style="width: 150px"
                @change="value => selectArea(value, 'province')"
              >
                <a-select-option
                  v-for="op in provinceList"
                  :key="op.code"
                  :value="op.code"
                >
                  {{ op.name }}
                </a-select-option>
              </a-select>
              <a-select
                :getPopupContainer="trigger => trigger.parentNode"
                size="large"
                v-model="filterForm.city"
                :placeholder="$t('supplierManagement.supplier.list.city')"
                allowClear
                style="width: 150px"
                @change="value => selectArea(value, 'city')"
              >
                <a-select-option
                  v-for="op in cityList"
                  :key="op.code"
                  :value="op.code"
                >
                  {{ op.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col> -->
        </a-row>
      </a-form-model>
    </div>
    <ECTable
      showTitle
      :title="$t('express.cost.modal.title')"
      :data-source="dataList"
      :columns="columns"
      :pagination="{ pageSize, total, current }"
      :loading="loading"
      @change="onTableChange"
      key="id"
      rowKey="id"
    >
      <template slot="tableHeaderRight">
        <a-button type="primary" @click="add">{{ $t("button.add") }}</a-button>
      </template>
      <template slot-scope="text, record" slot="action">
        <div class="table-operations">
          <a-button type="link" class="table-btn" @click="onEdit(record)">
            <a-icon type="edit" />
            {{ $t("button.edit") }}
          </a-button>
          <a-popconfirm
            :title="$t('button.confirmDelete')"
            :ok-text="$t('button.yes')"
            :cancel-text="$t('button.no')"
            @confirm="onDelete(record)"
          >
            <a-button type="link" class="table-btn danger">
              <a-icon type="delete" />
              {{ $t("button.remove") }}
            </a-button>
          </a-popconfirm>
        </div>
      </template>
    </ECTable>
    <template v-if="isShowModal">
      <CostModal
        :visible.sync="isShowModal"
        @getForm="getForm"
        :isEdit="isEdit"
        :item="item"
        :supplier="supplierList"
      />
    </template>
  </div>
</template>

<script>
import ECTable from "@/components/ECTable";
import {
  addExpressCost,
  editExpressCost,
  getExpressCostList,
  removeExpressCost
} from "@/pages/express/api";
import { FETCH_CODE } from "@/config";
import CostModal from "@/pages/express/cost/Modal.vue";
import { getArea, getSupplierAll } from "@/pages/supplier/api";
import { showMsg } from "@/utils";

export default {
  name: "ExpressCostList",
  components: { CostModal, ECTable },
  computed: {},
  data() {
    return {
      dataList: [],
      columns: [
        {
          dataIndex: "areaName",
          key: "areaName",
          align: "left",
          title: this.$t("express.cost.filter.template"),
          width: 120
        },
        {
          dataIndex: "amount",
          key: "amount",
          title: this.$t("express.cost.cols.goodsAmount"),
          align: "right",
          width: 120
        },
        {
          dataIndex: "supplierName",
          key: "supplierName",
          title: this.$t("productManagement.product.list.supplier"),
          align: "left",
          width: 120
        },
        {
          dataIndex: "areaName",
          key: "areaName",
          title: this.$t("express.cost.cols.area"),
          align: "left"
        },
        {
          dataIndex: "amount",
          key: "money",
          align: "right",
          title: this.$t("express.cost.edit.freight"),
          width: 120
        },
        {
          key: "action",
          align: "center",
          title: this.$t("action"),
          scopedSlots: { customRender: "action" },
          width: 120
        }
      ],
      current: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      isShowModal: false,
      isEdit: false,
      filterForm: {
        city: undefined,
        province: undefined,
        supplier: undefined,
        templateName: ""
      },
      item: {},
      supplierList: [],
      provinceList: [],
      cityList: [],
      formList: [
        {
          label: this.$t("express.cost.filter.template"),
          key: "supplierCode",
          type: "slot",
          slotName: "supplierCode"
        },
        {
          label: this.$t("express.cost.filter.area"),
          key: "areaCode",
          type: "slot",
          slotName: "areaCode"
        }
      ]
    };
  },
  methods: {
    onSearch() {
      this.current = 1;
      this.getList();
    },
    onReset() {
      this.filterForm = {};
    },
    async getList() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const params = {
        pageNum: this.current,
        pageSize: this.pageSize,
        supplierCode: this.filterForm.supplier,
        areaCode: this.filterForm.city || this.filterForm.province
      };
      const res = await getExpressCostList(params);
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg({ flag: FETCH_CODE.ERROR.KEY, msg: res.msg });
        this.loading = false;
        return;
      }
      this.dataList = res.data?.records || [];
      this.dataList.map(d => {
        d.supplierName =
          this.supplierList.find(i => i.supplierCode === d.supplierCode)
            ?.supplierName || "";
        d.area = d.areaCode
          ? d.areaCode.split(/(\d{2})/g).filter(i => i !== "")
          : [];
        d.cityCode = d.area[1] || "";
        d.provinceCode = d.area[0] || "";
        d.provinceName =
          this.provinceList.find(i => i.code === d.area[0])?.name || "";
      });
      this.current = res.data.current;
      this.pageSize = res.data.size;
      this.total = res.data.total;
      this.loading = false;
    },
    onTableChange(page) {
      this.current = page.current;
      this.pageSize = page.pageSize;
      this.getList();
    },
    async onDelete(item) {
      const res = await removeExpressCost({ id: item.id });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg({ flag: FETCH_CODE.ERROR.KEY, msg: res.msg });
        return;
      }
      showMsg({
        flag: FETCH_CODE.SUCCESS.KEY,
        msg: this.$t("showMsg.success.delete")
      });
      this.current = 1;
      await this.getList();
    },
    async getSupplier() {
      const res = await getSupplierAll();
      this.supplierList = res?.data || [];
    },
    onEdit(item) {
      this.$router.push({
        name: "ExpressCostEdit",
        params: {
          id: "1"
        }
      });
      // console.log(item);
      // this.item = item;
      // this.isEdit = true;
      // this.isShowModal = true;
    },
    add() {
      this.$router.push({
        name: "ExpressCostAdd"
      });
      // this.isEdit = false;
      // this.isShowModal = true;
    },
    async getForm(form) {
      const params = {
        amount: Number(form.amount),
        areaCode: form.city || form.province,
        delFlag: form.delFlag ? 1 : 0,
        supplierCode: form.supplierCode
      };
      const res = this.isEdit
        ? await editExpressCost({ id: this.item.id, ...params })
        : await addExpressCost(params);
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg({ flag: FETCH_CODE.ERROR.KEY, msg: res.msg });
        return;
      }
      const msg = this.isEdit
        ? this.$t("showMsg.success.change")
        : this.$t("showMsg.success.add");
      showMsg({ flag: FETCH_CODE.SUCCESS.KEY, msg });
      this.isShowModal = false;
      this.current = 1;
      await this.getList();
    },
    selectArea(value, type) {
      if (type === "province") {
        if (!value) {
          this.filterForm.province = undefined;
          this.filterForm.city = undefined;
          this.cityList = [];
          return;
        }
        this.filterForm.province = value;
        this.filterForm.city = undefined;
        this.getAreaList(value, type);
        return;
      }
      if (type === "city") {
        if (!value) {
          this.filterForm.city = undefined;
          return;
        }
        this.filterForm.city = value;
        return;
      }
    },
    async getAreaList(value, type) {
      const params = !value ? {} : { parentCode: value };
      const res = await getArea(params);
      const key = type === "province" ? "cityList" : "provinceList";
      this[key] = res.data || [];
    }
  },
  async mounted() {
    await this.getSupplier();
    this.getAreaList();
    await this.getList();
  }
};
</script>

<style lang="less" scoped>
.cost {
  &-head {
    margin: 20px 0;
    .filter-btn {
      text-align: center;
      .reset-btn {
        width: 146px;
        height: 42px;
        background: #b2c0df;
        border-radius: 8px;
        color: #fff;
        &:hover,
        &:focus {
          border: 1px solid #b2c0df;
        }
        margin-right: @ec-gutter20;
      }
      .search-btn {
        width: 146px;
        height: 42px;
        border-radius: 8px;
      }
    }
  }
  .table-operations {
    display: flex;
    justify-content: center;
    align-items: center;

    .table-btn {
      &.danger {
        color: @error-color;
      }
    }
  }
}
</style>
