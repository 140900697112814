<template>
  <ECEditPage class="product-edit" :loading="loading">
    <a-form-model
        ref="productForm"
        layout="inline"
        :model="form"
        :rules="formRules"
        :colon="false"
        labelAlign="right"
        :labelCol="formItemLayout.labelCol"
        :wrapperCol="formItemLayout.wrapperCol"
    >
      <div class="product-edit-title">
        {{ $t("productManagement.product.detail.baseInfo.title") }}
      </div>
      <div class="product-edit-content">
        <a-row>
          <a-col :span="8">
            <a-form-model-item
              :label="$t('productManagement.product.detail.baseInfo.productName.label')"
              prop="productName"
            >
              <a-input
                v-model="form.productName"
                :placeholder="$t('productManagement.product.detail.baseInfo.productName.placeholder')"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="8" class="product-edit-tips">
            <a-icon type="exclamation-circle" theme="filled" class="ec-color-warning" />
            {{ $t("productManagement.product.detail.baseInfo.productName.tips") }}
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-model-item
              :label="$t('productManagement.product.detail.baseInfo.categoryCode.label')"
              prop="categoryCode"
            >
              <a-select
                :getPopupContainer="trigger => trigger.parentNode"
                v-model="form.categoryCode"
                :placeholder="$t('productManagement.product.detail.baseInfo.categoryCode.placeholder')"
              >
                <a-select-option
                  v-for="i in categoryList"
                  :value="i.categoryCode"
                  :key="i.categoryCode"
                >
                  {{ i.categoryName }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item
              :label="$t('productManagement.product.detail.baseInfo.productType.label')"
              prop="productType"
            >
              <a-select
                :getPopupContainer="trigger => trigger.parentNode"
                v-model="form.productType"
                :placeholder="$t('productManagement.product.detail.baseInfo.productType.placeholder')"
              >
                <a-select-option
                  v-for="i in productTypeList"
                  :key="i.dictCode"
                  :value="i.dictCode"
                >
                  {{ i.dictName }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item
              :label="$t('productManagement.product.detail.baseInfo.advertisement.label')"
              prop="advertisingWords"
            >
              <a-input
                v-model="form.advertisingWords"
                :placeholder="$t('productManagement.product.detail.baseInfo.advertisement.placeholder')"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item
              :label="$t('productManagement.product.detail.baseInfo.keywords.label')"
              prop="keywords"
            >
              <a-input
                v-model="form.keywords"
                :placeholder="$t('productManagement.product.detail.baseInfo.keywords.placeholder')"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item
                :label="$t('productManagement.product.detail.baseInfo.supplierCode.label')"
                prop="supplierCode"
            >
              <a-select
                  :getPopupContainer="trigger => trigger.parentNode"
                  v-model="form.supplierCode"
                  :placeholder="$t('productManagement.product.detail.baseInfo.supplierCode.placeholder')"
              >
                <a-select-option
                    v-for="i in supplierList"
                    :value="i.supplierCode"
                    :key="i.supplierCode"
                >
                  {{ i.supplierName }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item
                :label="$t('productManagement.product.detail.baseInfo.freightTemplateId.label')"
                prop="freightTemplateId"
            >
              <a-select
                  :getPopupContainer="trigger => trigger.parentNode"
                  v-model="form.freightTemplateId"
                  :placeholder="$t('productManagement.product.detail.baseInfo.freightTemplateId.placeholder')"
              >
                <a-select-option
                    v-for="i in shippingFeeList"
                    :value="i.id"
                    :key="i.id"
                >
                  {{ i.amount }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item
                :label="$t('productManagement.product.detail.baseInfo.isRefund.label')"
                prop="isRefund"
            >
              <a-radio-group v-decorator="['radio-group']" v-model="form.isRefund">
                <a-radio value="1">
                  {{ $t("button.yes") }}
                </a-radio>
                <a-radio value="0">
                  {{ $t("button.no") }}
                </a-radio>
              </a-radio-group>
            </a-form-model-item>
          </a-col>
          <a-col :span="16">
            <a-form-model-item
                :label="$t('productManagement.product.detail.baseInfo.pictureUrl.label')"
                prop="pictureUrl"
                :labelCol="{ span: 4 }"
                :wrapperCol="{ span: 20 }"
            >
              <ec-form-upload
                  :format="['image/jpeg', 'image/jpg', 'image/png']"
                  :fileList.sync="fileListProduct"
                  :number-max="5"
                  @change="onProductFileUploadChange"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-model-item
                :label="$t('productManagement.product.detail.baseInfo.brandCode.label')"
                prop="brandCode"
            >
              <a-select
                  :getPopupContainer="trigger => trigger.parentNode"
                  v-model="form.brandCode"
                  :placeholder="$t('productManagement.product.detail.baseInfo.brandCode.placeholder')"
              >
                <a-select-option
                    v-for="i in brandList"
                    :value="i.brandCode"
                    :key="i.brandCode"
                >
                  {{ i.brandName }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-button type="link" disabled>
              {{ $t("productManagement.product.detail.baseInfo.brandCode.add") }}
            </a-button>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-model-item
                :label="$t('productManagement.product.detail.baseInfo.taxPoint.label')"
                prop="taxPoint"
            >
              <a-input-number
                  v-model="form.taxPoint"
                  :placeholder="$t('productManagement.product.detail.baseInfo.taxPoint.placeholder')"
                  :min="0"
                  :max="100"
                  :precision="2"
                  :formatter="value => `${value}%`"
                  :parser="value => value.replace('%', '')"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item
                :label="$t('productManagement.product.detail.baseInfo.isRecommend.label')"
                prop="isRecommend"
            >
              <a-checkbox v-model="form.isRecommend">
                {{ $t("productManagement.product.detail.baseInfo.isRecommend.label") }}
              </a-checkbox>
            </a-form-model-item>
          </a-col>
          <a-col :span="16">
            <a-form-model-item
                :label="$t('productManagement.product.detail.baseInfo.link.label')"
                prop="supplierProductUrl"
                :labelCol="{ span: 4 }"
                :wrapperCol="{ span: 20 }"
            >
              <a-input
                  v-model="form.supplierProductUrl"
                  :placeholder="$t('productManagement.product.detail.baseInfo.link.placeholder')"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item
                :label="$t('productManagement.product.detail.baseInfo.shelfTime.label')"
                prop="shelfTime"
            >
              <a-date-picker
                  v-model="form.shelfTime"
                  :placeholder="$t('productManagement.product.detail.baseInfo.shelfTime.placeholder')"
                  show-time
              />
            </a-form-model-item>
          </a-col>
        </a-row>
      </div>
      <div class="product-edit-title">
        {{ $t("productManagement.product.detail.spec.title") }}
        <a-button
          type="primary"
          class="product-edit-add-btn"
          @click="onAddSpecs()"
          :disabled="specsList.length >= 2 || skuEditable"
        >
          {{ $t("productManagement.product.detail.spec.add") }}
        </a-button>
        <span class="product-edit-title-tips">
          <a-icon type="exclamation-circle" theme="filled" class="ec-color-warning" />
            {{ $t("productManagement.product.detail.spec.tipsAlways") }}
            <span v-if="skuEditable">{{ $t("productManagement.product.detail.spec.tips") }}</span>
        </span>
      </div>
      <div class="product-edit-content">
        <a-row v-for="(specItem, specIndex) in specsList" :key="specItem.key">
          <a-col :span="8">
            <a-form-model-item
                :label="$t('productManagement.product.detail.spec.specsName.label')"
                required
            >
              <a-select
                  :getPopupContainer="trigger => trigger.parentNode"
                  :value="specItem.specsCode"
                  :placeholder="$t('productManagement.product.detail.spec.specsName.placeholder')"
                  allow-clear
                  @change="(val) => onSpecCodeChange(specIndex, val, specItem)"
                  :disabled="skuEditable"
              >
                <a-select-option
                    v-for="i in attrList"
                    :value="i.attributeCode"
                    :key="i.attributeCode"
                >
                  {{ i.attributeName }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="16">
            <a-row v-for="(valItem, valIdx) in specItem.specsValues" :key="valIdx">
              <a-col :span="12">
                <a-form-model-item
                  :label="$t('productManagement.product.detail.spec.specsValue.label')"
                  required
                >
                  <a-input
                    :value="valItem"
                    :placeholder="$t('productManagement.product.detail.spec.specsValue.placeholder')"
                    @change="(e) => onSpecValueChange(specIndex, valIdx, e, specItem)"
                    allow-clear
                    :disabled="skuEditable"
                  />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-button
                  type="danger"
                  ghost
                  class="product-edit-handle-btn"
                  @click="onSpecValueRemove(specIndex, valIdx, specItem)"
                  :disabled="skuEditable"
                >
                  {{ $t("button.remove") }}
                </a-button>
                <a-button
                  type="primary"
                  class="product-edit-handle-btn"
                  @click="onSpecValueAdd(specIndex, valIdx, specItem)"
                  v-if="valIdx >= specItem.specsValues.length - 1"
                  :disabled="skuEditable"
                >
                  {{ $t("button.new") }}
                </a-button>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </div>
      <div class="product-edit-title">
        {{ $t("productManagement.product.detail.sku.title") }}
        <a-button type="primary" class="product-edit-add-btn" @click="onSaveSku" v-if="skuEditable">
          {{ $t("productManagement.product.detail.sku.save") }}
        </a-button>
        <a-button type="primary" class="product-edit-add-btn" @click="onEditSku" v-else>
          {{ $t("productManagement.product.detail.sku.edit") }}
        </a-button>
        <span class="product-edit-title-tips">
          <a-icon type="exclamation-circle" theme="filled" class="ec-color-warning" />
            {{ $t("productManagement.product.detail.sku.tips") }}
        </span>
      </div>
      <div class="product-edit-content">
        <a-table
          :columns="skuColumns"
          :data-source="skuData"
          bordered
          class="table-sku-list"
          :pagination="false"
          :scroll="{ x: true }"
          v-if="specsList.length"
        >
          <template slot="skuNo" slot-scope="text, record">
            {{ record.skuNo || $t('productManagement.product.detail.sku.productNo.placeholder') }}
          </template>
          <template slot="skuName" slot-scope="text, record, index">
            <a-input
              v-model="record.skuName"
              :placeholder="$t('productManagement.product.detail.sku.skuName.placeholder')"
              :id="`skuList-skuName-${index}`"
              autocomplete="off"
              :disabled="!record.state"
              v-if="skuEditable"
              @change="(e) => onSkuChange({ skuName: e.target.value }, index, record)"
            />
            <template v-else>{{ text }}</template>
          </template>
          <template slot="salesPrice" slot-scope="text, record, index">
            <a-input-number
              :value="record.salesPrice"
              :placeholder="$t('productManagement.product.detail.sku.salesPrice.placeholder')"
              :min="0"
              :precision="2"
              :id="`skuList-salesPrice-${index}`"
              :disabled="!record.state"
              v-if="skuEditable"
              @change="(e) => onSkuChange({ salesPrice: e }, index, record)"
            />
            <template v-else>
              {{ record.salesPrice ? moneyFormatter(record.salesPrice) : "" }}
            </template>
          </template>
          <template slot="stock" slot-scope="text, record, index">
            <a-input-number
              :value="record.stock"
              :placeholder="$t('productManagement.product.detail.sku.stock.placeholder')"
              :min="0"
              :precision="0"
              :id="`skuList-stock-${index}`"
              :disabled="!record.state"
              v-if="skuEditable"
              @change="(e) => onSkuChange({ stock: e }, index, record)"
            />
            <template v-else>{{ text }}</template>
          </template>
          <template slot="minBuyCount" slot-scope="text, record, index">
            <a-input-number
              :value="record.minBuyCount"
              :placeholder="$t('productManagement.product.detail.sku.minBuyCount.placeholder')"
              :min="0"
              :precision="0"
              :id="`skuList-minBuyCount-${index}`"
              :disabled="!record.state"
              v-if="skuEditable"
              @change="(e) => onSkuChange({ minBuyCount: e }, index, record)"
            />
            <template v-else>{{ text }}</template>
          </template>
          <template slot="maxBuyCount" slot-scope="text, record, index">
            <a-input-number
              :value="record.maxBuyCount"
              :placeholder="$t('productManagement.product.detail.sku.maxBuyCount.placeholder')"
              :min="0"
              :precision="0"
              :id="`skuList-maxBuyCount-${index}`"
              :disabled="!record.state"
              v-if="skuEditable"
              @change="(e) => onSkuChange({ maxBuyCount: e }, index, record)"
            />
            <template v-else>{{ text }}</template>
          </template>
          <template slot="pictureUrl" slot-scope="text, record, index">
            <ec-form-upload
              :format="['image/jpeg', 'image/jpg', 'image/png']"
              :fileList.sync="record.fileList"
              :number-max="5"
              @change="(_fileList) => onFileUploadChange(index, _fileList)"
              :id="`skuList-pictureUrl-${index}`"
              :disabled="!record.state"
              v-if="skuEditable"
            />
            <ul class="sku-list-picture-wrap" v-else>
              <li
                class="sku-list-picture"
                v-for="picture in record.fileList"
                :key="picture.url"
                :style="{ backgroundImage: `url(${picture.url})` }"
              ></li>
            </ul>
          </template>
          <template slot="action" slot-scope="text, record">
            <template v-if="skuEditable">
              <a-button
                  type="link"
                  class="table-btn danger"
                  @click="onSkuActive(false, record)"
                  v-if="record.state"
              >
                {{ $t("productManagement.product.detail.sku.disable") }}
              </a-button>
              <a-button
                  type="link"
                  class="table-btn"
                  @click="onSkuActive(true, record)"
                  v-else
              >
                {{ $t("productManagement.product.detail.sku.enable") }}
              </a-button>
            </template>
            <template v-else>
              {{
                record.state ?
                  $t("productManagement.product.detail.sku.enabled") :
                  $t("productManagement.product.detail.sku.disabled")
              }}
            </template>
          </template>
        </a-table>
      </div>
      <div class="product-edit-title">
        {{ $t("productManagement.product.detail.others.title") }}
      </div>
      <div class="product-edit-content">
        <div class="ec-tabs-wrapper">
          <ul class="ec-tabs-head">
            <li
                v-for="tab in tabList"
                :key="tab.key"
                class="ec-tabs-head-item"
                :class="activeTab === tab.key ? 'active' : ''"
                @click="onTabsChange(tab)"
            >
              {{ tab.title }}
            </li>
          </ul>
          <ul class="ec-tabs-content">
            <li :key="tabList[0].key" class="ec-tabs-item" :class="activeTab === tabList[0].key ? 'active' : ''">
              <a-form-model-item
                  label=""
                  prop="otherDetail"
                  :labelCol="{ span: 0 }"
                  :wrapperCol="{ span: 24 }"
              >
                <EcEditor ref="otherDetail" :content="otherDetail"/>
              </a-form-model-item>
            </li>
            <li :key="tabList[1].key" class="ec-tabs-item" :class="activeTab === tabList[1].key ? 'active' : ''">
              <a-form-model-item
                  label=""
                  prop="otherSpec"
                  :labelCol="{ span: 0 }"
                  :wrapperCol="{ span: 24 }"
              >
                <EcEditor ref="otherSpec"  :content="otherSpec"/>
              </a-form-model-item>
            </li>
            <li :key="tabList[2].key" class="ec-tabs-item" :class="activeTab === tabList[2].key ? 'active' : ''">
              <a-form-model-item
                  label=""
                  prop="otherExchange"
                  :labelCol="{ span: 0 }"
                  :wrapperCol="{ span: 24 }"
              >
                <EcEditor ref="otherExchange"  :content="otherExchange"/>
              </a-form-model-item>
            </li>
            <li :key="tabList[3].key" class="ec-tabs-item" :class="activeTab === tabList[3].key ? 'active' : ''">
              <a-form-model-item
                  label=""
                  prop="otherAfterSales"
                  :labelCol="{ span: 0 }"
                  :wrapperCol="{ span: 24 }"
              >
                <EcEditor ref="otherAfterSales" :content="otherAfterSales"/>
              </a-form-model-item>
            </li>
          </ul>
        </div>
      </div>
      <div class="product-edit-foot">
        <a-button class="product-edit-btn" @click="onCancel">
          {{ $t("button.cancel") }}
        </a-button>
        <a-button type="primary" class="product-edit-btn" @click="onSubmit(false)">
          {{ $t("button.save") }}
        </a-button>
        <a-button type="primary" class="product-edit-btn" @click="onSubmit(true)">
          {{ $t("button.saveAndUp") }}
        </a-button>
      </div>
    </a-form-model>
  </ECEditPage>
</template>

<script>
import Vue from "vue";
import { showMsg } from "@/utils";
import {DateFormatFull, FETCH_CODE, PRICE_RATE_BACK, PRICE_RATE_FRONT} from "@/config";
import {
  getCategory,
  getProductDetail,
  getSpecList,
  saveProduct
} from "@/pages/product/api";
import ECEditPage from "@/components/ECEditPage.vue";
import EcEditor from "@/components/Editor.vue"
import { getBrandAll, getSupplierAll } from "@/pages/supplier/api";
import { getExpressCostList } from "@/pages/express/api";
import EcFormUpload from "@/components/EcFormUpload.vue";
import {
  getFormModelFirstError,
  moneyFormatter,
} from "@/utils/tools";
import moment from "moment";
import {getDic} from "@/api";
import {cloneDeep, trim, uniq} from "lodash";
import BigNumber from "bignumber.js";
import { langMixin } from "@/mixins";

const skuColumns = [
  {
    title: Vue.prototype.$t('productManagement.product.detail.sku.productNo.label'),
    dataIndex: "skuNo",
    width: 140,
    scopedSlots: { customRender: "skuNo" },
    align: "left",
  },
  {
    title: Vue.prototype.$t('productManagement.product.detail.sku.skuName.label'),
    dataIndex: "skuName",
    width: 140,
    scopedSlots: { customRender: "skuName" },
    align: "left",
  },
  {
    title: Vue.prototype.$t('productManagement.product.detail.sku.salesPrice.label'),
    dataIndex: "salesPrice",
    width: 100,
    scopedSlots: { customRender: "salesPrice" },
    align: "right",
  },
  {
    title: Vue.prototype.$t('productManagement.product.detail.sku.stock.label'),
    dataIndex: "stock",
    width: 100,
    scopedSlots: { customRender: "stock" },
    align: "right",
  },
  {
    title: Vue.prototype.$t('productManagement.product.detail.sku.minBuyCount.label'),
    dataIndex: "minBuyCount",
    width: 100,
    scopedSlots: { customRender: "minBuyCount" },
    align: "right",
  },
  {
    title: Vue.prototype.$t('productManagement.product.detail.sku.maxBuyCount.label'),
    dataIndex: "maxBuyCount",
    width: 100,
    scopedSlots: { customRender: "maxBuyCount" },
    align: "right",
  },
  {
    title: Vue.prototype.$t('productManagement.product.detail.sku.pictureUrl.label'),
    dataIndex: "pictureUrl",
    width: 100,
    scopedSlots: { customRender: "pictureUrl" },
    align: "center",
  },
  {
    title: Vue.prototype.$t("action"),
    dataIndex: "action",
    width: 100,
    scopedSlots: { customRender: "action" },
    align: "center",
  },
];

export default {
  name: "ProductEdit",
  components: {EcFormUpload, ECEditPage, EcEditor},
  mixins: [langMixin],
  data() {
    return {
      presetSpecsLength: 5, // 受制于后端返回数据结构问题 只能通过硬编码匹配sku 修改此值同时修改 getSpecKey 方法
      loading: false,
      detail: {},
      form: {
        pictureUrl: null,
        state: true,
      },
      specsList: [],
      skuData: [],
      skuColumns: cloneDeep(skuColumns),
      skuEditable: false,
      detailPageList: [],
      fileListProduct: [],
      formRules: {
        productName: [
          {
            required: true,
            message: this.$t("productManagement.product.detail.baseInfo.productName.required"),
          },
          {
            max: 100,
            message: this.$t("productManagement.product.detail.baseInfo.productName.max", { length: 100 }),
          },
        ],
        categoryCode: [
          {
            required: true,
            message: this.$t("productManagement.product.detail.baseInfo.categoryCode.required"),
          },
        ],
        productType: [
          {
            required: true,
            message: this.$t("productManagement.product.detail.baseInfo.productType.required"),
          },
        ],
        advertisingWords: [
          {
            max: 500,
            message: this.$t("productManagement.product.detail.baseInfo.advertisement.max", { length: 500 }),
          },
        ],
        keywords: [
          {
            max: 500,
            message: this.$t("productManagement.product.detail.baseInfo.keywords.max", { length: 500 }),
          },
        ],
        supplierCode: [
          {
            required: true,
            message: this.$t("productManagement.product.detail.baseInfo.supplierCode.required"),
          },
        ],
        freightTemplateId: [
          {
            required: true,
            message: this.$t("productManagement.product.detail.baseInfo.freightTemplateId.required"),
          },
        ],
        isRefund: [
          {
            required: true,
            message: this.$t("productManagement.product.detail.baseInfo.isRefund.required"),
          },
        ],
        pictureUrl: [
          {
            required: true,
            message: this.$t("productManagement.product.detail.baseInfo.pictureUrl.required"),
          },
        ],
        brandCode: [
          {
            required: true,
            message: this.$t("productManagement.product.detail.baseInfo.brandCode.required"),
          },
        ],
        taxPoint: [
          {
            type: "number",
            message: this.$t("productManagement.product.detail.baseInfo.taxPoint.number"),
          },
        ],
        supplierProductUrl: [
          {
            max: 500,
            message: this.$t("productManagement.product.detail.baseInfo.link.max", { length: 500 }),
          },
        ],
        shelfTime: [
          {
            required: true,
            message: this.$t("productManagement.product.detail.baseInfo.shelfTime.required"),
          },
        ],
        specsName: [
          {
            required: true,
            message: this.$t("productManagement.product.detail.spec.specsName.required"),
          },
        ],
        specsValue: [
          {
            required: true,
            message: this.$t("productManagement.product.detail.spec.specsValue.required"),
          },
          {
            max: 100,
            message: this.$t("productManagement.product.detail.spec.specsValue.max", { length: 100 }),
          },
        ],
        skuName: [
          {
            required: true,
            message: this.$t("productManagement.product.detail.sku.skuName.required"),
          },
          {
            max: 100,
            message: this.$t("productManagement.product.detail.sku.skuName.max", { length: 100 }),
          },
        ],
      },
      categoryList: [],
      supplierList: [],
      shippingFeeList: [],
      brandList: [],
      attrList: [],
      productTypeList: [],
      tabList: [
        {
          key: "1",
          title: this.$t('productManagement.product.detail.others.detail'),
          value: null,
        },
        {
          key: "2",
          title: this.$t('productManagement.product.detail.others.spec'),
          value: null,
        },
        {
          key: "3",
          title: this.$t('productManagement.product.detail.others.exchange'),
          value: null,
        },
        {
          key: "4",
          title: this.$t('productManagement.product.detail.others.afterSales'),
          value: null,
        },
      ],
      activeTab: '1',
      isRerender: false,
      otherDetail: '',
      otherSpec: '',
      otherExchange: '',
      otherAfterSales: '',
    };
  },
  computed: {
    isEdit () {
      return !!this.$route.params.productNo;
    },
    formItemLayout() {
      return this.calcFormItemLayout([8, 9]);
    }
    // otherDetail () {
    //   return this.detailPageList?.find((it) => it.pageType === "detail")?.content;
    // },
    // otherSpec () {
    //   return this.detailPageList?.find((it) => it.pageType === "specifications")?.content;
    // },
    // otherExchange() {
    //   return this.detailPageList?.find((it) => it.pageType === "exchange_process")?.content;
    // },
    // otherAfterSales() {
    //   return this.detailPageList?.find((it) => it.pageType === "after_sales_guarantee")?.content;
    // },
  },
  methods: {
    moneyFormatter,
    onBack() {
      this.$router.back();
    },
    onCancel() {
      this.onBack();
    },
    onSubmit(upShelfState = false) {
      if (this.loading) return;
      this.loading = true;
      this.$refs.productForm.validate(async (valid, errors) => {
        if (!valid) {
          const errMsg = getFormModelFirstError(errors);
          errMsg && showMsg({ flag: 1, msg: errMsg });
          this.loading = false;
          return;
        }
        if (this.skuEditable) {
          showMsg({
            flag: 1,
            msg: this.$t("productManagement.product.detail.sku.saveTips"),
          });
          this.loading = false;
          return;
        }
        const specsValid = this.specsValidator();
        const skuValid = this.skuValidator();
        if (!specsValid || !skuValid) {
          this.loading = false;
          return;
        }

        const skuList = this.skuData.filter((it) => it.state).slice();
        skuList.forEach((it) => {
          it.salesPrice = BigNumber(it.salesPrice).times(PRICE_RATE_BACK).toNumber();
        });

        const params = {
          ...this.form,
          description: this.form.keywords,
          remark: this.form.advertisingWords,
          isRefund: this.form.isRefund === "1",
          shelfTime: this.form.shelfTime ? moment(this.form.shelfTime).format(DateFormatFull) : null,
          skuList,
        };
        // 详情接口可能不返回id
        if (this.isEdit && !params.id) {
          params.id = this.$route.params.id;
        }
        params.upAndDownShelfState = upShelfState;
        const timymces = {
          // 富文本
          otherDetail: this.$refs.otherDetail.getValue(), // 商品详情
          otherSpec: this.$refs.otherSpec.getValue(), // 规格参数
          otherExchange: this.$refs.otherExchange.getValue(), // 兑换流程
          otherAfterSales: this.$refs.otherAfterSales.getValue(), // 售后保障
        };
        const detailPageList = [];
        if (timymces.otherDetail) {
          const curr = this.detailPageList.find((it) => it.pageType === "detail");
          detailPageList.push({
            ...curr,
            content: timymces.otherDetail,
            pageType: "detail",
          });
        }
        if (timymces.otherSpec) {
          const curr = this.detailPageList.find((it) => it.pageType === "specifications");
          detailPageList.push({
            ...curr,
            content: timymces.otherSpec,
            pageType: "specifications",
          });
        }
        if (timymces.otherExchange) {
          const curr = this.detailPageList.find((it) => it.pageType === "exchange_process");
          detailPageList.push({
            ...curr,
            content: timymces.otherExchange,
            pageType: "exchange_process",
          });
        }
        if (timymces.otherAfterSales) {
          const curr = this.detailPageList.find((it) => it.pageType === "after_sales_guarantee");
          detailPageList.push({
            ...curr,
            content: timymces.otherAfterSales,
            pageType: "after_sales_guarantee",
          });
        }
        params.detailPageList = detailPageList;

        const res = await saveProduct(params);
        if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
          showMsg(res);
          this.loading = false;
          return;
        }
        let msg = this.$t("productManagement.product.detail.success.add");
        if (this.isEdit) {
          msg = this.$t("productManagement.product.detail.success.edit");
        }
        showMsg({
          flag: FETCH_CODE.SUCCESS.KEY,
          msg,
        });
        this.onBack();
      });
    },
    // 新建规格
    onAddSpecs(values = {}) {
      const index = this.specsList.length;
      if (index >= 2) return;
      this.specsList.push({
        key: this.getSpecKey(index),
        specsName: null,
        specsId: null,
        specsCode: undefined,
        specsValues: [null],
        children: [{
          key: this.getSpecKey(index),
          specsKey: this.getSpecKey(index),
          specsName: null,
          specsId: null,
          specsCode: undefined,
          specsValue: undefined,
        }],
        ...values,
      });

      // 根据规格生成sku列表
      this.skuColumns = this.calcSkuColumns();
      this.skuData = this.calcSkuList();
      this.setFormSpecsData();
    },
    // 删除规格
    onRemoveSpecs(specIndex) {
      const specsList = this.specsList.slice();

      // 删除当前规格并重置剩余规格key
      specsList.splice(specIndex, 1);
      specsList.forEach((it, idx) => {
        const currSpecKey = this.getSpecKey(idx);
        it.key = currSpecKey;
        it.children = it.children.map((_it) => ({
          ..._it,
          key: currSpecKey + it,
          specsKey: currSpecKey,
        }));
      });
      this.specsList = specsList;

      // 根据规格生成sku列表
      this.skuColumns = this.calcSkuColumns();
      this.skuData = this.calcSkuList();
      this.setFormSpecsData();
    },
    // 规格值修改事件
    onSpecValueChange(specIndex, index, e) {
      this.specsList[specIndex].specsValues.splice(index, 1, e.target.value);
      this.specsList[specIndex].children.splice(index, 1, {
        ...this.specsList[specIndex].children[index],
        key: this.specsList[specIndex].key + e.target.value,
        specsValue: e.target.value,
      });

      // 根据规格生成sku列表
      this.skuColumns = this.calcSkuColumns();
      this.skuData = this.calcSkuList();
      this.setFormSpecsData();
    },
    // 规格修改事件
    onSpecCodeChange(specIndex, value) {
      if (!value) {
        this.onRemoveSpecs(specIndex);
        return;
      }
      const existed = this.specsList
        .filter((it, idx) => idx !== specIndex)
        .some((it) => it.specsCode === value);
      if (existed) {
        showMsg({
          flag: FETCH_CODE.WARNING.KEY,
          msg: this.$t("productManagement.product.detail.spec.existed"),
        });
        return;
      }
      const option = this.attrList.find((it) => it.attributeCode === value);
      this.specsList[specIndex].specsCode = value;
      this.specsList[specIndex].specsName = option?.attributeName;
      this.specsList[specIndex].specsId = option?.id;
      this.specsList[specIndex].specsValues.forEach((it, idx) => {
        this.specsList[specIndex].children.splice(idx, 1, {
          ...this.specsList[specIndex].children[idx],
          key: this.specsList[specIndex].key + it,
          specsName: option?.attributeName,
          specsId: option?.id,
          specsCode: value,
          specsValue: it,
        });
      });
      this.form[this.getSpecKey(specIndex)] = option?.attributeName;

      // 根据规格生成sku列表
      this.skuColumns = this.calcSkuColumns();
      this.skuData = this.calcSkuList();
      this.setFormSpecsData();
    },
    // 新建规格值
    onSpecValueAdd(specIndex) {
      this.specsList[specIndex].specsValues.push(undefined);
      this.specsList[specIndex].children.push({
        key: this.specsList[specIndex].key,
        specsKey: this.specsList[specIndex].key,
        specsName: null,
        specsId: null,
        specsCode: undefined,
        specsValue: undefined,
      });

      // 根据规格生成sku列表
      this.skuColumns = this.calcSkuColumns();
      this.skuData = this.calcSkuList();
      this.setFormSpecsData();
    },
    // 删除规格值
    onSpecValueRemove(specIndex, index) {
      this.specsList[specIndex].specsValues.splice(index, 1);
      this.specsList[specIndex].children.splice(index, 1);

      if (!this.specsList[specIndex].specsValues.length) {
        this.onRemoveSpecs(specIndex);
      } else {
        // 根据规格生成sku列表
        this.skuColumns = this.calcSkuColumns();
        this.skuData = this.calcSkuList();
        this.setFormSpecsData();
      }
    },
    onEditSku() {
      // 没有规格不允许编辑SKU
      if (!this.specsList || !this.specsList.length) {
        showMsg({
          flag: 1,
          msg: `${this.$t("input")}${this.$t("productManagement.product.detail.spec.title")}!`,
        });
        this.onAddSpecs();
        return;
      }
      const isInvalid = this.specsList.some((it) => {
        return !it.specsCode || it.specsValues.some((_it) => !_it || !trim(_it));
      });
      if (isInvalid) {
        showMsg({
          flag: 1,
          msg: `${this.$t("input")}${this.$t("right")}${this.$t("productManagement.product.detail.spec.title")}!`,
        });
        return;
      }
      this.skuEditable = true;
    },
    onSaveSku() {
      const skuValid = this.skuValidator();
      if (!skuValid) {
        return;
      }
      this.skuEditable = false;
    },
    onSkuActive(active, record) {
      const skuData = this.skuData.slice();
      const curr = skuData.find((it) => it.key === record.key);
      if (!curr) return;
      curr.state = active;
      // 禁用时 清空 属性名称、支付价格、库存、主图
      if (!active) {
        curr.skuNo = null;
        curr.skuName = null;
        curr.salesPrice = null;
        curr.stock = null;
        curr.pictureUrl = null;
        curr.fileList = [];
      }
      this.skuData = skuData;
    },
    onSkuChange(values, index) {
      const skuData = this.skuData.slice();
      Object.assign(skuData[index], values);
      this.skuData = skuData;
    },
    getSpecKey(index) {
      const list = ["One", "Two", "Three", "Four", "Five"];
      return `specs${list[index]}`;
    },
    specsValidator() {
      // 判断是否添加了有效的规格数据
      if (!this.specsList.length) {
        showMsg({
          flag: FETCH_CODE.WARNING.KEY,
          msg: this.$t("productManagement.product.detail.sku.emptySpecs"),
        });
        return false;
      }
      for (let i = 0; i < this.specsList.length; i++) {
        const it = this.specsList[i];
        if (!it.specsCode) {
          showMsg({
            flag: FETCH_CODE.WARNING.KEY,
            msg: this.$t("productManagement.product.detail.spec.emptySpecsName", {
              sn: i + 1,
            }),
          });
          return false;
        }
        for (let j = 0; j < it.specsValues.length; j++) {
          if (!trim(it.specsValues[j])) {
            showMsg({
              flag: FETCH_CODE.WARNING.KEY,
              msg: this.$t("productManagement.product.detail.spec.emptySpecsValue", {
                sn: i + 1,
                sn2: j + 1,
              }),
            });
            return false;
          }
        }
      }
      return true;
    },
    skuValidator() {
      const skuData = this.skuData.filter((it) => it.state);
      // 判断是否添加了有效的规格数据
      if (!skuData.length) {
        showMsg({
          flag: FETCH_CODE.WARNING.KEY,
          msg: this.$t("productManagement.product.detail.sku.emptySku"),
        });
        return false;
      }
      for (let i = 0; i < this.skuData.length; i++) {
        const it = this.skuData[i];
        for (let j = 0; j < this.specsList.length; j++) {
          const specItem = this.specsList[j];
          if (it.state && !it[`${specItem.key}Value`]) {
            showMsg({
              flag: FETCH_CODE.WARNING.KEY,
              msg: this.$t("productManagement.product.detail.sku.emptySpec", {
                sn: i + 1,
                label: specItem.specsName,
              }),
            });
            return false;
          }
        }
        if (it.state && !trim(it.skuName)) {
          showMsg({
            flag: FETCH_CODE.WARNING.KEY,
            msg: this.$t("productManagement.product.detail.sku.emptySkuName", {
              sn: i + 1,
            }),
          });
          this.focusSkuForm("skuName", i);
          return false;
        }
        if (it.state && !it.salesPrice) {
          showMsg({
            flag: FETCH_CODE.WARNING.KEY,
            msg: this.$t("productManagement.product.detail.sku.emptySalesPrice", {
              sn: i + 1,
            }),
          });
          this.focusSkuForm("salesPrice", i);
          return false;
        }
        if (it.state && !it.stock) {
          showMsg({
            flag: FETCH_CODE.WARNING.KEY,
            msg: this.$t("productManagement.product.detail.sku.emptyStock", {
              sn: i + 1,
            }),
          });
          this.focusSkuForm("stock", i);
          return false;
        }
        if (
          it.state &&
          typeof it.minBuyCount === "number" &&
          typeof it.maxBuyCount === "number" &&
          it.minBuyCount > it.maxBuyCount
        ) {
          showMsg({
            flag: FETCH_CODE.WARNING.KEY,
            msg: this.$t("productManagement.product.detail.sku.invalidBuyCount", {
              sn: i + 1,
            }),
          });
          this.focusSkuForm("minBuyCount", i);
          return false;
        }
      }
      return true;
    },
    focusSkuForm(field, index) {
      const target = document.getElementById(`skuList-${field}-${index}`);
      if (!target) return;
      setTimeout(() => {
        try {
          target.select();
          target.focus();
        } catch (err) {
          console.log(err);
        }
      }, 0);
    },
    async getDetail() {
      const params = {
        productNo: this.$route.params.productNo,
      };
      if (!params.productNo) {
        return;
      }
      this.loading = true;
      const res = await getProductDetail(params);
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        await showMsg(res);
        this.loading = false;
        this.$router.back();
        return;
      }
      this.detail = res.data;
      this.form = {
        ...res.data,
        keywords: res.data.description,
        advertisingWords: res.data.remark,
        isRefund: res.data.isRefund ? "1" : "0",
      };
      const skuData = res.data.skuList || [];
      // sku图回显
      skuData.forEach((it) => {
        it.state = true;
        it.fileList = [];
        it.salesPrice = BigNumber(it.salesPrice).times(PRICE_RATE_FRONT).toNumber();
        if (it.pictureUrl) {
          it.fileList = it.pictureUrl.split(",").map((it, idx) => {
            return {
              uid: "-" + (idx + 1),
              name: "icon" + (idx + 1) +".png",
              status: "done",
              url: it,
            };
          });
        }
      });

      // 规格回显
      [...new Array(this.presetSpecsLength).keys()].forEach((i) => {
        const specsKey = this.getSpecKey(i);
        if (!res.data[specsKey]) return;
        const curr = this.attrList.find((it) => it.attributeName === res.data[specsKey]);
        if (!curr) return;
        const specsValueData = res.data.skuList.map((it) => it[`${specsKey}Value`]);

        this.onAddSpecs({
          specsName: curr.attributeName,
          specsId: curr.id,
          specsCode: curr.attributeCode,
          specsValues: uniq(specsValueData),
          children: uniq(specsValueData).map((it) => {
            return {
              key: specsKey + it,
              specsKey: specsKey,
              specsName: curr.attributeName,
              specsId: curr.id,
              specsCode: curr.attributeCode,
              specsValue: it,
            };
          }),
        });
      });

      // sku回显
      this.skuColumns = this.calcSkuColumns();
      this.skuData = this.calcSkuList(skuData);

      // 主图回显
      if (this.form.pictureUrl) {
        this.fileListProduct = this.form.pictureUrl.split(",").map((it, idx) => {
          return {
            uid: "-" + idx,
            name: "icon" + idx +".png",
            status: "done",
            url: it,
          };
        });
      }

      // 富文本回显
      this.detailPageList = res.data.detailPageList || [];
      this.otherDetail = this.detailPageList.find((it) => it.pageType === "detail")?.content || '';
      this.otherSpec = this.detailPageList.find((it) => it.pageType === "specifications")?.content || '';
      this.otherExchange = this.detailPageList.find((it) => it.pageType === "exchange_process")?.content || '';
      this.otherAfterSales = this.detailPageList.find((it) => it.pageType === "after_sales_guarantee")?.content || '';
      this.$nextTick(() => {
        this.$refs.otherDetail.setValue(this.otherDetail);
      });
      this.loading = false;
    },
    async getCategoryList() {
      const res = await getCategory({
        pageNum: 1,
        pageSize: 999,
        state: true,
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        return;
      }
      this.categoryList = res.data.records;
    },
    async getSupplierList() {
      const res = await getSupplierAll({ _ts: +new Date() });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        return;
      }
      const supplierList = res.data || [];
      this.supplierList = supplierList.filter((it) => it.state);
    },
    async getBrandList() {
      const res = await getBrandAll();
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        return;
      }
      const brandList = res.data || [];
      this.brandList = brandList.filter((it) => it.state);
    },
    async getShippingFeeList() {
      const res = await getExpressCostList({
        pageNum: 1,
        pageSize: 999,
        state: true,
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        return;
      }
      const shippingFeeList = res.data.records || [];
      this.shippingFeeList = shippingFeeList.filter((it) => !it.delFlag);
    },
    async getSpecList() {
      const res = await getSpecList({
        pageNum: 1,
        pageSize: 999,
        state: true,
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        return;
      }
      const attrList = res.data.records || [];
      this.attrList = attrList;
    },
    async onFileUploadChange(skuIndex, fileList) {
      const skuData = this.skuData.slice();
      skuData[skuIndex].fileList = fileList;
      if (!fileList || !fileList.length) {
        skuData[skuIndex].pictureUrl = null;
        this.skuData = skuData;
        return;
      }
      const pictureUrl = [];
      fileList.filter((file) => {
        return file.response && file.response.data;
      }).forEach((file) => {
        pictureUrl.push(file.response.data);
        for (const skuFile of skuData[skuIndex].fileList) {
          if (skuFile.uid === file.uid) {
            skuFile.url = file.response.data;
            break;
          }
        }
      });
      skuData[skuIndex].pictureUrl = pictureUrl.join(",");
      this.skuData = skuData;
    },
    async onProductFileUploadChange(fileList) {
      if (!fileList || !fileList.length) return;
      const pictureUrl = [];
      fileList.filter((file) => {
        return file.response && file.response.data;
      }).forEach((file) => {
        pictureUrl.push(file.response.data);
      });
      this.form.pictureUrl = pictureUrl.join(",");
    },
    async getProductTypeList() {
      const res = await getDic({
        code: "product_type",
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) return;
      this.productTypeList = res.data;
    },
    onTabsChange(tab) {
      this.activeTab = tab.key;
    },
    makeSku(specsAll) {
      const emptySku = {
        skuNo: null,
        skuName: null,
        salesPrice: null,
        stock: null,
        pictureUrl: null,
        fileList: [],
        // 以下是未展示字段
        costPrice: null,
        crossedPrice: null,
        id: null,
        marketPrice: null,
        maxBuyCount: null,
        minBuyCount: null,
        productNo: "",
        remark: "",
        state: true,
        upAndDownShelfState: true,
      };
      const skuMaker = (arr1, arr2) => {
        const result = [];
        for (let i = 0; i < arr1.length; i++) {
          for (let j = 0; j < arr2.length; j++) {
            result.push({
              key: `${arr1[i].specsValue}-${arr2[j].specsValue}`,
              [`${arr1[i].specsKey}Value`]: arr1[i].specsValue,
              [`${arr2[j].specsKey}Value`]: arr2[j].specsValue,
              ...emptySku,
            });
          }
        }
        return result;
      };
      // 没有传入规格时
      if (!specsAll.length) {
        return [];
      }
      // 只有一个规格时直接返回
      if (specsAll.length < 2) {
        return specsAll[0].map((it) => {
          return {
            key: `${it.specsValue}`,
            [`${it.specsKey}Value`]: it.specsValue,
            ...emptySku,
          };
        });
      }
      // 多个规格时累加
      let specsRows = skuMaker(specsAll[0], specsAll[1]);
      for (let i = 2; i < specsAll.length; i++) {
        specsRows = skuMaker(specsRows, specsAll[i]);
      }
      return specsRows;
    },
    // 计算sku列表
    calcSkuList(skuData = this.skuData) {
      // 根据规格生成sku列表
      const specsAll = this.specsList.map((it) => it.children);
      const skuSpecs = this.makeSku(specsAll);
      // 回显数据赋值
      const skuDataSpecsKeys = [];
      [...new Array(this.specsList.length).keys()].forEach((i) => {
        const specsKey = this.getSpecKey(i);
        skuDataSpecsKeys.push(`${specsKey}Value`);
      });
      return skuSpecs.map((it) => {
        for (const sku of skuData) {
          let existed = 0;
          for (let i = 0; i < skuDataSpecsKeys.length; i++) {
            const skuDataSpecsKey = skuDataSpecsKeys[i];
            if (sku[skuDataSpecsKey] === it[skuDataSpecsKey]) {
              existed++;
            }
          }
          if (existed === skuDataSpecsKeys.length) {
            return { ...it, ...sku };
          }
        }
        return it;
      });
    },
    // 计算sku列
    calcSkuColumns() {
      const skuColumnsSpecs = [];
      this.specsList.forEach((spec) => {
        const col = {
          title: spec.specsName,
          dataIndex: `${spec.key}Value`,
          key: spec.specsCode,
          width: 80,
          align: "left",
        };
        skuColumnsSpecs.push(col);
      });
      return [...skuColumnsSpecs, ...cloneDeep(skuColumns)];
    },
    // 计算form中保存的规格
    setFormSpecsData() {
      [...new Array(this.presetSpecsLength).keys()].forEach((i) => {
        const specsKey = this.getSpecKey(i);
        this.form[specsKey] = undefined;
      });
      this.specsList.forEach((spec) => {
        this.form[spec.key] = spec.specsName;
      });
    },
  },
  async mounted() {
    this.getCategoryList();
    this.getSupplierList();
    this.getShippingFeeList();
    this.getBrandList();
    this.getProductTypeList();
    await this.getSpecList();
    this.getDetail();
  },
};
</script>

<style lang="less" scoped>
.product-edit {
  /deep/.ant-form-item {
    margin-bottom: 20px;
  }
  /deep/.ant-input-number {
    width: 100%;
  }
  &-title {
    font-size: 18px;
    font-weight: bold;
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    margin-left: -25px;

    &:not(&:first-child) {
      margin-top: 18px;
    }

    &::before {
      content: " ";
      display: block;
      width: 3px;
      height: 18px;
      background: @primary-color;
      margin-right: 5px;
    }
  }
  &-title-tips {
    margin-left: 23px;
    font-size: 14px;
    font-weight: normal;
    color: #A6AAB2;
  }
  &-btn {
    width: 146px;
  }
  &-handle-btn {
    width: 46px;
    height: 30px;
    line-height: 28px;
    font-size: 14px;
    border-radius: 4px;
    padding: 0;
    margin-right: 10px;
    margin-top: 9px;

    &:first-child {
      margin-left: 70px;
    }
  }
  &-add-btn {
    margin-left: 10px;
  }
  &-tips {
    height: 48px;
    line-height: 48px;
    margin-left: 23px;
  }
  &-foot {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  &-sku-item {
    border-bottom: 1px solid @border-color-base;

    &.not-first {
      margin-top: 30px;
    }

    &:last-child {
      border-bottom: 0;
    }
  }
  .table-sku-list {
    /deep/ .ant-upload-list-picture-card .ant-upload-list-item-info::before {
      left: 0;
    }
  }
  .sku-list-picture-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    margin-bottom: 0;

    .sku-list-picture {
      width: 60px;
      height: 60px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
}
.ec-tabs {
  &-head {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  &-head-item {
    height: 40px;
    margin: 0;
    margin-right: 2px;
    padding: 0 16px;
    line-height: 38px;
    background: #fafafa;
    border: 1px solid #e8e8e8;
    border-radius: 8px 8px 0 0;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

    &.active {
      color: #266FE8;
      background: #fff;
      border-color: #e8e8e8;
      border-bottom: 1px solid #fff;
    }
  }
  &-content {}
  &-item {
    display: none;

    &.active {
      display: block;
    }
  }
}
</style>
