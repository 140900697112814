<template>
  <div class="order-detail-wrapper">
    <a-spin :spinning="loading">
      <!--#region 订单进度-->
      <div class="order-detail-progress">
        <div class="order-detail-progress-top">
          <h5 class="order-detail-progress-title">
            {{ $t("orderManagement.order.detail.progress.title") }}
          </h5>
          <ul class="order-detail-progress-list">
            <li class="order-detail-progress-item" v-for="item in progressList" :key="item.no">
              <div class="order-detail-progress-step" :class="item.active ? 'active' : ''">
                {{ item.no }}
              </div>
              <div class="order-detail-progress-step-name">
                {{ item.name }}
              </div>
              <div class="order-detail-progress-step-status">
                {{ item.status }}
              </div>
            </li>
          </ul>
        </div>
        <div class="order-detail-progress-bot">
          {{ $t("orderManagement.order.detail.stateTips") }}: {{ this.detail.stateName || "--" }}
        </div>
      </div>
      <!--#endregion -->
      <!--#region 订单信息-->
      <div class="order-detail-info">
        <!--#region 发货信息-->
        <OrderDetailTitle
          :name="$t('orderManagement.order.detail.deliveryInfo.title')"
          class="order-detail-delivery-info"
          v-if="isSendGoodBtn || isRefundBtn"
        >
          <a-form-model
            ref="orderDeliveryInfo"
            :model="deliveryInfo"
            :rules="deliveryInfoRules"
            :label-col="deliveryInfoLabelCol"
            :wrapper-col="deliveryInfoWrapperCol"
          >
            <a-row>
              <a-col :span="8" v-if="isSendGoodBtn">
                <a-form-model-item
                  ref="expName"
                  :label="$t('orderManagement.order.detail.deliveryInfo.expName.label')"
                  prop="expName"
                >
                  <a-select
                    :getPopupContainer="trigger => trigger.parentNode"
                    v-model="deliveryInfo.expName"
                    :placeholder="$t('orderManagement.order.detail.deliveryInfo.expName.placeholder')"
                    allowClear
                  >
                    <a-select-option
                      v-for="op in expressList"
                      :key="op.id"
                      :value="op.id"
                    >
                      {{ op.name }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="8" v-if="isSendGoodBtn">
                <a-form-model-item
                  ref="number"
                  :label="$t('orderManagement.order.detail.deliveryInfo.number.label')"
                  prop="number"
                >
                  <a-input
                    v-model="deliveryInfo.number"
                    :placeholder="$t('orderManagement.order.detail.deliveryInfo.number.placeholder')"
                  />
                </a-form-model-item>
              </a-col>
              <a-col :span="8" class="order-detail-delivery-info-foot">
                <a-button type="primary" @click="onShip" class="order-detail-delivery-info-btn" v-if="isSendGoodBtn">
                  {{ $t("orderManagement.order.detail.deliveryInfo.ship") }}
                </a-button>
                <a-button type="danger" ghost @click="onRefund" class="order-detail-delivery-info-btn" v-if="isRefundBtn">
                  {{ $t("orderManagement.order.detail.deliveryInfo.refund") }}
                </a-button>
              </a-col>
            </a-row>
          </a-form-model>
        </OrderDetailTitle>
        <!--#endregion -->
        <!--#region 收货人信息-->
        <OrderDetailTitle
          :name="$t('orderManagement.order.detail.receiverInfo.title')"
          class="order-detail-receiver-info"
        >
          <OrderDetailTable
            :columns="receiverInfoCols"
            :data-source="receiverInfoList"
          >
            <template slot="address" slot-scope="text, record">
              {{ `${record.provinceName}${record.cityName}${record.countyName}${record.detailAddress}` }}
            </template>
          </OrderDetailTable>
        </OrderDetailTitle>
        <!--#endregion -->
        <!--#region 基本信息-->
        <OrderDetailTitle
          :name="$t('orderManagement.order.detail.baseInfo.title')"
          class="order-detail-base-info"
        >
          <OrderDetailTable
            :columns="baseInfoCols1"
            :data-source="baseInfoList1"
          />
          <OrderDetailTable
            :columns="baseInfoCols2"
            :data-source="baseInfoList2"
          />
        </OrderDetailTitle>
        <!--#endregion -->
        <!--#region 发票信息-->
        <OrderDetailTitle
          :name="$t('orderManagement.order.detail.invoiceInfo.title')"
          class="order-detail-invoice-info"
        >
          <OrderDetailTable
            :columns="invoiceInfoCols"
            :data-source="invoiceInfoList"
          />
        </OrderDetailTitle>
        <!--#endregion -->
        <!--#region 商品信息-->
        <OrderDetailTitle
          :name="$t('orderManagement.order.detail.productInfo.title')"
          class="order-detail-product-info"
        >
          <OrderDetailTable
            :columns="productInfoCols"
            :data-source="productInfoList"
          >
            <template slot="productUrl" slot-scope="text">
              <div class="order-detail-product-img" :style="{ backgroundImage: `url(${text})` }" />
            </template>
            <template slot="productPrice" slot-scope="text, record">
              <a-row>
                <a-col :span="24">
                  {{$t("orderManagement.order.detail.productInfo.cols.productPrice1")}}：{{ record.productPrice }}
                </a-col>
                <a-col :span="24">
                  {{$t("orderManagement.order.detail.productInfo.cols.productPrice2")}}：{{ record.skuNo }}
                </a-col>
              </a-row>
            </template>
            <template slot="subtotal" slot-scope="text, record">
              <a-row>
                <a-col :span="24" v-if="record.payPoints">
                  {{ record.payPointsShow }}{{platformConf.moneyName}}
                </a-col>
                <a-col :span="24" v-if="record.payPrice">
                  {{ record.payPriceShow }}{{$t("currency.price")}}
                </a-col>
                <a-col :span="24" v-if="record.payGrowthPoints">
                  {{ record.payGrowthPointsShow }}{{$t("currency.growthPoints")}}
                </a-col>
              </a-row>
            </template>
            <template slot="footer">
              <a-row class="order-detail-product-info-total">
                <a-col :span="24">
                  {{ $t("orderManagement.order.detail.productInfo.total") }}
                  <span class="ec-color-danger" v-if="productTotalPoints">
                    {{ productTotalPointsShow }}{{platformConf.moneyName}}&nbsp;
                  </span>
                  <span class="ec-color-danger" v-if="productTotalPrice">
                    {{ productTotalPriceShow }}{{$t("currency.price")}}&nbsp;
                  </span>
                  <span class="ec-color-danger" v-if="productTotalGrowthPoints">
                    {{ productTotalGrowthPointsShow }}{{$t("currency.growthPoints")}}&nbsp;
                  </span>
                </a-col>
              </a-row>
            </template>
          </OrderDetailTable>
        </OrderDetailTitle>
        <!--#endregion -->
        <!--#region 费用信息-->
        <OrderDetailTitle
          :name="$t('orderManagement.order.detail.feeInfo.title')"
          class="order-detail-fee-info"
        >
          <OrderDetailTable
            :columns="feeInfoCols1"
            :data-source="feeInfoList1"
          >
            <template slot="productTotalPrice">
              <a-row class="order-detail-fee-info-total">
                <a-col :span="24">
                  <span class="ec-color-text" v-if="productTotalPoints">
                    {{ productTotalPointsShow }}{{platformConf.moneyName}}&nbsp;
                  </span>
                  <span class="ec-color-text" v-if="productTotalPrice">
                    {{ productTotalPriceShow }}{{$t("currency.price")}}&nbsp;
                  </span>
                  <span class="ec-color-text" v-if="productTotalGrowthPoints">
                    {{ productTotalGrowthPointsShow }}{{$t("currency.growthPoints")}}&nbsp;
                  </span>
                </a-col>
              </a-row>
            </template>
          </OrderDetailTable>
          <OrderDetailTable
            :columns="feeInfoCols2"
            :data-source="feeInfoList2"
          >
            <template slot="orderTotalPrice">
              <a-row class="order-detail-fee-info-total">
                <a-col :span="24">
                  <span class="ec-color-text" v-if="productTotalPoints">
                    {{ productTotalPointsShow }}{{platformConf.moneyName}}&nbsp;
                  </span>
                  <span class="ec-color-text" v-if="productTotalPrice">
                    {{ productTotalPriceShow }}{{$t("currency.price")}}&nbsp;
                  </span>
                  <span class="ec-color-text" v-if="productTotalGrowthPoints">
                    {{ productTotalGrowthPointsShow }}{{$t("currency.growthPoints")}}&nbsp;
                  </span>
                </a-col>
              </a-row>
            </template>
          </OrderDetailTable>
        </OrderDetailTitle>
        <!--#endregion -->
        <!--#region 操作信息-->
        <OrderDetailTitle
          :name="$t('orderManagement.order.detail.operatingInfo.title')"
          class="order-detail-operating-info"
        >
          <OrderDetailTable
            :columns="operatingInfoCols"
            :data-source="operatingInfoList"
          />
        </OrderDetailTitle>
        <!--#endregion -->
      </div>
      <!--#endregion -->
      <!--#region 底部-->
      <div class="order-detail-foot">
        <a-button type="primary" @click="onConfirm" class="order-detail-foot-btn">
          {{ $t("button.confirm") }}
        </a-button>
        <a-button @click="onBack" class="order-detail-foot-btn">
          {{ $t("button.back") }}
        </a-button>
      </div>
      <!--#endregion -->
    </a-spin>
  </div>
</template>

<script>
import {areaListByCode, expressList, getOrderDetail, refund, send} from "@/pages/order/api";
import { FETCH_CODE } from "@/config";
import { showMsg } from "@/utils";
import OrderDetailTitle from "@/pages/order/components/Title.vue";
import OrderDetailTable from "@/pages/order/components/Table.vue";
import {confirmModal, listDicFormatter, productItemImgFormatter, productItemPriceFormatter} from "@/utils/tools";
import {getDic} from "@/api";
import { mapState } from "vuex";

export default {
  name: "OrderEdit",
  components: { OrderDetailTable, OrderDetailTitle },
  data() {
    return {
      loading: false,
      detail: {},
      orderStateList: [],
      progressList: [
        {
          no: 1,
          name: this.$t("orderManagement.order.detail.progress.createOrder"),
          status: "",
          active: false,
        },
        {
          no: 2,
          name: this.$t("orderManagement.order.detail.progress.payOrder"),
          status: "",
          active: false,
        },
        {
          no: 3,
          name: this.$t("orderManagement.order.detail.progress.sendOrder"),
          status: "",
          active: false,
        },
        {
          no: 4,
          name: this.$t("orderManagement.order.detail.progress.receivedOrder"),
          status: "",
          active: false,
        },
        {
          no: 5,
          name: this.$t("orderManagement.order.detail.progress.doneOrder"),
          status: "",
          active: false,
        },
      ],
      deliveryInfo: {
        expName: undefined,
        number: null,
      },
      deliveryInfoRules: {
        expName: [
          {
            required: true,
            message: this.$t("orderManagement.order.detail.deliveryInfo.expName.validation.required"),
            trigger: "blur",
          },
        ],
        number: [
          {
            required: true,
            message: this.$t("orderManagement.order.detail.deliveryInfo.number.validation.required"),
            trigger: "blur",
          },
          {
            max: 100,
            message: this.$t("orderManagement.order.detail.deliveryInfo.number.validation.max", { length: 100 }),
            trigger: "blur",
          },
        ],
      },
      deliveryInfoLabelCol: { span: 8 },
      deliveryInfoWrapperCol: { span: 16 },
      expressList: [],
      receiverInfoCols: [
        {
          title: this.$t("orderManagement.order.detail.receiverInfo.cols.consignee"),
          dataIndex: "consignee",
          key: "consignee",
        },
        {
          title: this.$t("orderManagement.order.detail.receiverInfo.cols.phone"),
          dataIndex: "phone",
          key: "phone",
        },
        {
          title: this.$t("orderManagement.order.detail.receiverInfo.cols.zipCode"),
          dataIndex: "zipCode",
          key: "zipCode",
        },
        {
          title: this.$t("orderManagement.order.detail.receiverInfo.cols.address"),
          dataIndex: "address",
          key: "address",
          scopedSlots: { customRender: "address" },
        },
      ],
      receiverInfoList: [],
      baseInfoCols1: [
        {
          title: this.$t("orderManagement.order.detail.baseInfo.cols.orderId"),
          dataIndex: "orderId",
          key: "orderId",
          align: "center",
          width: 150,
        },
        {
          title: this.$t("orderManagement.order.detail.baseInfo.cols.orderNumber"),
          dataIndex: "orderNumber",
          key: "orderNumber",
          align: "center",
          width: 150,
        },
        {
          title: this.$t("orderManagement.order.detail.baseInfo.cols.account"),
          dataIndex: "account",
          key: "account",
          align: "center",
          width: 150,
        },
        {
          title: this.$t("orderManagement.order.detail.baseInfo.cols.payWay"),
          dataIndex: "payWay",
          key: "payWay",
          align: "center",
          width: 150,
        },
        {
          title: this.$t("orderManagement.order.detail.baseInfo.cols.orderSource"),
          dataIndex: "orderSource",
          key: "orderSource",
          align: "center",
          width: 150,
        },
        {
          title: this.$t("orderManagement.order.detail.baseInfo.cols.orderTypeName"),
          dataIndex: "orderTypeName",
          key: "orderTypeName",
          align: "center",
          width: 150,
        },
      ],
      baseInfoCols2: [
        {
          title: this.$t("orderManagement.order.detail.baseInfo.cols.deliveryMethod"),
          dataIndex: "deliveryMethod",
          key: "deliveryMethod",
          align: "center",
          width: 150,
        },
        {
          title: this.$t("orderManagement.order.detail.baseInfo.cols.expNumber"),
          dataIndex: "expNumber",
          key: "expNumber",
          align: "center",
          width: 150,
        },
        {
          title: this.$t("orderManagement.order.detail.baseInfo.cols.autoReceived"),
          dataIndex: "autoReceived",
          key: "autoReceived",
          align: "center",
          width: 150,
        },
        {
          title: this.$t("orderManagement.order.detail.baseInfo.cols.orderAward"),
          dataIndex: "orderAward",
          key: "orderAward",
          align: "center",
          width: 150,
        },
        {
          title: this.$t("orderManagement.order.detail.baseInfo.cols.orderPoints"),
          dataIndex: "orderPoints",
          key: "orderPoints",
          align: "center",
          width: 150,
        },
        {
          title: this.$t("orderManagement.order.detail.baseInfo.cols.activityInfo"),
          dataIndex: "activityInfo",
          key: "activityInfo",
          align: "center",
          width: 150,
        },
      ],
      baseInfoList1: [],
      baseInfoList2: [],
      invoiceInfoCols: [
        {
          title: this.$t("orderManagement.order.detail.invoiceInfo.cols.invoiceType"),
          dataIndex: "invoiceType",
          key: "invoiceType",
          align: "center",
          width: 150,
        },
        {
          title: this.$t("orderManagement.order.detail.invoiceInfo.cols.invoiceHead"),
          dataIndex: "invoiceHead",
          key: "invoiceHead",
          align: "center",
          width: 150,
        },
        {
          title: this.$t("orderManagement.order.detail.invoiceInfo.cols.invoiceContent"),
          dataIndex: "invoiceContent",
          key: "invoiceContent",
          align: "center",
          width: 150,
        },
        {
          title: this.$t("orderManagement.order.detail.invoiceInfo.cols.invoiceInfo"),
          dataIndex: "invoiceInfo",
          key: "invoiceInfo",
          align: "center",
          width: 150,
        },
      ],
      invoiceInfoList: [],
      productInfoCols: [
        {
          title: this.$t("orderManagement.order.detail.productInfo.cols.productUrl"),
          dataIndex: "productUrl",
          key: "productUrl",
          align: "center",
          scopedSlots: { customRender: "productUrl" },
        },
        {
          title: this.$t("orderManagement.order.detail.productInfo.cols.productName"),
          dataIndex: "productName",
          key: "productName",
          align: "left",
        },
        {
          title: this.$t("orderManagement.order.detail.productInfo.cols.productPrice"),
          dataIndex: "productPrice",
          key: "productPrice",
          align: "left",
          scopedSlots: { customRender: "productPrice" },
        },
        {
          title: this.$t("orderManagement.order.detail.productInfo.cols.skuName"),
          dataIndex: "skuName",
          key: "skuName",
          align: "left",
        },
        {
          title: this.$t("orderManagement.order.detail.productInfo.cols.number"),
          dataIndex: "number",
          key: "number",
          align: "right",
        },
        {
          title: this.$t("orderManagement.order.detail.productInfo.cols.subtotal"),
          dataIndex: "subtotal",
          key: "subtotal",
          align: "right",
          scopedSlots: { customRender: "subtotal" },
        },
      ],
      productInfoList: [],
      productTotalPrice: 0,
      productTotalPoints: 0,
      productTotalGrowthPoints: 0,
      feeInfoCols1: [
        {
          title: this.$t("orderManagement.order.detail.feeInfo.cols.aaa1"),
          dataIndex: "productTotalPrice",
          key: "productTotalPrice",
          align: "center",
          width: 230,
          scopedSlots: { customRender: "productTotalPrice" },
        },
        {
          title: this.$t("orderManagement.order.detail.feeInfo.cols.freightPoints"),
          dataIndex: "freightPoints",
          key: "freightPoints",
          align: "center",
          width: 230,
        },
        {
          title: this.$t("orderManagement.order.detail.feeInfo.cols.aaa2"),
          dataIndex: "coupon",
          key: "coupon",
          align: "center",
          width: 230,
        },
        {
          title: this.$t("orderManagement.order.detail.feeInfo.cols.aaa3"),
          dataIndex: "pointsDiscount",
          key: "pointsDiscount",
          align: "center",
          width: 230,
        },
      ],
      feeInfoList1: [],
      feeInfoCols2: [
        {
          title: this.$t("orderManagement.order.detail.feeInfo.cols.aaa4"),
          dataIndex: "activityDiscount",
          key: "activityDiscount",
          align: "center",
          width: 230,
        },
        {
          title: this.$t("orderManagement.order.detail.feeInfo.cols.aaa5"),
          dataIndex: "discountMoney",
          key: "discountMoney",
          align: "center",
          width: 230,
        },
        {
          title: this.$t("orderManagement.order.detail.feeInfo.cols.aaa6"),
          dataIndex: "orderTotalPrice",
          key: "orderTotalPrice",
          align: "center",
          width: 230,
          scopedSlots: { customRender: "orderTotalPrice" },
        },
        {
          title: this.$t("orderManagement.order.detail.feeInfo.cols.aaa7"),
          dataIndex: "orderPayPrice",
          key: "orderPayPrice",
          align: "center",
          width: 230,
          scopedSlots: { customRender: "orderTotalPrice" },
        },
      ],
      feeInfoList2: [],
      operatingInfoCols: [
        {
          title: this.$t("orderManagement.order.detail.operatingInfo.cols.aaa1"),
          dataIndex: "createBy",
          key: "operator",
          align: "left",
        },
        {
          title: this.$t("orderManagement.order.detail.operatingInfo.cols.aaa2"),
          dataIndex: "createTime",
          key: "operateTime",
          align: "center",
        },
        {
          title: this.$t("orderManagement.order.detail.operatingInfo.cols.aaa3"),
          dataIndex: "stateName",
          key: "orderState",
          align: "center",
        },
        {
          title: this.$t("orderManagement.order.detail.operatingInfo.cols.aaa4"),
          dataIndex: "payState",
          key: "payState",
          align: "center",
        },
        {
          title: this.$t("orderManagement.order.detail.operatingInfo.cols.aaa5"),
          dataIndex: "expressState",
          key: "expressState",
          align: "center",
        },
        {
          title: this.$t("orderManagement.order.detail.operatingInfo.cols.aaa6"),
          dataIndex: "remark",
          key: "remark",
          align: "left",
        },
      ],
      operatingInfoList: [],
    };
  },
  computed: {
    ...mapState({
      platformConf: state => state.user.platformConf,
    }),
    productTotalPriceShow() {
      return productItemPriceFormatter(this.productTotalPrice);
    },
    productTotalPointsShow() {
      return productItemPriceFormatter(this.productTotalPoints);
    },
    productTotalGrowthPointsShow() {
      return productItemPriceFormatter(this.productTotalGrowthPoints);
    },
    isSendGoodBtn() {
      return this.detail.state === "paid";
    },
    isRefundBtn() {
      return ["paid", "shipped", "received"].includes(this.detail.state);
    }
  },
  methods: {
    async getOrderStateList() {
      const res = await getDic({
        code: "order_state",
      });
      const orderStateList = res.data || [];
      this.orderStateList = orderStateList.sort((a, b) => a.sort - b.sort);
    },
    async getDetail() {
      const params = {
        orderId: this.$route.params.orderId,
      };
      if (!params.orderId) {
        await showMsg({
          flag: FETCH_CODE.ERROR.KEY,
          msg: this.$t("orderManagement.order.detail.invalidOrderId"),
        });
        this.$router.back();
        return;
      }
      this.loading = true;
      const res = await getOrderDetail(params);
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        await showMsg(res);
        this.loading = false;
        // this.$router.back();
        return;
      }
      this.detail = res.data;
      // 订单进度
      const progressList = this.progressList.slice();
      progressList[0].status = res.data.createTime;
      progressList[0].active = true;
      progressList[1].status = this.$t("orderManagement.order.detail.progress.payOrder2");
      const currOrderStateIdx = this.orderStateList.findIndex((it) => it.dictCode === res.data.state);
      // 当下标大于等于 paid 时 为已支付
      const paidOrderStateIdx = this.orderStateList.findIndex((it) => it.dictCode === "paid");
      if (currOrderStateIdx >= paidOrderStateIdx) {
        progressList[1].active = true;
        progressList[1].status = this.$t("orderManagement.order.detail.progress.payOrder1");
        progressList[2].status = this.$t("orderManagement.order.detail.progress.sendOrder2");
      }
      // 当下标大于等于 shipped 时 为已发货
      const shippedOrderStateIdx = this.orderStateList.findIndex((it) => it.dictCode === "shipped");
      if (currOrderStateIdx >= shippedOrderStateIdx) {
        progressList[2].active = true;
        progressList[2].status = this.$t("orderManagement.order.detail.progress.sendOrder1");
        progressList[3].status = this.$t("orderManagement.order.detail.progress.receivedOrder2");
      }
      // 当下标大于等于 received 时 为已收货
      const receivedOrderStateIdx = this.orderStateList.findIndex((it) => it.dictCode === "received");
      if (currOrderStateIdx >= receivedOrderStateIdx) {
        progressList[3].active = true;
        progressList[3].status = this.$t("orderManagement.order.detail.progress.receivedOrder1");
      }
      // TODO 完成评价
      this.progressList = progressList;
      // 收货人信息
      const shoppiingAddress = res.data.shoppiingAddress;
      const areaRes = await areaListByCode({
        codes: `${shoppiingAddress.province},${shoppiingAddress.city},${shoppiingAddress.county}`,
      });
      if (shoppiingAddress) {
        this.receiverInfoList = [{
          key: shoppiingAddress.id,
          consignee: shoppiingAddress.consignee,
          phone: shoppiingAddress.phone,
          zipCode: shoppiingAddress.zipCode,
          provinceName: areaRes.data[0]?.name,
          cityName: areaRes.data[1]?.name,
          countyName: areaRes.data[2]?.name,
          detailAddress: shoppiingAddress.detailAddress,
        }];
      }
      // 基本信息-1
      this.baseInfoList1 = [{
        key: "1",
        orderId: res.data.orderId,
        orderNumber: res.data.freightNo, // 发货单流水号
        account: "", // 用户账号
        payWay: "", // 支付方式
        orderSource: "", // 订单来源
        orderTypeName: res.data.productTypeName, // 订单类型
      }];
      // 基本信息-2
      this.baseInfoList2 = [{
        key: "1",
        deliveryMethod: res.data.expBody?.expName, // 配送方式
        expNumber: res.data.expBody?.number, // 物流单号
        autoReceived: "", // 自动确认收货时间
        orderAward: "", // 订单可得优币
        orderPoints: "", // 订单可得成长值
        activityInfo: "", // 活动信息
      }];
      //  发票信息
      this.invoiceInfoList = [];
      // 商品信息
      this.productInfoList = [{
        key: "1",
        productUrl: productItemImgFormatter(res.data.pictureUrl), // 商品图片
        productName: res.data.productName, // 商品名称
        productPrice: productItemPriceFormatter(res.data.salesPrice || 0), // 价格
        skuNo: res.data.skuNo, // 货号
        skuName: res.data.skuName, // 属性
        number: res.data.number, // 数量
        productStock: res.data.stock || "--", // 库存
        payPrice: res.data.payPrice, // 小计
        payPoints: res.data.payPoints, // 小计
        payGrowthPoints: res.data.payGrowthPoints, // 小计
        payPriceShow: productItemPriceFormatter(res.data.payPrice), // 小计
        payPointsShow: productItemPriceFormatter(res.data.payPoints), // 小计
        payGrowthPointsShow: productItemPriceFormatter(res.data.payGrowthPoints), // 小计
      }];
      // 商品信息合计 - 分
      this.productTotalPrice = res.data.payPrice;
      this.productTotalPoints = res.data.payPoints;
      this.productTotalGrowthPoints = res.data.payGrowthPoints;
      // 费用信息-1
      this.feeInfoList1 = [{
        key: "1",
        productTotalPrice: productItemPriceFormatter(res.data.payPrice), // 商品合计
        freightPoints: res.data.freight?.freightPoints ? productItemPriceFormatter(res.data.freight?.freightPoints) : "--", // 运费
        coupon: "--", // 优惠券
        pointsDiscount: "--", // 成长积分抵扣
      }];
      // 费用信息-2
      this.feeInfoList2 = [{
        key: "1",
        activityDiscount: "--", // 活动优惠
        discountMoney: "--", // 折扣金额
        orderTotalPrice: productItemPriceFormatter(res.data.payPrice), // 订单总金额
        orderPayPrice: productItemPriceFormatter(res.data.payPrice), // 应付款金额
      }];
      // 操作信息
      let operatingInfoList = res.data.orderStateRecordList || [];
      operatingInfoList = await listDicFormatter(operatingInfoList, [
        { code: "order_state", key: "state" },
      ]);
      this.operatingInfoList = operatingInfoList.map((it) => ({
        ...it,
        payState: res.data.stateName, // 付款状态
        expressState: res.data.expBody?.deliverystatus || "--", // 发货状态
      }));
      this.loading = false;
    },
    async getExpressList() {
      const res = await expressList({});
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) return;
      this.expressList = res.data;
    },
    // 发货
    async onShip() {
      if (this.loading) return;
      this.loading = true;
      this.$refs.orderDeliveryInfo.validate(async (valid) => {
        if (!valid) {
          this.loading = false;
          return;
        }
        const confirmed = await confirmModal(
          this.$t("orderManagement.order.detail.send"),
          this.$t("orderManagement.order.detail.confirmSend"),
        );
        if (!confirmed) {
          this.loading = false;
          return;
        }
        const params = {
          expCode: this.deliveryInfo.number,
          expressId: this.deliveryInfo.expName,
          id: this.detail.id,
          orderId: this.detail.orderId,
          remark: "",
        };
        const res = await send(params);
        if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
          showMsg(res);
          this.loading = false;
          return;
        }
        showMsg({ flag: 0, msg: this.$t("orderManagement.order.detail.sendSuccess") });
        this.loading = false;
      });
    },
    // 退款
    async onRefund() {
      if (this.loading) return;
      const confirmed = await confirmModal(
        this.$t("orderManagement.order.detail.confirm"),
        this.$t("orderManagement.order.detail.confirmRefund"),
      );
      if (!confirmed) return;
      this.loading = true;
      const params = {
        status: "1", // 申请状态 0 待审核 1 已通过 2 已拒绝
        id: this.detail.id,
        orderId: this.detail.orderId,
        remark: "订单管理后台详情页面【万不得已的退款】",
      };
      const res = await refund(params);
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        this.loading = false;
        return;
      }
      showMsg({ flag: 0, msg: this.$t("orderManagement.order.detail.refundSuccess") });
      this.loading = false;
    },
    onConfirm() {
      this.onBack();
    },
    onBack() {
      this.$router.back();
    },
  },
  mounted() {
    this.getExpressList();
    this.getOrderStateList().then(() => {
      this.getDetail();
    });
  },
};
</script>

<style lang="less" scoped>
.order-detail {
  &-wrapper {}
  &-progress {
    &-top {
      border: 1px solid #E9EBFB;
      box-shadow: 0px 4px 4px 0px rgba(15, 113, 222, 0.06);
      padding: 19px 21px 47px;
    }
    &-title {
      font-size: 18px;
      font-weight: 400;
      color: #293B58;
    }
    &-list {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-wrap: nowrap;
    }
    &-item {
      text-align: center;
      position: relative;
      padding-top: 50px;
      min-width: 110px;
      cursor: default;

      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        position: absolute;
        z-index: 1;
        border-top: 1px dashed #CCD8F0;
        left: 0;
        top: 25px;
      }
      &:first-child::before {
        width: 50%;
        left: unset;
        right: 0;
      }
      &:last-child::before {
        width: 50%;
      }
    }
    &-step {
      width: 50px;
      height: 50px;
      line-height: 42px;
      border: 4px solid #CCD8F0;
      font-size: 22px;
      font-weight: 400;
      color: #A6AAB2;
      background: #ffffff;
      border-radius: 50%;
      position: absolute;
      z-index: 2;
      top: 0;
      left: 50%;
      transform: translateX(-50%);

      &.active {
        background: @primary-color;
        color: #ffffff;
      }
      &-name {
        font-size: 14px;
        font-weight: 400;
        color: #293B58;
        line-height: 24px;
        margin-top: 6px;
      }
      &-status {
        font-size: 14px;
        font-weight: 400;
        color: #A6AAB2;
        line-height: 24px;
      }
    }
    &-bot {
      font-size: 18px;
      font-weight: 400;
      color: #F03738;
      line-height: 21px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 40px;
      margin-bottom: 21px;

      &::before {
        content: "";
        display: block;
        width: 9px;
        height: 9px;
        border-radius: 50%;
        background: #F03738;
        margin-right: 15px;
      }
    }
  }
  &-info {
    padding-top: 20px;
    border-top: 1px solid #E2E8EE;

    .order-detail-delivery-info {
      &-foot {
        padding-left: 76px;
      }
      &-btn {
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
    .order-detail-product-info-total {
      text-align: right;
    }
    .order-detail-product-img {
      width: 50px;
      height: 50px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      margin: 0 auto;
    }
  }
  &-foot {
    margin-top: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;

    &-btn {
      margin-right: 31px;
      width: 146px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
