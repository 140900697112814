<template>
  <div>
    <div class="search-head">
      <a-input
          size="large"
          v-model="name"
          :placeholder="$t('productManagement.promotionalLabel.list.search')"
          allowClear
          class="search-input"
          @keydown.enter="search"
      />
      <a-button type="primary" @click="search">
        {{ $t("button.filter") }}
      </a-button>
    </div>
    <ECTable
        showTitle
        :title="$t('productManagement.promotionalLabel.list.title')"
        :columns="tableColumns"
        :data-source="dataList"
        bordered
        rowKey="id"
        key="id"
        :loading="loading"
        :pagination="{ total, current, pageSize }"
        @change="handleTableChange"
    >
      <div slot="tableHeaderRight" class="table-buttons">
        <a-button type="primary" @click="onAdd">
          {{ $t("productManagement.promotionalLabel.list.add") }}
        </a-button>
      </div>
      <template slot="sort" slot-scope="text, record, index">
        {{ (current - 1) * pageSize + (index + 1) }}
      </template>
      <template slot="icon" slot-scope="text">
        <img style="width: 30px;max-height: 60px;" :src="text" alt="" v-if="text" />
        <span v-else>--</span>
      </template>
      <template slot="state" slot-scope="text, record">
        <a-switch :checked="record.state" />
      </template>
      <!-- 操作列 -->
      <template slot="action" slot-scope="text, record">
        <div class="table-operations">
          <a-button type="link" class="table-btn" @click="edit(record)">
            <a-icon type="edit" />
            {{ $t("button.edit") }}
          </a-button>
          <a-popconfirm
              :title="$t('button.confirmDelete')"
              :ok-text="$t('button.yes')"
              :cancel-text="$t('button.no')"
              @confirm="onDelete(record)"
          >
            <a-button type="link" class="table-btn danger">
              <a-icon type="delete" />
              {{ $t("button.remove") }}
            </a-button>
          </a-popconfirm>
        </div>
      </template>
    </ECTable>
  </div>
</template>

<script>
import ECTable from "@/components/ECTable";
import { delPromotion, pagePromotion } from "../api";
import { FETCH_CODE } from "@/config";
import { showMsg } from "@/utils";
import {trim} from "lodash";
import {listDicFormatter} from "@/utils/tools";

export default {
  data() {
    return {
      name: "",
      dataList: [],
      tableColumns: [
        {
          title: this.$t("productManagement.promotionalLabel.list.cols.id"),
          dataIndex: "id",
          align: "left",
          width: 120,
        },
        {
          title: this.$t("productManagement.promotionalLabel.list.cols.img"),
          dataIndex: "icon",
          scopedSlots: { customRender: "icon" },
          align: "center",
          width: 80,
        },
        {
          title: this.$t("productManagement.promotionalLabel.list.cols.name"),
          dataIndex: "name",
          align: "left",
          width: 200,
        },
        {
          title: this.$t("productManagement.promotionalLabel.list.cols.type"),
          dataIndex: "promotionTypeName",
          align: "center",
          width: 100,
        },
        {
          title: this.$t("productManagement.promotionalLabel.list.cols.state"),
          dataIndex: "state",
          scopedSlots: { customRender: "state" },
          align: "center",
          width: 80,
        },
        {
          title: this.$t("productManagement.promotionalLabel.list.cols.note"),
          dataIndex: "remark",
          align: "left",
        },
        {
          title: this.$t("action"),
          dataIndex: "action",
          key: "action",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 130,
        },
      ],
      current: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      exportLoading: false,
    };
  },
  components: {
    ECTable,
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const res = await pagePromotion({
        name: trim(this.name),
        pageNum: this.current,
        pageSize: this.pageSize,
      });
      const dataList = res.data.records || [];
      this.dataList = await listDicFormatter(dataList, [
        { code: "promotion_type", key: "promotionType" },
      ]);
      this.current = res.data.current;
      this.total = res.data.total;
      this.pageSize = res.data.size;
      this.loading = false;
    },
    edit(record) {
      this.$router.push({
        name: "promotionalLabelEdit",
        params: {
          id: record.id,
          name: record.name,
          promotionType: record.promotionType,
          icon: record.icon,
          remark: record.remark,
          state: record.state ? "1" : "0",
        },
      });
    },
    async onDelete(record) {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const res = await delPromotion({
        id: record.id,
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        this.loading = false;
        return;
      }
      showMsg({ flag: 0, msg: this.$t("showMsg.success.delete") });
      this.loading = false;
      this.getList();
    },
    onAdd() {
      this.$router.push({
        name: "promotionalLabelAdd",
      });
    },
    search() {
      this.current = 1;
      this.getList();
    },
    handleTableChange(page) {
      this.current = page.current;
      this.pageSize = page.pageSize;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.search-head {
  padding: @ec-gutter20 0;
}
.search-input {
  width: 300px;
  margin-right: @ec-gutter;
}
.table-buttons {
  &> * {
    margin-left: @ec-gutter;
  }
}
.table-operations {
  display: flex;
  justify-content: center;
  align-items: center;

  .table-btn {
    &.danger {
      color: @error-color;
    }
  }
}
</style>
