<template>
  <div class="product-list">
    <ListFilter
        :formList="formList"
        @filter="onSearch"
        @reset="onReset"
    >
      <a-form-model-item
        slot="supplier"
        :label="$t('productManagement.product.list.supplier')"
    >
      <a-select
          :getPopupContainer="trigger => trigger.parentNode"
          size="large"
          v-model="filterForm.supplierCode"
          :placeholder="$t('productManagement.product.list.supplierPlaceholder')"
          allowClear
      >
        <a-select-option
            v-for="op in supplierList"
            :key="op.supplierCode"
            :value="op.supplierCode"
        >
          {{ op.supplierName }}
        </a-select-option>
      </a-select>
    </a-form-model-item>
      <a-form-model-item
        slot="brand"
        :label="$t('productManagement.product.list.brand')"
    >
      <a-select
          :getPopupContainer="trigger => trigger.parentNode"
          size="large"
          v-model="filterForm.brandCode"
          :placeholder="$t('productManagement.product.list.brandPlaceholder')"
          allowClear
      >
        <a-select-option
            v-for="op in brandList"
            :key="op.brandCode"
            :value="op.brandCode"
        >
          {{ op.brandName }}
        </a-select-option>
      </a-select>
    </a-form-model-item>
      <a-form-model-item
          slot="classification"
          :label="$t('productManagement.product.list.classification')"
      >
        <a-select
            :getPopupContainer="trigger => trigger.parentNode"
            size="large"
            v-model="filterForm.categoryCode"
            :placeholder="$t('productManagement.product.list.classificationPlaceholder')"
            allowClear
        >
          <a-select-option
              v-for="op in categoryList"
              :key="op.categoryCode"
              :value="op.categoryCode"
          >
            {{ op.categoryName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item
          slot="type"
          :label="$t('productManagement.product.list.type')"
      >
        <a-select
            :getPopupContainer="trigger => trigger.parentNode"
            size="large"
            v-model="filterForm.productType"
            :placeholder="$t('productManagement.product.list.typePlaceholder')"
            allowClear
        >
          <a-select-option
              v-for="op in productTypeList"
              :key="op.dictCode"
              :value="op.dictCode"
          >
            {{ op.dictName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
    </ListFilter>
    <div class="product-list_table">
      <a-row class="table-head">
        <a-col :span="8" class="table-head_title">
          {{ $t("productManagement.product.list.title") }}
        </a-col>
        <a-col :span="16">
          <a-row type="flex" justify="end" :gutter="10">
            <a-col>
              <a-button type="primary" @click="onAdd">
                {{ $t("productManagement.product.list.add") }}
              </a-button>
            </a-col>
            <a-col>
              <a-button type="primary" @click="onUpOrDownBatch(true)">
                {{ $t("button.onShelf") }}
              </a-button>
            </a-col>
            <a-col>
              <a-button type="warning" @click="onUpOrDownBatch(false)">
                {{ $t("button.offShelf") }}
              </a-button>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
      <a-tabs v-model:activeKey="activeTab" @change="onChangeTab">
        <a-tab-pane :tab="tab.tab" v-for="tab in tabList" :key="tab.key">
          <ECTable
            :columns="tableColumns"
            :data-source="tab.dataList"
            bordered
            :row-selection="{
              columnWidth: 50,
              selectedRowKeys: tab.selectedProduct,
              onChange: (params) => onSelectChange(params, tab),
            }"
            :rowKey="
              (record, index) => {
                return index;
              }
            "
            :loading="tab.loading"
            :pagination="{ total: tab.total, current: tab.current, pageSize: tab.pageSize }"
            @change="handleTableChange"
            :scroll="{ x: true }"
          >
            <template slot="index" slot-scope="record, text, index">
              {{ index + 1 }}
            </template>
            <template slot="pictureUrl" slot-scope="text">
              <img
                style="width: 30px; max-height: 60px"
                :src="text"
                alt=""
                v-if="text"
              />
              <span v-else>--</span>
            </template>
            <template slot="productName" slot-scope="record, text">
              <span class="ellipsis" :title="text.productName">{{ text.productName }}</span>
            </template>
            <template slot="upAndDownShelfState" slot-scope="record, text">
              {{ text.upAndDownShelfState ? $t("productManagement.product.list.upShelf") : $t("productManagement.product.list.downShelf") }}
            </template>
            <template slot="isRecommend" slot-scope="record, text">
              {{ text.isRecommend ? $t("button.yes") : $t("button.no") }}
            </template>
            <span slot="footerCustom" class="footer-btns">
              <a-button type="primary" @click="onSetDiscountTag">
                {{ $t("button.set") }}{{ $t("productManagement.promotionalLabel.list.title") }}
              </a-button>
              <a-button type="primary" @click="onCancelDiscountTag">
                {{ $t("button.cancel") }}{{ $t("productManagement.promotionalLabel.list.title") }}
              </a-button>
              <a-button type="primary" @click="onSetRecommend">
                {{ $t("productManagement.product.list.batchRec") }}
              </a-button>
              <a-button type="primary" @click="onCancelRecommend">
                {{ $t("button.cancel") }}{{ $t("productManagement.product.list.cols.recommend") }}
              </a-button>
              <a-button type="danger" ghost @click="onDeleteMulti">
                {{ $t("button.batchDel") }}
              </a-button>
            </span>
            <template slot="action" slot-scope="text, record">
              <div class="table-operations">
                <a-popconfirm
                  :title="$t('button.onShelfConfirm')"
                  :ok-text="$t('button.yes')"
                  :cancel-text="$t('button.no')"
                  @confirm="onOnShelf(record)"
                  :disabled="record.upAndDownShelfState"
                >
                  <a-button type="link" class="table-btn" :disabled="record.upAndDownShelfState">
                    <a-icon type="cloud-upload" />
                    {{ $t("button.onShelf") }}
                  </a-button>
                </a-popconfirm>
                <a-popconfirm
                  :title="$t('button.offShelfConfirm')"
                  :ok-text="$t('button.yes')"
                  :cancel-text="$t('button.no')"
                  @confirm="onOffShelf(record)"
                  :disabled="!record.upAndDownShelfState"
                >
                  <a-button type="link" class="table-btn warning" :disabled="!record.upAndDownShelfState">
                    <a-icon type="cloud-download" />
                    {{ $t("button.offShelf") }}
                  </a-button>
                </a-popconfirm>
                <a-button
                  type="link"
                  class="table-btn"
                  @click="onEdit(record)"
                >
                  <i class="iconfont icon-edit table-icon-center"></i>
                  {{ $t("button.edit") }}</a-button
                >
                <a-popconfirm
                  :title="$t('button.confirmDelete')"
                  :ok-text="$t('button.yes')"
                  :cancel-text="$t('button.no')"
                  @confirm="onDelete(record)"
                >
                  <a-button type="link" class="table-btn danger">
                    <a-icon type="delete" />
                    {{ $t("button.remove") }}
                  </a-button>
                </a-popconfirm>
              </div>
            </template>
          </ECTable>
        </a-tab-pane>
      </a-tabs>
    </div>
    <PromotionProductModal
      :product-list="promotionProductList"
      :visible.sync="promotionProductVisible"
      :is-cancel="promotionProductCancel"
      @reload="getList"
      @close="onPromotionProductClose"
    />
  </div>
</template>

<script>
import ListFilter from "@/pages/home/components/ListFilter";
import ECTable from "@/components/ECTable";
import {
  delProduct,
  delProductBatch,
  getCategory,
  getProduct, lowerProduct,
  recommendProductBatch, upOrDownProduct, upperProduct
} from "./api";
import { FETCH_CODE } from "@/config/fetch";
import { showMsg } from "@/utils";
import Vue from "vue";
import {getBrandAll, getSupplierAll} from "@/pages/supplier/api";
import {getDic} from "@/api";
import moment from "moment";
import {DateFormatFull} from "@/config";
import { cloneDeep, trim } from "lodash";
import { confirmModal, productItemImgFormatter } from "@/utils/tools";
import PromotionProductModal from "@/pages/product/PromotionProduct/Modal.vue";
const formList = [
  {
    label: Vue.prototype.$t("productManagement.product.list.supplier"),
    type: "slot",
    slotName: "supplier",
  },
  {
    label: Vue.prototype.$t("productManagement.product.list.number"),
    placeholder: Vue.prototype.$t("productManagement.product.list.numberPlaceholder"),
    key: "number",
    type: "input",
  },
  {
    label: Vue.prototype.$t("productManagement.product.list.brand"),
    type: "slot",
    slotName: "brand",
  },
  {
    label: Vue.prototype.$t("productManagement.product.list.name"),
    placeholder: Vue.prototype.$t("productManagement.product.list.namePlaceholder"),
    key: "name",
    type: "input",
  },
  {
    label: Vue.prototype.$t("productManagement.product.list.type"),
    type: "slot",
    slotName: "type",
  },
  {
    label: Vue.prototype.$t("productManagement.product.list.classification"),
    type: "slot",
    slotName: "classification",
  },
  {
    label: Vue.prototype.$t("productManagement.product.list.createTime"),
    placeholder: Vue.prototype.$t("productManagement.product.list.createTimePlaceholder"),
    key: "createTime",
    type: "time",
    showTime: false,
  },
  {
    label: Vue.prototype.$t("productManagement.product.list.AddTime"),
    placeholder: Vue.prototype.$t("productManagement.product.list.AddTimePlaceholder"),
    key: "AddTime",
    type: "time",
    showTime: false,
  },
  {
    label: Vue.prototype.$t("productManagement.product.list.shelfTime"),
    placeholder: Vue.prototype.$t("productManagement.product.list.shelfTimePlaceholder"),
    key: "shelfTime",
    type: "time",
    showTime: false,
  },
];
const tableColumns = [
  {
    title: Vue.prototype.$t("productManagement.product.list.cols.sort"),
    key: "index",
    scopedSlots: { customRender: "index" },
    align: "center",
    width: 80,
  },
  {
    title: Vue.prototype.$t("productManagement.product.list.number"),
    key: "productNo",
    dataIndex: "productNo",
    align: "left",
    width: 120,
  },
  {
    title: Vue.prototype.$t("productManagement.product.list.cols.img"),
    dataIndex: "pictureUrl",
    align: "center",
    scopedSlots: { customRender: "pictureUrl" },
    width: 90,
  },
  {
    title: Vue.prototype.$t("productManagement.product.list.name"),
    dataIndex: "productName",
    scopedSlots: { customRender: "productName" },
    align: "left",
    width: 230,
  },
  {
    title: Vue.prototype.$t("productManagement.product.list.classification"),
    dataIndex: "categoryName",
    align: "center",
    width: 100,
  },
  {
    title: Vue.prototype.$t("productManagement.product.list.cols.stock"),
    dataIndex: "stock",
    align: "right",
    width: 100,
  },
  {
    title: Vue.prototype.$t("productManagement.product.list.cols.num"),
    dataIndex: "saleNumber",
    align: "right",
    width: 90,
  },
  {
    title: Vue.prototype.$t("productManagement.product.list.cols.recommend"),
    dataIndex: "isRecommend",
    scopedSlots: { customRender: "isRecommend" },
    align: "center",
    width: 100,
  },
  {
    title: Vue.prototype.$t("productManagement.product.list.cols.status"),
    dataIndex: "upAndDownShelfState",
    scopedSlots: { customRender: "upAndDownShelfState" },
    align: "center",
    width: 100,
  },
  {
    title: Vue.prototype.$t("action"),
    dataIndex: "action",
    key: "action",
    align: "center",
    scopedSlots: { customRender: "action" },
    width: 130,
  },
];
export default {
  components: {
    PromotionProductModal,
    ListFilter,
    ECTable,
  },
  data() {
    return {
      formList,
      filterForm: {},
      tableColumns,
      activeTab: "ALL",
      tabList: [
        {
          key: "ALL",
          tab: this.$t('productManagement.product.list.tabAll'),
          dataList: [],
          selectedProduct: [],
          loading: false,
          total: 0,
          current: 1,
          pageSize: 10,
          operationLoading: false,
        },
        {
          key: "2",
          tab: this.$t('productManagement.product.list.tabAudit'),
          dataList: [],
          selectedProduct: [],
          loading: false,
          total: 0,
          current: 1,
          pageSize: 10,
          operationLoading: false,
        },
        {
          key: 3,
          tab: this.$t('productManagement.product.list.tabAdd'),
          dataList: [],
          selectedProduct: [],
          loading: false,
          total: 0,
          current: 1,
          pageSize: 10,
          operationLoading: false,
        },
        {
          key: 4,
          tab: this.$t('productManagement.product.list.tabShelf'),
          dataList: [],
          selectedProduct: [],
          loading: false,
          total: 0,
          current: 1,
          pageSize: 10,
          operationLoading: false,
        },
      ],
      brandList: [],
      categoryList: [],
      productTypeList: [],
      supplierList: [],
      promotionProductList: [],
      promotionProductVisible: false,
      promotionProductCancel: false,
    };
  },
  computed: {
    currentTab() {
      return this.tabList.find((it) => it.key === this.activeTab);
    },
  },
  mounted() {
    this.getList();
    this.getBrandList();
    this.getCateList();
    this.getProductTypeList();
    this.getSupplierList();
  },
  methods: {
    onSearch(filterForm) {
      const currentTab = this.tabList.find((it) => it.key === this.activeTab);
      if (!currentTab) return;
      currentTab.current = 1;
      this.getList(filterForm);
    },
    onReset() {
      this.filterForm = {};
    },
    handleTableChange(pagination) {
      const currentTab = this.tabList.find((it) => it.key === this.activeTab);
      if (!currentTab) return;
      currentTab.current = pagination.current;
      currentTab.pageSize = pagination.pageSize;
      this.getList();
    },
    onSelectChange(value) {
      const currentTab = this.tabList.find((it) => it.key === this.activeTab);
      if (!currentTab) return;
      currentTab.selectedProduct = value;
    },
    async getList(filterForm = {}) {
      const currentTab = this.tabList.find((it) => it.key === this.activeTab);
      if (!currentTab) return;
      currentTab.loading = true;
      const params = {
        pageNum: currentTab.current,
        pageSize: currentTab.pageSize,
        ...this.filterForm,
        // 搜索内容（商品名称、商品编码，sku编码）
        search: trim(filterForm.name) || trim(filterForm.number),
      };
      // 已上架
      if (this.activeTab === this.tabList[2].key) {
        params.upAndDownShelfState = true;
      }
      // 已下架
      if (this.activeTab === this.tabList[3].key) {
        params.upAndDownShelfState = false;
      }
      // 创建时间
      if (filterForm.createTime) {
        params.createTimeStart = moment(filterForm.createTime).startOf("date").format(DateFormatFull);
        params.createTimeEnd = moment(filterForm.createTime).endOf("date").format(DateFormatFull);
      }
      // 上架时间
      if (filterForm.AddTime) {
        params.upShelfTimeStart = moment(filterForm.AddTime).startOf("date").format(DateFormatFull);
        params.upShelfTimeEnd = moment(filterForm.AddTime).endOf("date").format(DateFormatFull);
      }
      // 下架时间
      if (filterForm.shelfTime) {
        params.downShelfTimeStart = moment(filterForm.shelfTime).startOf("date").format(DateFormatFull);
        params.downShelfTimeEnd = moment(filterForm.shelfTime).endOf("date").format(DateFormatFull);
      }
      const res = await getProduct(params);
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        currentTab.loading = false;
        return;
      }
      const dataList = res.data.records || [];
      currentTab.dataList = dataList.map((it) => {
        return {
          ...it,
          key: it.productNo,
          pictureUrl: productItemImgFormatter(it.pictureUrl),
        };
      });
      currentTab.current = res.data.current;
      currentTab.pageSize = res.data.size;
      currentTab.total = res.data.total;
      currentTab.loading = false;
    },
    async onDelete(record) {
      const currentTab = this.tabList.find((it) => it.key === this.activeTab);
      if (!currentTab) return;
      if (currentTab.operationLoading) return;
      currentTab.operationLoading = true;
      const res = await delProduct({
        id: record.id,
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        currentTab.operationLoading = false;
        return;
      }
      showMsg({ flag: FETCH_CODE.SUCCESS.KEY, msg: this.$t("showMsg.success.delete") });
      currentTab.operationLoading = false;
      this.getList();
    },
    onEdit(record) {
      this.$router.push({
        name: "ProductEdit",
        params: {
          ...record,
        },
      });
    },
    onAdd() {
      this.$router.push({
        name: "ProductAdd",
      });
    },
    onChangeTab () {
      this.getList();
    },
    async getBrandList() {
      const res = await getBrandAll();
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        return;
      }
      const brandList = res.data || [];
      this.brandList = brandList.filter((it) => it.state);
    },
    async getCateList() {
      const res = await getCategory({
        pageSize: 999,
        pageNum: 1,
        state: true,
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        return;
      }
      this.categoryList = res.data.records || [];
    },
    async getProductTypeList() {
      const res = await getDic({
        code: "product_type",
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) return;
      const productTypeList = res.data || [];
      this.productTypeList = productTypeList.filter((it) => it.state);
    },
    async getSupplierList() {
      const res = await getSupplierAll({ _ts: +new Date() });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) return;
      const supplierList = res.data || [];
      this.supplierList = supplierList.filter((it) => it.state);
    },
    // 批量操作前置判断
    handleMulti(options = {}) {
      const currentTab = this.tabList.find((it) => it.key === this.activeTab);
      const result = { valid: true, currentTab };
      if (!currentTab) {
        result.valid = false;
        return result;
      }
      return new Promise((resolve) => {
        if (!currentTab.selectedProduct || !currentTab.selectedProduct.length) {
          showMsg({
            flag: FETCH_CODE.WARNING.KEY,
            msg: this.$t("productManagement.product.list.selectProduct"),
          });
          result.valid = false;
          resolve(result);
          return;
        }
        if (currentTab.operationLoading) {
          result.valid = false;
          resolve(result);
          return;
        }
        const handleData = () => {
          currentTab.selectedProductItem = currentTab.selectedProduct
            .map((productNo) => {
              const curr = currentTab.dataList.find((it) => it.productNo === productNo);
              if (!curr) return null;
              return {
                ...curr,
              };
            }).filter((it) => !!it);
          resolve(result);
        };
        if (!options.isConfirm) {
          handleData();
          return;
        }
        confirmModal(
          this.$t("showMsg.confirm.batchTitle", { msg: options.msg }),
          this.$t("showMsg.confirm.batch", { msg: options.msg }),
        ).then((confirmed) => {
          if (!confirmed) {
            result.valid = false;
            resolve(result);
            return;
          }
          handleData();
        });
      });
    },
    // 设置促销标签
    async onSetDiscountTag() {
      const multiRes = await this.handleMulti({
        msg: `${this.$t("button.set")}${this.$t("productManagement.promotionalLabel.list.title")}`,
        isConfirm: false,
      });
      if (!multiRes.valid) return;
      multiRes.operationLoading = true;
      this.promotionProductCancel = false;
      this.promotionProductVisible = true;
      this.promotionProductList = cloneDeep(multiRes.currentTab.selectedProductItem);
      multiRes.operationLoading = false;
    },
    // 取消促销标签
    async onCancelDiscountTag() {
      const multiRes = await this.handleMulti({
        msg: `${this.$t("button.cancel")}${this.$t("productManagement.promotionalLabel.list.title")}`,
        isConfirm: false,
      });
      if (!multiRes.valid) return;
      multiRes.operationLoading = true;
      this.promotionProductCancel = true;
      this.promotionProductVisible = true;
      this.promotionProductList = cloneDeep(multiRes.currentTab.selectedProductItem);
      multiRes.operationLoading = false;
    },
    onPromotionProductClose() {},
    // 批量推荐
    async onSetRecommend() {
      const multiRes = await this.handleMulti({
        msg: `${this.$t("productManagement.product.list.cols.recommend")}`,
        isConfirm: true,
      });
      if (!multiRes.valid) return;
      multiRes.operationLoading = true;
      const params = multiRes.currentTab.selectedProductItem.map((it, idx) => ({
        isRecommend: true,
        productNo: it.productNo,
        remark: `${this.$t("productManagement.product.list.title2")}${this.$t("productManagement.product.list.cols.recommend")}`,
        sort: idx,
      }));
      const res = await recommendProductBatch(params);
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        multiRes.operationLoading = false;
        return;
      }
      showMsg({ flag: FETCH_CODE.SUCCESS.KEY, msg: this.$t("showMsg.success.operation") });
      multiRes.currentTab.selectedProduct = [];
      multiRes.operationLoading = false;
      this.getList();
    },
    // 取消推荐
    async onCancelRecommend() {
      const multiRes = await this.handleMulti({
        msg: `${this.$t("button.cancel")}${this.$t("productManagement.product.list.cols.recommend")}`,
        isConfirm: true,
      });
      if (!multiRes.valid) return;
      multiRes.operationLoading = true;
      const params = multiRes.currentTab.selectedProductItem.map((it, idx) => ({
        isRecommend: false,
        productNo: it.productNo,
        remark: `${this.$t("productManagement.product.list.title2")}${this.$t("button.cancel")}${this.$t("productManagement.product.list.cols.recommend")}`,
        sort: idx,
      }));
      const res = await recommendProductBatch(params);
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        multiRes.operationLoading = false;
        return;
      }
      showMsg({ flag: FETCH_CODE.SUCCESS.KEY, msg: this.$t("showMsg.success.operation") });
      multiRes.currentTab.selectedProduct = [];
      multiRes.operationLoading = false;
      this.getList();
    },
    // 批量删除
    async onDeleteMulti() {
      const multiRes = await this.handleMulti({
        msg: `${this.$t("button.remove")}`,
        isConfirm: true,
      });
      if (!multiRes.valid) return;
      multiRes.operationLoading = true;
      const res = await delProductBatch({
        productNos: multiRes.currentTab.selectedProductItem.map((it) => it.productNo),
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        multiRes.operationLoading = false;
        return;
      }
      showMsg({ flag: FETCH_CODE.SUCCESS.KEY, msg: this.$t("showMsg.success.operation") });
      multiRes.currentTab.selectedProduct = [];
      multiRes.operationLoading = false;
      this.getList();
    },
    // 批量上下架商品
    async onUpOrDownBatch(isUp) {
      const multiRes = await this.handleMulti({
        msg: `${isUp ? this.$t("button.onShelf") : this.$t("button.offShelf")}`,
        isConfirm: true,
      });
      if (!multiRes.valid) return;
      multiRes.operationLoading = true;
      const res = await upOrDownProduct({
        isUp,
        productNos: multiRes.currentTab.selectedProductItem.map((it) => it.productNo),
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        multiRes.operationLoading = false;
        return;
      }
      showMsg({ flag: FETCH_CODE.SUCCESS.KEY, msg: this.$t("showMsg.success.operation") });
      multiRes.currentTab.selectedProduct = [];
      multiRes.operationLoading = false;
      this.getList();
    },
    async onOnShelf(record) {
      const currentTab = this.tabList.find((it) => it.key === this.activeTab);
      if (!currentTab) return;
      if (currentTab.operationLoading) return;
      currentTab.operationLoading = true;
      const res = await upperProduct({
        id: record.id,
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        currentTab.operationLoading = false;
        return;
      }
      showMsg({ flag: FETCH_CODE.SUCCESS.KEY, msg: this.$t("showMsg.success.operation") });
      currentTab.operationLoading = false;
      this.getList();
    },
    async onOffShelf(record) {
      const currentTab = this.tabList.find((it) => it.key === this.activeTab);
      if (!currentTab) return;
      if (currentTab.operationLoading) return;
      currentTab.operationLoading = true;
      const res = await lowerProduct({
        id: record.id,
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        currentTab.operationLoading = false;
        return;
      }
      showMsg({ flag: FETCH_CODE.SUCCESS.KEY, msg: this.$t("showMsg.success.operation") });
      currentTab.operationLoading = false;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.ellipsis {
  .multi-hidden(2);
}
.footer-btns {
  display: inline-flex;
  justify-content: flex-end;
  width: calc(100% - 102px);
  & > * {
    margin-left: @ec-gutter;
  }
}
.product-list {
  &_table {
     border: 1px solid #e8e8e8;
     border-radius: 8px;
    .table-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: @ec-gutter;
      border-bottom: 1px solid #e8e8e8;
      &_title {
        font-weight: bold;
        font-size: 16px;
      }
    }
  }
}
/deep/.ec-card {
  border: none !important;
  margin-bottom: 0 !important;
}
</style>
