// 售后类型
export const ORDER_AFTER_SALES = [
  {
    dictTypeName: "售后类型",
    dictType: "order_after_sales",
    dictCode: 1,
    dictName: "仅退款",
  },
  {
    dictTypeName: "售后类型",
    dictType: "order_after_sales",
    dictCode: 2,
    dictName: "退货退款",
  },
];

// 售后订单状态
export const ORDER_AFTER_SALES_STATUS = [
  {
    dictTypeName: "申请状态",
    dictType: "order_after_sales_status",
    dictCode: 1,
    dictName: "待审核",
  },
  {
    dictTypeName: "申请状态",
    dictType: "order_after_sales_status",
    dictCode: 2,
    dictName: "已通过",
  },
  {
    dictTypeName: "申请状态",
    dictType: "order_after_sales_status",
    dictCode: 3,
    dictName: "已拒绝",
  },
  {
    dictTypeName: "申请状态",
    dictType: "order_after_sales_status",
    dictCode: 4,
    dictName: "已取消",
  },
];
