import api from "@/utils/fetch";
import { queryUrlFormatter } from "@/utils/tools";

// 商品分类列表接口
export async function getCategory(params) {
  return await api.post("/product/categroy/page", params);
}

// 商品分类新增、编辑接口
export async function editCategory(params) {
  return await api.post("/product/categroy/save", params);
}

// 删除商品分类
export async function deleteCategory(params) {
  let url = "/product/categroy/del";
  url = queryUrlFormatter(url, {
    id: params.id,
  });
  return await api.post(url, params);
}

// 导出商品分类
export async function exportCategory(params) {
  return await api.download("/product/categroy/export", params);
}

// 商品规格列表分页接口
export async function getSpecList(params) {
  return await api.post("/product/attr/page", params);
}

// 删除商品规格
export async function deleteSpec(params) {
  let url = "/product/attr/del";
  url = queryUrlFormatter(url, {
    id: params.id,
  });
  return await api.post(url, params);
}

// 保存商品规格
export async function editSpec(params) {
  return await api.post("/product/attr/save", params);
}

// 商品列表
export async function getProduct(params) {
  return await api.post("/product/page", params);
}

// 商品详情
export async function getProductDetail(params) {
  return await api.get("/product/detail", params);
}

// 删除商品
export async function delProduct(params) {
  let url = "/product/del";
  url = queryUrlFormatter(url, {
    id: params.id,
  });
  return await api.post(url, params);
}

// 批量删除商品
export async function delProductBatch(params) {
  let url = "/product/batch/del";
  url = queryUrlFormatter(url, params);
  return await api.post(url, params);
}

// 批量设置推荐商品
export async function recommendProductBatch(params) {
  let url = "/product/recommend/batch/set";
  return await api.post(url, params);
}

// 上架商品
export async function upperProduct(params) {
  let url = "/product/upper";
  url = queryUrlFormatter(url, params);
  return await api.post(url, params);
}

// 下架商品
export async function lowerProduct(params) {
  let url = "/product/lower";
  url = queryUrlFormatter(url, params);
  return await api.post(url, params);
}

// 批量上下架商品
export async function upOrDownProduct(params) {
  let url = "/product/batch/upOrDown";
  return await api.post(url, params);
}

// 保存商品
export async function saveProduct(params) {
  const url = "/product/save";
  return await api.post(url, params);
}

// 删除促销标签
export async function delPromotion(params) {
  let url = "/product/promotion/del";
  url = queryUrlFormatter(url, { id: params.id });
  return await api.post(url, params);
}

// 促销标签分页列表
export async function pagePromotion(params) {
  const url = "/product/promotion/page";
  return await api.post(url, params);
}

// 促销标签列表
export async function listPromotion(params) {
  const url = "/product/promotion/list";
  return await api.post(url, params);
}

// 促销标签保存
export async function savePromotion(params) {
  const url = "/product/promotion/save";
  return await api.post(url, params);
}

/**
 * 查询商品列表-分页（客户端）
 * @param params
 * @param {number} params.pageNum
 * @param {number} params.pageSize
 * @param {string=} params.brandCode
 * @param {string=} params.categoryCode
 * @param {boolean=} params.isRecommend
 * @param {boolean=} params.isRefund
 * @param {string=} params.productType
 * @param {string=} params.search
 * @param {string=} params.sort
 * @param {boolean=} params.state
 * @param {string=} params.supplierCode
 * @param {boolean=} params.upAndDownShelfState
 * @return {Promise<{flag: (0|1|2), msg: string, data: *}>}
 */
export function clientProduct(params) {
  const url = "/product/client/page";
  return api.post(url, params);
}
