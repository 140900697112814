import api from "@/utils/fetch";

// 快递公司列表
export async function getExpressList(params) {
  return await api.get("/sys/express/list", params);
}
// 快递资费列表
export async function getExpressCostList(params) {
  return await api.get("/sys/express/cost/list", params);
}

// 快递资费新增
export async function addExpressCost(params) {
  return await api.post("/sys/express/cost/add", params);
}

// 快递资费修改
export async function editExpressCost(params) {
  return await api.post("/sys/express/cost/update", params);
}

// 快递资费详情
export async function getExpressCostDetail(params) {
  return await api.get("/sys/express/cost", params);
}

// 快递资费删除
export async function removeExpressCost(params) {
  return await api.post("/sys/express/cost/delete", params);
}

// 获取所有省市区
export function getAllArea(params) {
  return api.get("/sys/area/findAll", params);
}
