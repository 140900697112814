<template>
  <div class="product-promotion-wrapper">
    <a-form-model
      ref="formRef"
      :model="form"
      :rules="rules"
      class="product-promotion-form"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 18 }"
    >
      <!--#region 选择促销标签 -->
      <a-row v-if="!isCancel">
        <a-col :span="24">
          <a-form-model-item
            :label="$t('productManagement.promotionalLabel.list.title')"
            prop="promotionId"
          >
            <a-select
              :getPopupContainer="trigger => trigger.parentNode"
              v-model="form.promotionId"
              :placeholder="$t('productManagement.promotionalLabel.form.name.placeholderSelect')"
            >
              <a-select-option
                v-for="op in promotionList"
                :key="op.id"
                :value="op.id"
              >
                {{ op.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
      <!--#endregion -->
      <!--#region 所选商品列表 -->
      <a-list
        class="product-promotion-product-list"
        item-layout="vertical"
        :data-source="productList"
      >
        <a-list-item slot="renderItem" slot-scope="item">
          <a-list-item-meta :description="getDescStr(item)">
            <div slot="title">{{ item.productName }}</div>
            <a-avatar
              slot="avatar"
              :src="item.pictureUrl"
            />
          </a-list-item-meta>
          <ul
            class="product-promotion-tag-list"
            v-if="item.promotionList && item.promotionList.length"
          >
            <li v-for="tag in item.promotionList" :key="tag.id">
              <a-tag color="blue"> {{ tag.name }} </a-tag>
            </li>
          </ul>
        </a-list-item>
      </a-list>
      <!--#endregion -->
    </a-form-model>
  </div>
</template>

<script>
import { listPromotion } from "@/pages/product/api";
import { FETCH_CODE } from "@/config";

const Empty = {
  promotionId: undefined,
};

export default {
  name: "PromotionProduct",
  props: {
    productList: {
      type: Array,
      required: true,
    },
    isCancel: Boolean,
  },
  data() {
    return {
      form: { ...Empty },
      rules: {
        promotionId: [
          { required: true, message: this.$t("productManagement.promotionalLabel.form.name.required") }
        ],
      },
      promotionList: [],
    };
  },
  methods: {
    onValid() {
      const result = { flag: FETCH_CODE.SUCCESS.KEY, data: this.form, msg: "" };
      return new Promise((resolve) => {
        this.$refs.formRef.validate((valid, errors) => {
          if (!valid) {
            result.flag = FETCH_CODE.WARNING.KEY;
            result.msg = Object.values(errors)[0]?.[0]?.message;
            resolve(result);
            return;
          }
          resolve(result);
        });
      });
    },
    async getPromotionList() {
      const res = await listPromotion();
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        return;
      }
      this.promotionList = res.data;
    },
    getDescStr(record) {
      const arr = [
        {
          label: this.$t('productManagement.product.list.classification'),
          value: record.categoryName,
        },
        {
          label: this.$t('productManagement.product.list.number'),
          value: record.productNo,
        },
      ];
      if (record.promotionList && record.promotionList.length) {
        arr.push({
          label: this.$t('productManagement.promotionalLabel.list.title'),
          value: record.promotionList.map((it) => it.name),
          list: true,
        });
      }
      return arr.map((it) => `${it.label}: ${it.value}`).join("; ");
    },
  },
  mounted() {
    if (this.isCancel) return;
    this.getPromotionList();
  },
};
</script>

<style lang="less" scoped>
.product-promotion {
  &-wrapper {}
  &-tag-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 48px;
    margin: 0;
  }
}
</style>
