<template>
  <ECEditPage>
    <div class="supplier-edit" :class="lang">
      <a-form-model
        ref="supplierForm"
        layout="inline"
        :model="form"
        :rules="formRules"
        :colon="false"
        labelAlign="right"
        :labelCol="calcFormItemLayout([8, 9]).labelCol"
        :wrapperCol="calcFormItemLayout([8, 9]).wrapperCol"
      >
        <div class="supplier-title">
          {{ $t("supplierManagement.supplier.form.info") }}
        </div>
        <a-row class="filter-form-inner" :gutter="gutter">
          <a-col :span="8">
            <a-form-model-item
              :label="$t('supplierManagement.supplier.form.supplierName')"
              prop="supplierName"
            >
              <a-input
                v-model="form.supplierName"
                :placeholder="
                  $t('supplierManagement.supplier.form.supplierNamePlaceholder')
                "
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item
              :label="$t('supplierManagement.supplier.form.supplierCode')"
              prop="supplierCode"
            >
              <a-input
                v-model="form.supplierCode"
                :placeholder="
                  $t('supplierManagement.supplier.form.supplierCodePlaceholder')
                "
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item
              :label="$t('supplierManagement.supplier.form.supplierNameSimple')"
              prop="supplierShortName"
            >
              <a-input
                v-model="form.supplierShortName"
                :placeholder="
                  $t(
                    'supplierManagement.supplier.form.supplierNameSimplePlaceholder'
                  )
                "
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-row :gutter="5">
              <a-col :span="16">
                <a-form-model-item
                  :label="$t('supplierManagement.supplier.form.province')"
                  class="supplier-area"
                  prop="province"
                  :labelCol="{ span: 12 }"
                >
                  <a-select
                    :getPopupContainer="trigger => trigger.parentNode"
                    size="large"
                    v-model="form.province"
                    :placeholder="
                      $t('supplierManagement.supplier.form.provincePlaceholder')
                    "
                    allowClear
                    @change="onProvinceSelect"
                  >
                    <a-select-option
                      v-for="op in provinceList"
                      :key="op.code"
                      :value="op.code"
                    >
                      {{ op.name }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="8">
                <a-form-model-item label="" class="supplier-area" prop="city">
                  <a-select
                    :getPopupContainer="trigger => trigger.parentNode"
                    size="large"
                    v-model="form.city"
                    :placeholder="
                      $t('supplierManagement.supplier.form.cityPlaceholder')
                    "
                    allowClear
                    @change="onCitySelect"
                  >
                    <a-select-option
                      v-for="op in cityList"
                      :key="op.code"
                      :value="op.code"
                    >
                      {{ op.name }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-col>
          <a-col :span="8">
            <a-form-model-item
              :label="$t('supplierManagement.supplier.form.paymentMethod')"
              prop="settlementType"
            >
              <a-input-number
                :placeholder="
                  $t(
                    'supplierManagement.supplier.form.paymentMethodPlaceholder'
                  )
                "
                v-model="form.settlementType"
                :min="0"
                :max="99999999"
                :precision="0"
                allowClear
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <div class="supplier-title">
          {{ $t("supplierManagement.supplier.form.rAddress") }}
        </div>
        <a-row class="filter-form-inner" :gutter="gutter">
          <a-col :span="8">
            <a-form-model-item
              :label="$t('supplierManagement.supplier.form.returnPhone')"
              prop="returnPhone"
            >
              <a-input
                v-model="form.returnPhone"
                :placeholder="
                  $t('supplierManagement.supplier.form.returnPhonePlaceholder')
                "
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item
              :label="$t('supplierManagement.supplier.form.returnReceiver')"
              prop="returnRecipient"
            >
              <a-input
                v-model="form.returnRecipient"
                :placeholder="
                  $t(
                    'supplierManagement.supplier.form.returnReceiverPlaceholder'
                  )
                "
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item
              :label="$t('supplierManagement.supplier.form.returnAddress')"
              prop="returnAddress"
            >
              <a-input
                v-model="form.returnAddress"
                :placeholder="
                  $t(
                    'supplierManagement.supplier.form.returnAddressPlaceholder'
                  )
                "
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <div class="supplier-title">
          {{ $t("supplierManagement.supplier.form.company") }}
        </div>
        <a-row class="filter-form-inner" :gutter="gutter">
          <a-col :span="8">
            <a-form-model-item
              :label="$t('supplierManagement.supplier.form.icon')"
              class="supplier-logo-form-item"
              prop="icon"
              :labelCol="{ span: 12 }"
            >
              <ec-form-upload
                v-model="form.icon"
                :format="['image/jpeg', 'image/jpg', 'image/png']"
                :fileList.sync="fileList"
                :size-max="51200"
                @change="onFileUploadChange"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="16">
            <a-col :span="12" class="col-padding mr10">
              <a-form-model-item
                :label="$t('supplierManagement.supplier.form.detailAddress')"
                prop="contactAddress"
              >
                <a-input
                  v-model="form.contactAddress"
                  :placeholder="
                    $t(
                      'supplierManagement.supplier.form.detailAddressPlaceholder'
                    )
                  "
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="12" class="col-padding">
              <a-form-model-item
                :label="$t('supplierManagement.supplier.form.contactEmail')"
                prop="contactEmail"
              >
                <a-input
                  v-model="form.contactEmail"
                  :placeholder="
                    $t(
                      'supplierManagement.supplier.form.contactEmailPlaceholder'
                    )
                  "
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="12" class="col-padding mr10">
              <a-form-model-item
                :label="$t('supplierManagement.supplier.form.contactPerson')"
                prop="contact"
              >
                <a-input
                  v-model="form.contact"
                  :placeholder="
                    $t(
                      'supplierManagement.supplier.form.contactPersonPlaceholder'
                    )
                  "
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="12" class="col-padding">
              <a-form-model-item
                :label="$t('supplierManagement.supplier.form.contactPhone')"
                prop="contactPhone"
              >
                <a-input
                  v-model="form.contactPhone"
                  :placeholder="
                    $t(
                      'supplierManagement.supplier.form.contactPhonePlaceholder'
                    )
                  "
                />
              </a-form-model-item>
            </a-col>
            <a-form-model-item
              :label="$t('supplierManagement.supplier.form.businessScope')"
              prop="businessScope"
              :labelCol="{ span: 4 }"
              class="col-span-2-form-item"
            >
              <a-textarea
                v-model="form.businessScope"
                :placeholder="
                  $t(
                    'supplierManagement.supplier.form.businessScopePlaceholder'
                  )
                "
                :auto-size="{ minRows: 4, maxRows: 4 }"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <div class="supplier-title">
          {{ $t("supplierManagement.supplier.form.financial") }}
        </div>
        <a-row class="filter-form-inner" :gutter="gutter">
          <a-col :span="8">
            <a-form-model-item
              :label="$t('supplierManagement.supplier.form.taxNumber')"
              prop="invoiceNo"
            >
              <a-input
                v-model="form.invoiceNo"
                :placeholder="
                  $t('supplierManagement.supplier.form.taxNumberPlaceholder')
                "
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item
              :label="$t('supplierManagement.supplier.form.invoiceHead')"
              prop="invoiceHeader"
            >
              <a-input
                v-model="form.invoiceHeader"
                :placeholder="
                  $t('supplierManagement.supplier.form.invoiceHeadPlaceholder')
                "
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item
              :label="$t('supplierManagement.supplier.form.bank')"
              prop="invoiceBank"
            >
              <a-input
                v-model="form.invoiceBank"
                :placeholder="
                  $t('supplierManagement.supplier.form.bankPlaceholder')
                "
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row class="filter-form-inner" :gutter="gutter">
          <a-col :span="8">
            <a-form-model-item
              :label="$t('supplierManagement.supplier.form.bankNumber')"
              prop="invoiceBankNo"
            >
              <a-input
                v-model="form.invoiceBankNo"
                :placeholder="
                  $t('supplierManagement.supplier.form.bankNumberPlaceholder')
                "
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item
              :label="$t('supplierManagement.supplier.form.financePhoneNumber')"
              prop="invoicePhone"
            >
              <a-input
                v-model="form.invoicePhone"
                :placeholder="
                  $t(
                    'supplierManagement.supplier.form.financePhoneNumberPlaceholder'
                  )
                "
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item
              :label="$t('supplierManagement.supplier.form.companyAddress')"
              prop="invoiceUnitAddress"
            >
              <a-input
                v-model="form.invoiceUnitAddress"
                :placeholder="
                  $t(
                    'supplierManagement.supplier.form.companyAddressPlaceholder'
                  )
                "
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <div class="supplier-title">
          {{ $t("supplierManagement.supplier.form.customInfo") }}
        </div>
        <a-row class="filter-form-inner" :gutter="gutter">
          <a-col :span="8">
            <a-form-model-item
              :label="$t('supplierManagement.supplier.form.adminAccount')"
              prop="admin"
            >
              <a-input
                v-model="form.admin"
                :placeholder="
                  $t('supplierManagement.supplier.form.adminAccountPlaceholder')
                "
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item
              :label="$t('supplierManagement.supplier.form.principal')"
              prop="directorUserId"
            >
              <a-select
                :getPopupContainer="trigger => trigger.parentNode"
                size="large"
                v-model="form.directorUserId"
                :placeholder="
                  $t('supplierManagement.supplier.form.principalPlaceholder')
                "
                allowClear
              >
                <a-select-option
                  v-for="op in personList"
                  :key="op.userId"
                  :value="op.userId"
                >
                  {{ op.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item
              :label="$t('supplierManagement.supplier.form.supplierLevel')"
              prop="supplierLevel"
            >
              <a-select
                :getPopupContainer="trigger => trigger.parentNode"
                size="large"
                v-model="form.supplierLevel"
                :placeholder="
                  $t(
                    'supplierManagement.supplier.form.supplierLevelPlaceholder'
                  )
                "
                allowClear
              >
                <a-select-option
                  v-for="op in supplierLevelList"
                  :key="op.dictCode"
                  :value="op.dictCode"
                >
                  {{ op.dictName }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row class="filter-form-inner" :gutter="gutter">
          <a-col :span="8">
            <a-form-model-item
              :label="$t('supplierManagement.supplier.form.signingTime')"
              prop="signTime"
            >
              <a-date-picker
                size="large"
                :show-time="true"
                allowClear
                v-model="form.signTime"
                :inputReadOnly="true"
                :placeholder="
                  $t('supplierManagement.supplier.form.signingTimePlaceholder')
                "
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="16">
            <a-form-model-item
              :label="$t('supplierManagement.supplier.form.remark')"
              prop="remark"
              :label-col="{ span: 4 }"
              class="col-span-2-form-item"
            >
              <a-input
                v-model="form.remark"
                :placeholder="
                  $t('supplierManagement.supplier.form.remarkPlaceholder')
                "
              >
              </a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <div class="filter-btn">
        <a-button @click="onReset" class="reset-btn">
          {{ $t("button.reset") }}
        </a-button>
        <a-button type="primary" @click="onSubmit" class="search-btn">
          {{ $t("button.submit") }}
        </a-button>
      </div>
    </div>
  </ECEditPage>
</template>

<script>
import ECEditPage from "@/components/ECEditPage";
import EcFormUpload from "@/components/EcFormUpload";
import { getArea, saveSupplier } from "@/pages/supplier/api";
import { DateFormatFull, FETCH_CODE, REG_MOBILE } from "@/config";
import { showMsg } from "@/utils";
import { listUser } from "@/pages/system/api";
import { SupplierLevel } from "@/pages/supplier/config";
import { cloneDeep } from "lodash";
import moment from "moment";
import { langMixin } from "@/mixins";

export default {
  components: {
    ECEditPage,
    EcFormUpload
  },
  mixins: [langMixin],
  data() {
    return {
      form: {
        icon: null,
        province: undefined,
        city: undefined
      },
      formRules: {
        supplierName: [
          {
            required: true,
            message: this.$t(
              "supplierManagement.supplier.form.supplierNameRequired"
            )
          },
          {
            max: 100,
            message: this.$t(
              "supplierManagement.supplier.form.supplierNameMax",
              { length: 100 }
            )
          }
        ],
        supplierCode: [
          {
            required: true,
            message: this.$t(
              "supplierManagement.supplier.form.supplierCodeRequired"
            )
          },
          {
            max: 100,
            message: this.$t(
              "supplierManagement.supplier.form.supplierCodeMax",
              { length: 100 }
            )
          }
        ],
        supplierShortName: [
          {
            required: true,
            message: this.$t("supplierManagement.supplier.form.supplierNameSimpleRequired"),
          },
          {
            max: 100,
            message: this.$t(
              "supplierManagement.supplier.form.supplierNameSimpleMax",
              { length: 100 }
            )
          }
        ],
        province: [
          {
            required: true,
            message: this.$t(
              "supplierManagement.supplier.form.provinceRequired"
            )
          }
        ],
        city: [
          {
            required: true,
            message: this.$t("supplierManagement.supplier.form.cityRequired")
          }
        ],
        settlementType: [
          {
            required: true,
            message: this.$t(
              "supplierManagement.supplier.form.paymentMethodRequired"
            )
          }
        ],
        returnPhone: [
          {
            required: true,
            message: this.$t("supplierManagement.supplier.form.returnPhoneRequired"),
          },
          {
            pattern: REG_MOBILE,
            message: this.$t("supplierManagement.supplier.form.returnPhoneReg")
          }
        ],
        returnRecipient: [
          {
            required: true,
            message: this.$t("supplierManagement.supplier.form.returnReceiverRequired"),
          },
          {
            max: 100,
            message: this.$t(
              "supplierManagement.supplier.form.returnReceiverMax",
              { length: 100 }
            )
          }
        ],
        returnAddress: [
          {
            required: true,
            message: this.$t("supplierManagement.supplier.form.returnAddressRequired"),
          },
          {
            max: 100,
            message: this.$t(
              "supplierManagement.supplier.form.returnAddressMax",
              { length: 100 }
            )
          }
        ],
        icon: [
          // {
          //   required: true,
          //   message: this.$t("supplierManagement.supplier.form.iconRequired"),
          // },
        ],
        contactAddress: [
          {
            required: true,
            message: this.$t(
              "supplierManagement.supplier.form.detailAddressRequired"
            )
          },
          {
            max: 500,
            message: this.$t(
              "supplierManagement.supplier.form.detailAddressMax",
              { length: 500 }
            )
          }
        ],
        contactEmail: [
          {
            required: true,
            message: this.$t(
              "supplierManagement.supplier.form.contactEmailRequired"
            )
          },
          {
            max: 100,
            message: this.$t(
              "supplierManagement.supplier.form.contactEmailMax",
              { length: 100 }
            )
          },
          {
            type: "email",
            message: this.$t(
              "supplierManagement.supplier.form.contactEmailType"
            )
          }
        ],
        contact: [
          {
            required: true,
            message: this.$t(
              "supplierManagement.supplier.form.contactPersonRequired"
            )
          },
          {
            max: 100,
            message: this.$t(
              "supplierManagement.supplier.form.contactPersonMax",
              { length: 100 }
            )
          }
        ],
        contactPhone: [
          {
            required: true,
            message: this.$t(
              "supplierManagement.supplier.form.contactPhoneRequired"
            )
          },
          {
            max: 50,
            message: this.$t(
              "supplierManagement.supplier.form.contactPhoneMax",
              { length: 50 }
            )
          }
        ],
        businessScope: [
          {
            max: 500,
            message: this.$t(
              "supplierManagement.supplier.form.businessScopeMax",
              { length: 500 }
            )
          }
        ],
        invoiceNo: [
          {
            required: true,
            message: this.$t("supplierManagement.supplier.form.taxNumberRequired"),
          },
          {
            max: 100,
            message: this.$t("supplierManagement.supplier.form.taxNumberMax", {
              length: 100
            })
          }
        ],
        invoiceHeader: [
          {
            required: true,
            message: this.$t("supplierManagement.supplier.form.invoiceHeadRequired"),
          },
          {
            max: 100,
            message: this.$t(
              "supplierManagement.supplier.form.invoiceHeadMax",
              { length: 100 }
            )
          }
        ],
        invoiceBank: [
          {
            required: true,
            message: this.$t("supplierManagement.supplier.form.bankRequired"),
          },
          {
            max: 100,
            message: this.$t("supplierManagement.supplier.form.bankMax", {
              length: 100
            })
          }
        ],
        invoiceBankNo: [
          {
            required: true,
            message: this.$t("supplierManagement.supplier.form.bankNumberRequired"),
          },
          {
            max: 100,
            message: this.$t("supplierManagement.supplier.form.bankNumberMax", {
              length: 100
            })
          }
        ],
        invoicePhone: [
          {
            required: true,
            message: this.$t("supplierManagement.supplier.form.financePhoneNumberRequired"),
          },
          {
            max: 50,
            message: this.$t(
              "supplierManagement.supplier.form.financePhoneNumberMax",
              { length: 50 }
            )
          }
        ],
        invoiceUnitAddress: [
          {
            required: true,
            message: this.$t("supplierManagement.supplier.form.companyAddressRequired"),
          },
          {
            max: 500,
            message: this.$t(
              "supplierManagement.supplier.form.companyAddressMax",
              { length: 500 }
            )
          }
        ],
        admin: [
          {
            required: true,
            message: this.$t("supplierManagement.supplier.form.adminAccountRequired"),
          },
          {
            max: 100,
            message: this.$t(
              "supplierManagement.supplier.form.adminAccountMax",
              { length: 100 }
            )
          }
        ],
        directorUserId: [
          {
            required: true,
            message: this.$t("supplierManagement.supplier.form.principalRequired"),
          },
        ],
        supplierLevel: [
          {
            required: true,
            message: this.$t("supplierManagement.supplier.form.supplierLevelRequired"),
          },
        ],
        signTime: [
          {
            required: true,
            message: this.$t("supplierManagement.supplier.form.signingTimeRequired"),
          },
        ],
        remark: [
          {
            max: 500,
            message: this.$t("supplierManagement.supplier.form.remarkMax", {
              length: 500
            })
          }
        ]
      },
      labelCol: {
        span: 3
      },
      wrapperCol: {
        span: 6
      },
      fileList: [],
      provinceList: [], //省份列表
      cityList: [], // 城市列表
      supplierLevelList: cloneDeep(SupplierLevel), // 供应商级别列表
      personList: [],
      loading: false,
      gutter: [12, 24]
    };
  },
  computed: {
    isEdit() {
      return !!this.$route.params.id;
    }
  },
  methods: {
    async onFileUploadChange(fileList) {
      const file = fileList[0];
      if (!file || !file.response) return;
      const { data } = file.response;
      if (!data) return;
      this.form.icon = data;
      this.$refs.supplierForm.validateField("icon");
    },
    onReset() {
      this.form = {
        ...this.form,
        icon: null,
        province: undefined,
        city: undefined,
        supplierName: null,
        supplierShortName: null,
        settlementType: null,
        returnPhone: null,
        returnRecipient: null,
        returnAddress: null,
        contactAddress: null,
        zipCode: null,
        contact: null,
        contactPhone: null,
        businessScope: null,
        invoiceNo: null,
        invoiceHeader: null,
        invoiceBank: null,
        invoiceBankNo: null,
        invoicePhone: null,
        invoiceUnitAddress: null,
        admin: null,
        directorUserId: null,
        supplierLevel: null,
        signTime: null,
        remark: null,
        sort: 1,
      };
      this.fileList = [];
    },
    onSubmit() {
      if (this.loading) return;
      this.$refs.supplierForm.validate(async valid => {
        if (!valid) return;
        this.loading = true;
        const params = {
          ...this.form,
          suppilerTerritory: `${this.form.province},${this.form.city}`,
          signTime: this.form.signTime ? moment(this.form.signTime).format(DateFormatFull) : "",
          state: true
        };
        const res = await saveSupplier(params);
        if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
          showMsg(res);
          this.loading = false;
          return;
        }
        let msg = this.$t("supplierManagement.supplier.form.success.add");
        if (this.isEdit) {
          msg = this.$t("supplierManagement.brand.form.success.edit");
        }
        showMsg({
          flag: 0,
          msg
        });
        this.loading = false;
        this.onBack();
      });
    },
    onBack() {
      this.$router.back();
    },
    async getProvinceList() {
      const res = await getArea({});
      this.provinceList = res.data || [];
    },
    async getCityList(isReset = true) {
      this.cityList = [];
      if (isReset) {
        this.form.city = undefined;
      }
      const res = await getArea({
        parentCode: this.form.province
      });
      this.cityList = res.data || [];
    },
    onProvinceSelect(value) {
      if (!value) {
        this.form.province = undefined;
        this.form.city = undefined;
        this.cityList = [];
        return;
      }
      this.form.province = value;
      this.getCityList();
    },
    onCitySelect(value) {
      console.log(value);
      if (!value) {
        this.form.city = undefined;
        return;
      }
      this.form.city = value;
    },
    onInit() {
      const { id } = this.$route.params;
      if (!id) return;
      const form = {
        id: id,
        ...this.$route.params
      };
      if (form.icon) {
        this.fileList = [
          {
            uid: "-1",
            name: "icon.png",
            status: "done",
            url: form.icon
          }
        ];
      }
      if (form.suppilerTerritory) {
        const ls = form.suppilerTerritory.split(",");
        form.province = +ls[0];
        form.city = +ls[1];
      }

      this.form = form;
      if (this.form.city) {
        // 请求城市列表
        this.getCityList(false);
      }
    },
    async getPersonList() {
      const res = await listUser({
        userName: "",
        phone: ""
      });
      this.personList = res.data || [];
    }
  },
  mounted() {
    this.getProvinceList();
    this.getPersonList();
    this.onInit();
  }
};
</script>

<style lang="less" scoped>
.supplier-edit {
  .supplier-title {
    font-size: 18px;
    font-weight: bold;
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    margin-left: -25px;
    &:not(&:first-child) {
      margin-top: 18px;
    }
    &::before {
      content: " ";
      display: block;
      width: 3px;
      height: 18px;
      background: @primary-color;
      margin-right: 5px;
    }
  }
  &.en {
    /deep/.ant-form-item {
      &.col-span-2-form-item {
        .ant-form-item-label {
          width: 19%;
        }
      }
    }
  }
}
/deep/.ant-form-item {
  display: flex;
  margin-right: 0;
}
/deep/.ant-form-item-control-wrapper {
  flex: 1;
}
/deep/.ant-form-item-label {
  // width: 24%;
  text-align: right;
  flex-wrap: nowrap;
}
.filter-form-inner {
  display: flex;
  flex-wrap: wrap;
}
.supplier-area {
  display: flex;
  flex-wrap: nowrap;
}
/deep/.supplier-area .ant-form-item-children {
  display: flex;
  flex-wrap: nowrap;
}
.filter-btn {
  text-align: center;
  margin-top: @ec-gutter20 * 2;
}
.col-padding {
  padding: 0 !important;
  &:not(&:last-child) {
    margin-bottom: 8px;
  }
}
.mr10 {
  /deep/.ant-form-item-control-wrapper {
    margin-right: @ec-gutter;
  }
}
.reset-btn,
.search-btn {
  width: 146px;
}
.search-btn {
  margin-left: 20px;
}
.supplier-logo-form-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  /deep/.ant-form-item-control-wrapper {
    margin: 0 auto;
  }
}
</style>
