import api from "@/utils/fetch";
import { queryUrlFormatter } from "@/utils/tools";

// 批量设置促销标签
export function setPromotionProduct(params) {
  return api.post("/product/promotion/batch/set", params);
}

// 批量取消促销标签
export function cancelPromotionProduct(params) {
  let url = "/product/promotion/batch/cancel";
  url = queryUrlFormatter(url, params);
  return api.post(url, params);
}
