<template>
  <div class="order-detail-title-wrapper">
    <h5 class="order-detail-title-top">
      {{ name }}
    </h5>
    <div class="order-detail-title-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderDetailTitle",
  props: {
    name: String,
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="less" scoped>
.order-detail-title {
  &-wrapper {
    width: 100%;
    margin-bottom: 30px;
  }
  &-top {
    height: 26px;
    line-height: 26px;
    font-size: 18px;
    font-weight: 400;
    color: #293B58;
    position: relative;
    padding-left: 14px;
    margin-bottom: 0;

    &::before {
      content: "";
      display: block;
      width: 4px;
      height: 16px;
      border-radius: 2px;
      position: absolute;
      z-index: 1;
      background: @primary-color;
      left: 0;
      top: 5px;
    }
  }
  &-content {
    margin-top: 15px;
  }
}
</style>
