<template>
  <div class="product-edit">
    <a-form-model
      ref="productBrand"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item
        ref="brandCode"
        :label="$t('supplierManagement.brand.form.brandCode.label')"
        prop="brandCode"
      >
        <a-input
          v-model="form.brandCode"
          :placeholder="$t('supplierManagement.brand.form.brandCode.placeholder')"
        />
      </a-form-model-item>
      <a-form-model-item
        ref="brandName"
        :label="$t('supplierManagement.brand.form.brandName.label')"
        prop="brandName"
        class="product-form-item-tips"
      >
        <a-input
          v-model="form.brandName"
          :placeholder="$t('supplierManagement.brand.form.brandName.placeholder')"
        />
        <div class="product-form-tips">
          <a-icon type="exclamation-circle" theme="filled" class="ec-color-warning" />
          品牌名称作为专属品牌分类使用
        </div>
      </a-form-model-item>
      <a-form-model-item
        ref="website"
        :label="$t('supplierManagement.brand.form.website.label')"
        prop="website"
      >
        <a-input
          v-model="form.website"
          :placeholder="$t('supplierManagement.brand.form.website.placeholder')"
        />
      </a-form-model-item>
      <a-form-model-item
        ref="icon"
        :label="$t('supplierManagement.brand.form.icon.label')"
        prop="icon"
        class="product-form-item-tips"
      >
        <ec-form-upload
          :format="['image/jpeg', 'image/jpg', 'image/png']"
          :fileList.sync="fileList"
          :size-max="51200"
          @change="onFileUploadChange"
        />
        <div class="product-form-tips file">
          <a-icon type="exclamation-circle" theme="filled" class="ec-color-warning" />
          {{ $t("file.formatLimit", { format: "jpg/png", size: "50kb" }) }}
        </div>
      </a-form-model-item>
      <!-- <a-form-model-item
        ref="sort"
        :label="$t('supplierManagement.brand.form.sort.label')"
        prop="sort"
      >
        <a-input-number
          v-model="form.sort"
          :max="999999"
          :placeholder="$t('supplierManagement.brand.form.sort.placeholder')"
        />
      </a-form-model-item> -->
      <a-form-model-item
        ref="state"
        :label="$t('supplierManagement.brand.form.state.label')"
        prop="state"
      >
        <a-switch v-model:checked="form.state" />
      </a-form-model-item>
      <a-form-model-item
        ref="isRecommend"
        :label="$t('supplierManagement.brand.form.isRecommend.label')"
        prop="isRecommend"
      >
        <a-switch v-model:checked="form.isRecommend" />
      </a-form-model-item>
      <a-form-model-item
        ref="remark"
        :label="$t('supplierManagement.brand.form.remark.label')"
        prop="remark"
      >
        <a-textarea
          v-model="form.remark"
          :auto-size="{ minRows: 3, maxRows: 5 }"
          :placeholder="$t('supplierManagement.brand.form.remark.placeholder')"
        />
      </a-form-model-item>
    </a-form-model>
    <div class="buttons">
      <a-button @click="reset">
        {{ $t("supplierManagement.brand.form.reset") }}
      </a-button>
      <a-button type="primary" @click="submit">
        {{ $t("supplierManagement.brand.form.submit") }}
      </a-button>
    </div>
  </div>
</template>

<script>
import { saveBrand } from "../api";
import { FETCH_CODE } from "@/config/fetch";
import { showMsg } from "@/utils";
import EcFormUpload from "@/components/EcFormUpload.vue";

export default {
  name: "ProductBrandEdit",
  components: { EcFormUpload },
  data() {
    return {
      form: {
        id: "",
        brandCode: "",
        brandName: "",
        remark: "",
        icon: null,
        sort: null,
        website: null,
        state: true,
        isRecommend: false,
      },
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },
      rules: {
        brandCode: [
          {
            required: true,
            message: this.$t("productManagement.spec.form.attributeName.validation.required"),
            trigger: "blur",
          },
          {
            max: 100,
            message: this.$t("productManagement.spec.form.attributeName.validation.max"),
            trigger: "blur",
          },
        ],
        brandName: [
          {
            required: true,
            message: this.$t("productManagement.spec.form.attributeName.validation.required"),
            trigger: "blur",
          },
          {
            max: 100,
            message: this.$t("productManagement.spec.form.attributeName.validation.max"),
            trigger: "blur",
          },
        ],
        website: [
          {
            max: 500,
            message: this.$t("productManagement.spec.form.website.validation.max"),
            trigger: "blur",
          },
        ],
        icon: [],
        /*sort: [
          {
            required: true,
            message: this.$t("productManagement.spec.form.sort.validation.required"),
            trigger: "blur",
          },
          {
            type: "number",
            message: this.$t("productManagement.spec.form.sort.validation.number"),
            trigger: "blur",
          },
        ],*/
        state: [
          {
            required: true,
            message:  this.$t("productManagement.spec.form.state.validation.required"),
            trigger: "blur",
          },
        ],
        isRecommend: [
          {
            required: true,
            message:  this.$t("productManagement.spec.form.isRecommend.validation.required"),
            trigger: "blur",
          },
        ],
        remark: [
          {
            max: 500,
            message: this.$t("productManagement.spec.form.remark.validation.max"),
            trigger: "blur",
          },
        ],
      },
      loading: false,
      fileList: [],
    };
  },
  methods: {
    submit() {
      if (this.loading) return;
      this.$refs.productBrand.validate(async (valid) => {
        if (valid) {
          this.loading = true;
          const res = await saveBrand({
            id: this.form.id,
            brandCode: this.form.brandCode,
            brandName: this.form.brandName,
            remark: this.form.remark,
            state: this.form.state,
            icon: this.form.icon,
            // sort: this.form.sort,
            isRecommend: this.form.isRecommend,
            website: this.form.website,
          });
          if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
            showMsg(res);
            this.loading = false;
            return;
          }
          let msg = this.$t("supplierManagement.brand.form.success.add");
          if (this.isEdit) {
            msg = this.$t("supplierManagement.brand.form.success.edit");
          }
          showMsg({
            flag: 0,
            msg,
          });
          this.loading = false;
          this.back();
        }
      });
    },
    back() {
      this.$router.back()
    },
    reset() {
      this.form = {
        ...this.form,
        brandCode: "",
        brandName: "",
        remark: "",
        state: true,
        isRecommend: false,
        icon: null,
        // sort: null,
        website: null,
      };
      this.fileList = [];
    },
    init() {
      const { id, state, isRecommend } = this.$route.params;
      if (!id) return;
      this.form = {
        id: id,
        brandCode: this.$route.params.brandCode,
        brandName: this.$route.params.brandName,
        remark: this.$route.params.remark,
        icon: this.$route.params.icon,
        // sort: this.$route.params.sort,
        website: this.$route.params.website,
        state: state === "1",
        isRecommend: isRecommend === "1",
      };
      if (this.form.icon) {
        this.fileList = [{
          uid: "-1",
          name: "icon.png",
          status: "done",
          url: this.form.icon,
        }];
      }
    },
    async onFileUploadChange(fileList) {
      const file = fileList[0];
      if (!file || !file.response) return;
      const { data } = file.response;
      if (!data) return;
      this.form.icon = data;
    },
  },
  computed: {
    isEdit () {
      return !!this.$route.params.id
    }
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="less" scoped>
.product-edit {
  padding-top: 60px;
}
.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  & > * {
    margin-right: @ec-gutter;
  }
}
.ant-input-number {
  width: 100%;
}
.product-form-tips {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  position: absolute;
  z-index: 1;
  right: 0;
  color: #A6AAB2;
  transform: translateX(104%);
  line-height: 1.2;
  max-width: 310px;
  /deep/.anticon {
    flex-shrink: 0;
    flex-grow: 0;
  }
}
.product-form-item-tips {
  /deep/.ant-form-item-children {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
</style>
