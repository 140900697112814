<template>
  <div class="after-sales">
    <div class="after-sales_head">
      <ListFilter
          ref="listFilterRef"
          :formList="formList"
          @filter="onSearch"
          @reset="onReset"
          :loading="loading"
      >
        <a-form-model-item
            slot="orderAmount"
            :label="$t('orderManagement.order.list.filters.orderAmount.label')"
        >
          <div class="order-list-filters-amount">
            <a-input
                size="large"
                v-model="form.orderAmountMin"
                :placeholder="$t('orderManagement.order.list.filters.orderAmount.placeholderMin')"
                allowClear
            />
            -
            <a-input
                size="large"
                v-model="form.orderAmountMax"
                :placeholder="$t('orderManagement.order.list.filters.orderAmount.placeholderMax')"
                allowClear
            />
          </div>
        </a-form-model-item>
        <a-form-model-item
            slot="state"
            :label="$t('orderManagement.order.list.filters.state.label')"
        >
          <a-select
              :getPopupContainer="trigger => trigger.parentNode"
              size="large"
              v-model="form.state"
              :placeholder="$t('orderManagement.order.list.filters.state.placeholder')"
              allowClear
          >
            <a-select-option
                v-for="op in orderStateList"
                :key="op.dictCode"
                :value="op.dictCode"
            >
              {{ op.dictName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
            slot="productType"
            :label="$t('orderManagement.order.list.filters.productType.label')"
        >
          <a-select
              :getPopupContainer="trigger => trigger.parentNode"
              size="large"
              v-model="form.productType"
              :placeholder="$t('orderManagement.order.list.filters.productType.placeholder')"
              allowClear
          >
            <a-select-option
                v-for="op in productTypeList"
                :key="op.dictCode"
                :value="op.dictCode"
            >
              {{ op.dictName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </ListFilter>
    </div>
    <ECTable
        showTitle
        :title="$t('orderManagement.aftersales.list.title')"
        :columns="tableColumns"
        :data-source="dataList"
        bordered
        rowKey="id"
        :loading="tableLoading"
        :pagination="{ total, current, pageSize }"
        @change="onTablePageChange">
      <!-- 操作列 -->
      <template slot="action" slot-scope="text, record">
        <div class="table-operations">
          <a-popconfirm
              :title="$t('button.confirmAgree', { msg: record.typeName })"
              :ok-text="$t('button.yes')"
              :cancel-text="$t('button.no')"
              @confirm="onSubmit(true, record)"
              :disabled="record.status !== ORDER_AFTER_SALES_STATUS[0].dictCode"
          >
            <a-button
                type="link"
                class="table-btn"
                :disabled="record.status !== ORDER_AFTER_SALES_STATUS[0].dictCode"
            >
              {{ $t("button.agree") }}
            </a-button>
          </a-popconfirm>
          <a-popconfirm
              :title="$t('button.confirmRefuse', { msg: record.typeName })"
              :ok-text="$t('button.yes')"
              :cancel-text="$t('button.no')"
              @confirm="onSubmit(false, record)"
              :disabled="record.status !== ORDER_AFTER_SALES_STATUS[0].dictCode"
          >
            <a-button
                type="link"
                class="table-btn danger"
                :disabled="record.status !== ORDER_AFTER_SALES_STATUS[0].dictCode"
            >
              {{ $t("button.refuse") }}
            </a-button>
          </a-popconfirm>
        </div>
      </template>
    </ECTable>
  </div>
</template>

<script>
import ListFilter from "@/pages/home/components/ListFilter.vue"
import ECTable from "@/components/ECTable";
import {getDic} from "@/api";
import {getApplyList, refund} from "../api";
import {FETCH_CODE, DateFormatFull} from "@/config";
import {cloneDeep, trim} from "lodash";
import moment from "moment";
import {listDicFormatter} from "@/utils/tools";
import {ORDER_AFTER_SALES, ORDER_AFTER_SALES_STATUS} from "@/pages/order/config";
import {showMsg} from "@/utils";

export default {
  components: {
    ListFilter,
    ECTable
  },
  data() {
    return {
      formList: [
        {
          label: this.$t("orderManagement.order.list.filters.orderId.label"),
          key: "orderId",
          type: "input",
          placeholder: this.$t("orderManagement.order.list.filters.orderId.placeholder"),
        },
        {
          label: this.$t("orderManagement.order.list.filters.supplier.label"),
          key: "supplier",
          type: "input",
          placeholder: this.$t("orderManagement.order.list.filters.supplier.placeholder"),
        },
        {
          label: this.$t("orderManagement.order.list.filters.orderAmount.label"),
          type: "slot",
          slotName: "orderAmount",
        },
        {
          label: this.$t("orderManagement.order.list.filters.createTime.label"),
          key: "createTime",
          type: "time",
          showTime: false,
          placeholder: this.$t("orderManagement.order.list.filters.createTime.placeholder"),
        },
        {
          label: this.$t("orderManagement.order.list.filters.state.label"),
          type: "slot",
          slotName: "state",
        },
        {
          label: this.$t("orderManagement.order.list.filters.productName.label"),
          key: "productName",
          type: "input",
          placeholder: this.$t("orderManagement.order.list.filters.productName.placeholder"),
        },
        {
          label: this.$t("orderManagement.order.list.filters.name.label"),
          key: "name",
          type: "input",
          placeholder: this.$t("orderManagement.order.list.filters.name.placeholder"),
        },
        {
          label: this.$t("orderManagement.order.list.filters.phone.label"),
          key: "phone",
          type: "input",
          placeholder: this.$t("orderManagement.order.list.filters.phone.placeholder"),
        },
        {
          label: this.$t("orderManagement.order.list.filters.productType.label"),
          type: "slot",
          slotName: "productType",
        },
      ],
      loading: false,
      pending: false,
      form: {},
      orderStateList: [],
      productTypeList: [],
      dataList: [],
      tableColumns: [
        {
          title: this.$t("orderManagement.aftersales.list.cols.id"),
          dataIndex: "id",
          align: "left",
          width: 100,
        },
        {
          title: this.$t("orderManagement.aftersales.list.cols.orderId"),
          dataIndex: "orderId",
          align: "left",
          width: 200,
        },
        {
          title: this.$t("orderManagement.aftersales.list.cols.applyTime"),
          dataIndex: "createTime",
          align: "center",
          width: 180,
        },
        {
          title: this.$t("orderManagement.aftersales.list.cols.name"),
          dataIndex: "skuName",
          align: "left",
          width: 240,
        },
        {
          title: this.$t("orderManagement.aftersales.list.cols.money"),
          dataIndex: "payPrice",
          align: "right",
          width: 120,
        },
        {
          title: this.$t("orderManagement.aftersales.list.cols.reason"),
          dataIndex: "reason",
          align: "left",
          width: 200,
        },
        {
          title: this.$t("orderManagement.aftersales.list.cols.isReject"),
          dataIndex: "typeName",
          key: "typeName",
          align: "center",
          width: 100,
        },
        {
          title: this.$t("orderManagement.aftersales.list.cols.status"),
          dataIndex: "statusName",
          align: "center",
          key: "status",
          width: 100,
        },
        {
          title: this.$t("action"),
          dataIndex: "action",
          key: "action",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 130,
        },
      ],
      tableLoading: false,
      total: 0,
      current: 1,
      pageSize: 10,
      sort: undefined,
      ORDER_AFTER_SALES_STATUS: cloneDeep(ORDER_AFTER_SALES_STATUS),
    }
  },
  mounted() {
    this.getOrderStateList();
    this.getProductTypeList();
    this.getList();
  },
  methods: {
    onSearch(form) {
      this.current = 1;
      this.getList(form);
    },
    onReset() {
    },
    async getOrderStateList() {
      const res = await getDic({
        code: "order_state",
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) return;
      this.orderStateList = res.data;
    },
    async getProductTypeList() {
      const res = await getDic({
        code: "product_type",
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) return;
      this.productTypeList = res.data;
    },
    onTablePageChange(page) {
      this.current = page.current;
      this.pageSize = page.pageSize;
      if (page.sorter.order) {
        this.sort = `${page.sorter.field}_asc`;
        if (page.sorter.order === "descend") {
          this.sort = `${page.sorter.field}_desc`;
        }
      } else {
        this.sort = undefined;
      }
      this.getList()
    },
    async getList(filterForm = {}) {
      const res = await getApplyList({
        pageNum: this.current,
        pageSize: this.pageSize,
        // 排序规则
        sort: this.sort,
        // 	订单编号(精准)、商品名称（模糊）、sku名称（模糊）、商品编码（精准）、sku编码（精准）
        search: trim(filterForm.orderId) || trim(filterForm.productName),
        orderId: trim(filterForm.orderId),
        supplierCode: filterForm.supplier,
        minPayPoints: this.form.orderAmountMin ? +this.form.orderAmountMin : undefined,
        maxPayPoints: this.form.orderAmountMax ? +this.form.orderAmountMax : undefined,
        orderStartTime: filterForm.createTime ? moment(filterForm.createTime).startOf("date").format(DateFormatFull) : undefined,
        orderEndTime: filterForm.createTime ? moment(filterForm.createTime).endOf("date").format(DateFormatFull) : undefined,
        state: this.form.state,
        consignee: trim(filterForm.name) || trim(filterForm.phone),
        phone: trim(filterForm.phone),
        productType: this.form.productType,
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) return;
      this.dataList = await listDicFormatter(res.data?.records || [], [
        { code: ORDER_AFTER_SALES_STATUS, key: "status", local: true },
        { code: ORDER_AFTER_SALES, key: "type", local: true },
      ]);
    },
    async onSubmit (agree, record) {
      if (this.pending) return;
      this.pending = true;
      const res = await refund({
        orderId: record.orderId,
        status: agree ? ORDER_AFTER_SALES_STATUS[1].dictCode : ORDER_AFTER_SALES_STATUS[2].dictCode,
        remark: "订单管理后台售后列表页面操作",
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        this.pending = false;
        return;
      }
      showMsg({
        flag: FETCH_CODE.SUCCESS.KEY,
        msg: this.$t("showMsg.success.operation"),
      });
      this.$refs.listFilterRef.filter();
      this.pending = false;
    },
  }
}
</script>

<style lang="less" scoped>
.after-sales_head {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px 0px rgba(15,113,222,0.06);
  border: 1px solid #E9EBFB;
  padding: 40px;
  margin-bottom: 22px;
  border-radius: 8px;
}
.order-list-filters-amount {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;
}
.apply-status {
  color: @success-color;
}
.reject-status {
  color: @error-color;
}
</style>
