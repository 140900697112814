import { render, staticRenderFns } from "./AreaModal.vue?vue&type=template&id=506dcd0c&scoped=true"
import script from "./AreaModal.vue?vue&type=script&lang=js"
export * from "./AreaModal.vue?vue&type=script&lang=js"
import style0 from "./AreaModal.vue?vue&type=style&index=0&id=506dcd0c&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "506dcd0c",
  null
  
)

export default component.exports