<template>
  <a-modal
    class="user-select-company"
    v-model="visible"
    :title="$t('user.selectCompany')"
    @ok="onConfirm"
    @cancel="onClose"
    centered
    :maskClosable="false"
    destroyOnClose
  >
    <PromotionProduct
      ref="indexRef"
      :productList="productList"
      :is-cancel="isCancel"
    />
  </a-modal>
</template>

<script>
import PromotionProduct from "@/pages/product/PromotionProduct/Index.vue";
import { FETCH_CODE } from "@/config";
import { showMsg } from "@/utils";
import {
  cancelPromotionProduct,
  setPromotionProduct
} from "@/pages/product/PromotionProduct/api";

export default {
  name: "PromotionProductModal",
  components: { PromotionProduct },
  props: {
    visible: Boolean,
    productList: {
      type: Array,
      required: true,
    },
    isCancel: Boolean,
  },
  data() {
    return {
      pending: false,
    };
  },
  methods: {
    async onConfirm() {
      if (this.pending) return;
      this.pending = true;
      const valid = await this.$refs.indexRef.onValid();
      if (valid.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(valid);
        this.pending = false;
        return;
      }
      let res;
      if (this.isCancel) {
        res = await cancelPromotionProduct({
          productNos: this.productList.map((it) => it.productNo),
        });
      } else {
        res = await setPromotionProduct({
          promotionId: valid.data.promotionId,
          productNos: this.productList.map((it) => it.productNo),
        });
      }
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        this.pending = false;
        return;
      }
      showMsg({
        flag: FETCH_CODE.SUCCESS.KEY,
        msg: this.$t("showMsg.success.operation"),
      });
      this.pending = false;
      // 关闭弹窗
      this.onClose();
      this.$emit("reload");
    },
    onClose() {
      this.$emit("update:visible", false);
      this.$emit("close");
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped></style>
