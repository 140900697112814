<template>
  <ECEditPage>
    <a-form-model
      ref="productLabel"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
    <!-- 标签名称 -->
      <a-form-model-item
        :label="$t('productManagement.promotionalLabel.form.name.label')"
        prop="name"
      >
        <a-input
          v-model="form.name"
          :placeholder="
            $t('productManagement.promotionalLabel.form.name.placeholder')
          "
        />
      </a-form-model-item>
            <!-- 标签图片 -->
      <a-form-model-item
        ref="icon"
        :label="$t('productManagement.promotionalLabel.form.icon.label')"
        prop="icon"
        class="product-form-item-tips"
      >
        <ec-form-upload
          :format="['image/jpeg', 'image/jpg', 'image/png']"
          :fileList.sync="fileList"
          :size-max="51200"
          :number-max="1"
          @change="onFileUploadChange"
        />
        <div class="product-form-tips">
          <a-icon type="exclamation-circle" theme="filled" class="ec-color-warning" />
          {{ $t("file.formatLimit", { format: "jpg/png", size: "50kb" }) }}
        </div>
      </a-form-model-item>
      <!-- 标签分类 -->
      <a-form-model-item
        :label="$t('productManagement.promotionalLabel.form.promotionType.label')"
        prop="promotionType"
      >
        <a-select
          :getPopupContainer="trigger => trigger.parentNode"
          v-model="form.promotionType"
          :placeholder="
            $t('productManagement.promotionalLabel.form.promotionType.placeholder')
          "
        >
          <a-select-option
            :value="i.dictCode"
            v-for="i in typeList"
            :key="i.dictCode"
          >
            {{ i.dictName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <!-- 标签描述 -->
      <a-form-model-item
        ref="description"
        :label="$t('productManagement.promotionalLabel.form.remark.label')"
        prop="remark"
      >
        <a-textarea
          v-model="form.remark"
          :auto-size="{ minRows: 3, maxRows: 5 }"
          :placeholder="
            $t('productManagement.promotionalLabel.form.remark.placeholder')
          "
        />
      </a-form-model-item>
      <!-- 状态（true启用，false禁用） -->
      <a-form-model-item
          ref="description"
          :label="$t('productManagement.promotionalLabel.form.state.label')"
          prop="state"
      >
        <a-switch v-model:checked="form.state" />
      </a-form-model-item>
    </a-form-model>
     <div class="buttons">
      <a-button @click="back">
        {{ $t("productManagement.category.form.back") }}
      </a-button>
      <a-button @click="reset">
        {{ $t("productManagement.category.form.reset") }}
      </a-button>
      <a-button type="primary" @click="submit">
        {{ $t("productManagement.category.form.submit") }}
      </a-button>
    </div>
  </ECEditPage>
</template>
<script>
import ECEditPage from "@/components/ECEditPage"
import EcFormUpload from "@/components/EcFormUpload"
import {savePromotion} from "@/pages/product/api";
import {FETCH_CODE} from "@/config";
import {showMsg} from "@/utils";
import {getDic} from "@/api";
export default {
  components: {
    ECEditPage,
    EcFormUpload
  },
  data() {
    return {
      form: {
        state: true,
      },
      rules: {
        name: [
          { required: true, message: this.$t("productManagement.promotionalLabel.form.name.required") },
          { max: 100, message: this.$t("productManagement.promotionalLabel.form.name.max", { length: 100 }) },
        ],
        promotionType: [
          { required: true, message: this.$t("productManagement.promotionalLabel.form.promotionType.required") },
        ],
        remark: [
          { max: 500, message: this.$t("productManagement.promotionalLabel.form.remark.max", { length: 500 }) },
        ],
      },
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },
      fileList: [],
      typeList: [],
      loading: false,
    };
  },
  computed: {
    isEdit () {
      return !!this.$route.params.id;
    },
  },
  methods: {
    onFileUploadChange(fileList) {
      if (!fileList || !fileList.length) return;
      const pictureUrl = [];
      fileList.filter((file) => {
        return file.response && file.response.data;
      }).forEach((file) => {
        pictureUrl.push(file.response.data);
      });
      this.form.icon = pictureUrl.join(",");
    },
    back () {
      this.$router.back();
    },
    reset () {
      this.form = {
        ...this.form,
        name: null,
        icon: null,
        remark: null,
        state: true,
        promotionType: undefined,
      };
      this.fileList = [];
    },
    async submit() {
      if (this.loading) return;
      this.loading = true;
      const params = {
        ...this.form,
      };
      const res = await savePromotion(params);
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        this.loading = false;
        return;
      }
      let msg = this.$t("showMsg.success.add");
      if (this.isEdit) {
        msg = this.$t("showMsg.success.change");
      }
      showMsg({ flag: FETCH_CODE.SUCCESS.KEY, msg });
      this.loading = false;
      this.back();
    },
    init() {
      const { id, state } = this.$route.params;
      if (!id) return;
      this.form = {
        id: id,
        name: this.$route.params.name,
        promotionType: this.$route.params.promotionType,
        remark: this.$route.params.remark,
        icon: this.$route.params.icon,
        state: state === "1",
      };
      if (this.form.icon) {
        this.fileList = [{
          uid: "-1",
          name: "icon.png",
          status: "done",
          url: this.form.icon,
        }];
      }
    },
    async getTypeList() {
      const res = await getDic({ code: "promotion_type" });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        return;
      }
      this.typeList = res.data;
    },
  },
  mounted() {
    this.getTypeList();
    this.init();
  },
};
</script>
<style lang="less" scoped>
.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  & > * {
    margin-right: @ec-gutter;
  }
}
.product-form-tips {
  line-height: 1.2;
}
</style>
