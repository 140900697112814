import api from "@/utils/fetch";

export function getOrderListPage(params) {
  return api.post("/product/order/page", params);
}

export function getOrderDetail(params) {
  return api.post(`/product/order/get/${params.orderId}`, params);
}
export function getApplyList(params) {
  return api.get('sys/order/apply/list', params)
}


/**
 * 发货
 * @param params
 * @param {string?} params.delFlag 是否禁用（0 启用 1禁用)
 * @param {string?} params.expCode 运单号
 * @param {string?} params.expressId 快递公司id
 * @param {string?} params.id id
 * @param {string?} params.orderId 订单id
 * @param {string?} params.remark 备注
 * @returns {Promise<{flag: (0|1|2), msg: string, data: *}>}
 */
export function send(params) {
  return api.post(`/sys/order/send`, params);
}

/**
 * 确认退款
 * @param params
 * @param {string=} params.delFlag 是否禁用（0 启用 1禁用)
 * @param {string=} params.status 申请状态0 待审核 1 已通过 2 已拒绝
 * @param {string=} params.id id
 * @param {string=} params.orderId 订单id
 * @param {string=} params.remark 备注
 * @returns {Promise<{flag: (0|1|2), msg: string, data: *}>}
 */
export function refund(params) {
  return api.post(`/sys/order/refund`, params);
}

/**
 * 退货审核
 * @param params
 * @param {string=} params.orderId 订单id
 * @returns {Promise<{flag: (0|1|2), msg: string, data: *}>}
 */
export function orderAudit(params) {
  return api.post(`/sys/order/audit`, params);
}

/**
 * 快递公司列表
 * @returns {Promise<{flag: (0|1|2), msg: string, data: *}>}
 */
export function expressList(params) {
  return api.get(`/sys/express/list`, params);
}

/**
 * 根据code获取地址列表
 * @param params
 * @param {string[]} params.codes 编码(多个用,隔开)
 * @returns {Promise<{flag: (0|1|2), msg: string, data: *}>}
 */
export function areaListByCode(params) {
  return api.get("/sys/area/listByCode", params);
}
