<template>
  <div>
    <div class="search-head">
      <a-input
        size="large"
        v-model="searchName"
        :placeholder="$t('supplierManagement.coupon.list.search')"
        allowClear
        class="search-input"
        @keydown.enter="onSearch"
      />
      <a-button type="primary" @click="onSearch">
        {{ $t("button.filter") }}
      </a-button>
    </div>
    <ECTable
      showTitle
      :title="$t('supplierManagement.coupon.list.title')"
      :columns="tableColumns"
      :data-source="dataList"
      bordered
      rowKey="id"
      key="id"
      :loading="loading"
      :pagination="{ total, current, pageSize }"
      @change="handleTableChange"
    >
      <div slot="tableHeaderRight" class="table-buttons">
        <a-button type="primary" @click="onAdd">
          {{ $t("supplierManagement.coupon.list.add") }}
        </a-button>
      </div>
      <template slot="sort" slot-scope="text, record, index">
        {{ (current - 1) * pageSize + (index + 1) }}
      </template>
      <template slot="state" slot-scope="text, record">
        <span v-if="record.state === true">
           {{ $t("supplierManagement.coupon.list.enable") }}
        </span>
        <span class="ec-color-danger" v-else>
           {{ $t("supplierManagement.coupon.list.disabled") }}
        </span>
      </template>
      <template slot="icon" slot-scope="text">
        <img style="width: 30px;max-height: 60px;" :src="text" alt="" v-if="text" />
        <span v-else>--</span>
      </template>
      <!-- 操作列 -->
      <template slot="action" slot-scope="text, record">
        <div class="table-operations">
          <a-button
            type="link"
            class="table-btn danger"
            @click="onShelf(record, 3)"
            :disabled="![1].includes(record.status)"
          >
            <a-icon type="stop" />
            {{ $t("button.offShelf") }}
          </a-button>
          <a-button
            type="link"
            class="table-btn success"
            @click="onShelf(record, 1)"
            :disabled="![3].includes(record.status)"
          >
            <a-icon type="check-circle" />
            {{ $t("button.enable") }}
          </a-button>
          <a-button
            type="link"
            class="table-btn"
            @click="onEdit(record)"
          >
            <a-icon type="edit" />
            {{ $t("button.edit") }}
          </a-button>
        </div>
      </template>
    </ECTable>
  </div>
</template>

<script>
import ECTable from "@/components/ECTable";
import { pageCoupon, statusCoupon } from "../api";
import { FETCH_CODE } from "@/config";
import { showMsg } from "@/utils";
import { trim } from "lodash";
import { listDicFormatter } from "@/utils/tools";
import { Coupon_Status, Coupon_Type } from "@/pages/supplier/config";

export default {
  name: "SupplierCouponList",
  data() {
    return {
      searchName: "",
      dataList: [],
      tableColumns: [
        {
          title: this.$t("supplierManagement.coupon.list.cols.sort"),
          dataIndex: "sort",
          width: 75,
          scopedSlots: { customRender: "sort" },
          align: "center",
        },
        {
          title: this.$t("supplierManagement.coupon.list.cols.name"),
          dataIndex: "name",
          width: 150,
          align: "left",
        },
        {
          title: this.$t("supplierManagement.coupon.list.cols.couponId"),
          dataIndex: "couponId",
          width: 120,
          align: "center",
        },
        {
          title: this.$t("supplierManagement.coupon.list.cols.type"),
          dataIndex: "typeName",
          width: 100,
          align: "center",
        },
        {
          title: this.$t("supplierManagement.coupon.list.cols.total"),
          dataIndex: "total",
          width: 100,
          align: "right",
        },
        {
          title: this.$t("supplierManagement.coupon.list.cols.status"),
          dataIndex: "statusName",
          width: 100,
          align: "center",
        },
        {
          title: this.$t("supplierManagement.coupon.list.cols.discount"),
          dataIndex: "discount",
          width: 100,
          align: "left",
        },
        {
          title: this.$t("supplierManagement.coupon.list.cols.min"),
          dataIndex: "min",
          width: 100,
          align: "right",
        },
        {
          title: this.$t("supplierManagement.coupon.list.cols.days"),
          dataIndex: "days",
          width: 100,
          align: "right",
        },
        {
          title: this.$t("supplierManagement.coupon.list.cols.startTime"),
          dataIndex: "startTime",
          width: 140,
          align: "center",
        },
        {
          title: this.$t("supplierManagement.coupon.list.cols.endTime"),
          dataIndex: "endTime",
          width: 140,
          align: "center",
        },
        {
          title: this.$t("supplierManagement.coupon.list.cols.content"),
          dataIndex: "content",
          width: 200,
          align: "left",
        },
        {
          title: this.$t("table.createBy"),
          dataIndex: "createBy",
          width: 120,
          align: "left",
        },
        {
          title: this.$t("table.createTime"),
          dataIndex: "createTime",
          width: 160,
          align: "center",
        },
        {
          title: this.$t("table.updateBy"),
          dataIndex: "updateBy",
          width: 120,
          align: "left",
        },
        {
          title: this.$t("table.updateTime"),
          dataIndex: "updateTime",
          width: 160,
          align: "center",
        },
        {
          title: this.$t("action"),
          dataIndex: "action",
          key: "action",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 130,
          fixed: "right",
        },
      ],
      current: 1,
      pageSize: 10,
      total: 0,
      loading: false,
    };
  },
  components: {
    ECTable,
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      this.loading = true;
      const res = await pageCoupon({
        search: trim(this.searchName),
        pageNum: this.current,
        pageSize: this.pageSize,
      });
      this.dataList = await listDicFormatter(res.data.records || [], [
        { code: Coupon_Type, key: "type", local: true },
        { code: Coupon_Status, key: "status", local: true },
      ]);
      this.current = res.data.current;
      this.total = res.data.total;
      this.pageSize = res.data.size;
      this.loading = false;
    },
    onEdit(record) {
      this.$router.push({
        name: "CouponEdit",
        params: {
          ...record,
        },
      });
    },
    async onShelf(record, status) {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const res = await statusCoupon({
        id: record.id,
        // 状态 1正常；2 过期; 3下架
        status: status,
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        this.loading = false;
        return;
      }
      showMsg({ flag: 0, msg: this.$t("showMsg.success.operation") });
      this.loading = false;
      this.getList();
    },
    onAdd() {
      this.$router.push({
        name: "CouponAdd",
      });
    },
    onImport() {},
    onSearch() {
      this.current = 1;
      this.getList();
    },
    handleTableChange(page) {
      this.current = page.current;
      this.pageSize = page.pageSize;
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.search-head {
  padding: @ec-gutter20 0;
}
.search-input {
  width: 300px;
  margin-right: @ec-gutter;
}
.table-buttons {
  &> * {
    margin-left: @ec-gutter;
  }
}
.table-operations {
  display: flex;
  justify-content: center;
  align-items: center;

  .table-btn {
    &.danger {
      color: @error-color;

      &[disabled="disabled"] {
        color: @disabled-color;
      }
    }
    &.success {
      color: @success-color;

      &[disabled="disabled"] {
        color: @disabled-color;
      }
    }
  }
}
</style>
