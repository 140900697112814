<template>
  <div>
    <textarea :id="id" :value="content"></textarea>
  </div>
</template>

<script>
import tinymce from "tinymce";
import "tinymce/themes/silver";
import "tinymce/models/dom";
import "tinymce/icons/default/icons";
import "tinymce/plugins/advlist"; // 高级列表
import "tinymce/plugins/anchor"; // 锚点
import "tinymce/plugins/autolink"; // 自动链接
import "tinymce/plugins/autoresize"; // 编辑器高度自适应,注：plugins里引入此插件时，Init里设置的height将失效
// import "tinymce/plugins/autosave"; // 自动存稿
import "tinymce/plugins/charmap"; // 特殊字符
import "tinymce/plugins/code"; // 编辑源码
import "tinymce/plugins/codesample"; // 代码示例
import "tinymce/plugins/directionality"; // 文字方向
import "tinymce/plugins/fullscreen"; // 全屏
import "tinymce/plugins/help"; // 帮助
import "tinymce/plugins/importcss"; // 引入css
import "tinymce/plugins/insertdatetime"; // 插入日期时间
import "tinymce/plugins/link"; // 超链接
import "tinymce/plugins/lists"; // 列表插件
import "tinymce/plugins/media"; // 插入编辑媒体
import "tinymce/plugins/image"; // 插入图片
import "tinymce/plugins/nonbreaking"; // 插入不间断空格
import "tinymce/plugins/pagebreak"; // 插入分页符
import "tinymce/plugins/preview"; // 预览
import "tinymce/plugins/quickbars"; // 快速工具栏
import "tinymce/plugins/save"; // 保存
import "tinymce/plugins/searchreplace"; // 查找替换
import "tinymce/plugins/table"; // 表格
import "tinymce/plugins/template"; // 内容模板
import "tinymce/plugins/visualblocks"; // 显示元素范围
import "tinymce/plugins/visualchars"; // 显示不可见字符
import "tinymce/plugins/wordcount";
import { getFakeId } from "@/utils/tools";
import { uploadFile } from "@/api";
import { FETCH_CODE } from "@/config";
import { langMixin } from "@/mixins"; // 字数统计

const plugins = "preview searchreplace media autolink directionality visualblocks visualchars fullscreen image code codesample table pagebreak nonbreaking anchor insertdatetime advlist lists wordcount link"; // 插件 autosave
const toolbar = "undo | redo | insertdatetime | link | image | media | preview | fullscreen";

export default {
  name: "EcEditor",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    content: String
  },
  mixins: [langMixin],
  data() {
    return {
      myValue: "",
      id: getFakeId(),
      isRerender: false,
    };
  },
  methods: {
    onInit() {
      const config = {
        placeholder: this.$t("tinymcePlaceholder"),
        promotion: false,
        language_url: this.isChinese ? "/tinymce/langs/zh-Hans.js" : undefined, // 这个文件会放在下面
        language: this.isChinese ? "zh-Hans" : undefined,
        skin_url: "/tinymce/skins/ui/oxide",
        height: 400,
        toolbar,
        plugins,
        file_picker_types: "image",
        images_file_types: "jpeg,jpg,svg,webp,png,gif,bmp",
        images_upload_url: "/api/sys/file/uploadFile",
        images_upload_base_path: "",
        automatic_uploads: true,
        images_upload_credentials: true,
        images_upload_handler: this.onUpload,
        branding: false,
      };
      tinymce.init({
        ...config,
        selector: `#${this.id}`,
      });
    },
    onChange(e) {
      this.$emit("update:modelValue", e.target.value);
    },
    async onUpload(blobInfo) {
      const formData = new FormData();
      formData.append("file", blobInfo.blob(), blobInfo.filename());
      formData.append("name", blobInfo.filename());
      const res = await uploadFile(formData);
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        return Promise.reject(res.msg);
      }
      return Promise.resolve(res.data);
    },
    getValue() {
      return tinymce.get(this.id).getContent();
    },
    setValue(content) {
      tinymce.get(this.id).setContent(content);
    },
    getEditor() {
      return tinymce.get(this.id);
    },
  },
  mounted() {
    this.onInit();
  },
  destroyed() {
    tinymce.remove(`#${this.id}`)
  },
};
</script>

<style lang="less" scoped></style>
