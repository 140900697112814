<template>
  <ECEditPage>
    <div class="cost-edit">
      <a-form-model
        :model="form"
        ref="formRef"
        :rules="formRules"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 17 }"
      >
        <a-form-model-item
          :label="$t('express.cost.filter.supplier')"
          :wrapper-col="{ span: 8 }"
          prop="supplier"
        >
          <a-select
            :getPopupContainer="trigger => trigger.parentNode"
            size="large"
            v-model="form.supplier"
            :placeholder="$t('express.cost.filter.supplier')"
            allowClear
          >
            <a-select-option
              v-for="op in supplierList"
              :key="op.supplierCode"
              :value="op.supplierCode"
            >
              {{ op.supplierName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item
          :label="$t('express.cost.filter.template')"
          :wrapper-col="{ span: 8 }"
          prop="templateName"
        >
          <a-input
            :placeholder="$t('express.cost.filter.template')"
            v-model="form.templateName"
            :maxLength="20"
          />
        </a-form-model-item>
        <a-form-model-item :label="$t('express.cost.edit.area')">
          <ECTable
            rowKey="id"
            :pagination="false"
            :columns="columns"
            :data-source="dataList"
            :scroll="{ x: 500 }"
          >
            <template slot-scope="text, record, index" slot="area">
              <a-form-model-item :prop="`area-${index}`" style="margin-bottom: 0;">
                <a-input
                  readOnly
                  @click="onAreaEdit(record, index)"
                  :placeholder="$t('select')"
                  v-model="form[`area-${index}`]"
                />
              </a-form-model-item>
            </template>
            <template slot-scope="text, record, index" slot="amount">
              <a-form-model-item :prop="`amount-${index}`" style="margin-bottom: 0;">
                <a-auto-complete
                  :data-source="['10', '15', '20', '25', '50', '100']"
                  :placeholder="$t('input')"
                  @change="(e) => onAmountEdit(e, record, index)"
                  v-model="form[`amount-${index}`]"
                />
              </a-form-model-item>
            </template>
            <template slot-scope="text, record" slot="action">
              <div class="table-operations">
                <a-popconfirm
                  :title="$t('button.confirmDelete')"
                  :ok-text="$t('button.yes')"
                  :cancel-text="$t('button.no')"
                  @confirm="onDelete(record)"
                >
                  <a-button type="link" class="table-btn danger">
                    <a-icon type="delete" />
                    {{ $t("button.remove") }}
                  </a-button>
                </a-popconfirm>
              </div>
            </template>
          </ECTable>
          <a-button type="primary" ghost @click="onAdd">
            {{ $t("express.cost.edit.addArea") }}
          </a-button>
        </a-form-model-item>
      </a-form-model>
      <div class="detail-btn filter-btn">
        <a-button @click="onReset" class="reset-btn">
          {{ $t("button.reset") }}
        </a-button>
        <a-button type="primary" @click="onSubmit" class="search-btn">
          {{ $t("button.submit") }}
        </a-button>
      </div>
      <template v-if="modalVisible">
        <AreaModal
          :visible.sync="modalVisible"
          :selected="editAreaList"
          @ok="getArea"
        />
      </template>
    </div>
  </ECEditPage>
</template>

<script>
import ECEditPage from "@/components/ECEditPage";
import ECTable from "@/components/ECTable";
import { getSupplierAll } from "@/pages/supplier/api";
import AreaModal from "./AreaModal.vue";
import { trim } from "lodash";
import { getFakeId } from "@/utils/tools";

export default {
  components: {
    ECEditPage,
    ECTable,
    AreaModal
  },
  data() {
    return {
      supplierList: [],
      form: {
        supplier: undefined,
        templateName: ""
      },
      columns: [
        {
          dataIndex: "area",
          key: "area",
          title: this.$t("express.cost.edit.chargeArea"),
          scopedSlots: { customRender: "area" }
        },
        {
          key: "amount",
          dataIndex: "amount",
          title: this.$t("express.cost.edit.freight"),
          align: "right",
          width: 240,
          scopedSlots: { customRender: "amount" }
        },
        {
          key: "action",
          align: "center",
          width: 90,
          title: this.$t("action"),
          scopedSlots: { customRender: "action" }
        }
      ],
      dataList: [],
      modalVisible: false,
      editIndex: 0,
      editAreaList: [],
    };
  },
  computed: {
    formRules() {
      const rules = {
        supplier: [
          {
            required: true,
            message: this.$t("select") + this.$t("express.cost.filter.supplier")
          }
        ],
        templateName: [
          {
            required: true,
            message: this.$t("input") + this.$t("express.cost.filter.template")
          }
        ],
      };
      this.dataList.forEach((it, idx) => {
        rules[`area-${idx}`] = [
          {
            required: true,
            message: this.$t("select") + this.$t("express.cost.edit.area")
          }
        ];
        rules[`amount-${idx}`] = [
          {
            validator: (rule, value) => {
              const val = trim(value);
              if (!val) {
                return Promise.reject("请填写运费");
              }
              // 校验输入金额
              if (!/^(?!0\d)\d+(\.\d{1,2})?$/.test(val)) {
                return Promise.reject("输入最多2位小数的有效金额");
              }
              return Promise.resolve();
            }
          }
        ];
      });
      return rules;
    }
  },
  methods: {
    async getSupplier() {
      const res = await getSupplierAll();
      this.supplierList = res?.data || [];
    },
    onAdd() {
      this.addTableRow();
      this.editIndex = this.dataList.length - 1;
      this.editAreaList = [];
      this.modalVisible = true;
    },
    addTableRow() {
      this.dataList.push({
        key: getFakeId(),
        area: null,
        amount: null,
        areaList: [],
      });
    },
    onDelete() {},
    onReset() {
      this.$refs.formRef.resetFields();
      this.form = {
        supplier: undefined,
        templateName: "",
        area: []
      };
    },
    onBack() {
      this.$router.back();
    },
    onSubmit() {
      this.$refs.formRef.validate(async valid => {
        console.log(valid);
        if (!valid) return;
      });
    },
    getArea(list) {
      const showArea = list.map((it) => it.name).join("、");
      this.dataList[this.editIndex].areaList = list;
      this.dataList[this.editIndex].area = showArea;
      this.$set(this.form, `area-${this.editIndex}`, showArea);
      this.$refs.formRef.validateField(`area-${this.editIndex}`);
      this.modalVisible = false;
    },
    onAreaEdit(record, index) {
      this.editIndex = index;
      this.editAreaList = record.areaList;
      this.modalVisible = true;
    },
    onAmountEdit(e, record, index) {
      this.dataList[index].amount = trim(e);
    }
  },
  async mounted() {
    await this.getSupplier();
  }
};
</script>

<style lang="less" scoped>
.cost-edit {
  .filter-btn {
    & > button {
      width: 146px;
    }
  }
}
</style>
