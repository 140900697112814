<template>
  <div class="product-edit">
    <a-form-model
      ref="productSpec"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item
        ref="attributeName"
        :label="$t('productManagement.spec.form.attributeName.label')"
        prop="attributeName"
        class="product-form-attribute-name"
      >
        <a-input
          v-model="form.attributeName"
          :placeholder="$t('productManagement.spec.form.attributeName.placeholder')"
        />
        <div class="product-form-tips">
          <a-icon type="exclamation-circle" theme="filled" class="ec-color-warning" />
          {{$t('productManagement.spec.form.attributeName.note')}}
        </div>
      </a-form-model-item>
      <a-form-model-item
        :label="$t('productManagement.spec.form.state.label')"
        prop="state"
      >
        <a-select
          :getPopupContainer="trigger => trigger.parentNode"
          v-model="form.state"
          :placeholder="$t('productManagement.spec.form.state.placeholder')"
          allowClear
        >
          <a-select-option
            :value="i.value"
            v-for="i in stateList"
            :key="i.value"
          >
            {{ i.label }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item
        :label="$t('productManagement.spec.form.attributeType.label')"
        prop="attributeType"
      >
        <a-select
          :getPopupContainer="trigger => trigger.parentNode"
          v-model="form.attributeType"
          :placeholder="$t('productManagement.spec.form.attributeType.placeholder')"
          allowClear
          disabled
        >
          <a-select-option
            v-for="i in attrTypeList"
            :value="i.dictCode"
            :key="i.id"
          >
            {{ i.dictName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item
        :label="$t('productManagement.spec.form.attributeValueType.label')"
        prop="attributeValueType"
      >
        <a-select
          :getPopupContainer="trigger => trigger.parentNode"
          v-model="form.attributeValueType"
          :placeholder="$t('productManagement.spec.form.attributeValueType.placeholder')"
          allowClear
          disabled
        >
          <a-select-option
            v-for="i in attrValueTypeList"
            :value="i.dictCode"
            :key="i.id"
          >
            {{ i.dictName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item
        ref="sort"
        :label="$t('productManagement.spec.form.sort.label')"
        prop="sort"
      >
        <a-input-number
          v-model="form.sort"
          :max="999999"
          :placeholder="$t('productManagement.spec.form.sort.placeholder')"
        />
      </a-form-model-item>
      <a-form-model-item
        ref="isRequired"
        :label="$t('productManagement.spec.form.isRequired.label')"
        prop="isRequired"
      >
        <a-switch v-model:checked="form.isRequired" />
      </a-form-model-item>
      <a-form-model-item
        ref="isWholeLine"
        :label="$t('productManagement.spec.form.isWholeLine.label')"
        prop="isWholeLine"
      >
        <a-switch v-model:checked="form.isWholeLine" />
      </a-form-model-item>
      <a-form-model-item
        ref="remark"
        :label="$t('productManagement.spec.form.remark.label')"
        prop="remark"
      >
        <a-textarea
          v-model="form.remark"
          :auto-size="{ minRows: 3, maxRows: 5 }"
          :placeholder="$t('productManagement.spec.form.remark.placeholder')"
        />
      </a-form-model-item>
    </a-form-model>
    <div class="buttons">
      <a-button @click="reset">
        {{ $t("productManagement.spec.form.reset") }}
      </a-button>
      <a-button type="primary" @click="submit">
        {{ $t("productManagement.spec.form.submit") }}
      </a-button>
    </div>
  </div>
</template>

<script>
import { editSpec } from "../api";
import { FETCH_CODE } from "@/config/fetch";
import { showMsg } from "@/utils";
import { getDic } from "@/api";

export default {
  name: "ProductSpecEdit",
  data() {
    return {
      form: {
        id: "",
        attributeCode: "",
        attributeName: "",
        attributeType: "input",
        attributeValueType: "text",
        attributeParentCode: null,
        isRequired: false,
        isWholeLine: false,
        state: "1",
        remark: "",
        sort: null,
      },
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },
      rules: {
        attributeName: [
          {
            required: true,
            message: this.$t("productManagement.spec.form.attributeName.validation.required"),
            trigger: "blur",
          },
          {
            max: 100,
            message: this.$t("productManagement.spec.form.attributeName.validation.max"),
            trigger: "blur",
          },
        ],
        state: [
          {
            required: true,
            message:  this.$t("productManagement.spec.form.state.validation.required"),
            trigger: "blur",
          },
        ],
        attributeType: [
          {
            required: true,
            message:  this.$t("productManagement.spec.form.attributeType.validation.required"),
            trigger: "blur",
          },
        ],
        attributeValueType: [
          {
            required: true,
            message:  this.$t("productManagement.spec.form.attributeValueType.validation.required"),
            trigger: "blur",
          },
        ],
        sort: [
          {
            required: true,
            message: this.$t("productManagement.spec.form.sort.validation.required"),
            trigger: "blur",
          },
          {
            type: "number",
            message: this.$t("productManagement.spec.form.sort.validation.number"),
            trigger: "blur",
          },
        ],
        isRequired: [
          {
            required: true,
            message: this.$t("productManagement.spec.form.isRequired.validation.required"),
            trigger: "blur",
          },
        ],
        isWholeLine: [
          {
            required: true,
            message: this.$t("productManagement.spec.form.isWholeLine.validation.required"),
            trigger: "blur",
          },
        ],
        remark: [
          {
            max: 500,
            message: this.$t("productManagement.spec.form.remark.validation.max"),
            trigger: "blur",
          },
        ],
      },
      loading: false,
      stateList: [
        { value: "1", label: "启用" },
        { value: "0", label: "禁用" },
      ],
      attrTypeList: [],
      attrValueTypeList: [],
    };
  },
  methods: {
    submit() {
      if (this.loading) return;
      this.$refs.productSpec.validate(async (valid) => {
        if (valid) {
          this.loading = true;
          const params = {
            id: this.form.id,
            attributeCode: this.form.attributeCode,
            attributeName: this.form.attributeName,
            attributeType: this.form.attributeType,
            attributeValueType: this.form.attributeValueType,
            attributeParentCode: this.form.attributeParentCode,
            isRequired: this.form.isRequired,
            isWholeLine: this.form.isWholeLine,
            state: this.form.state === "1",
            remark: this.form.remark,
            sort: this.form.sort,
          };
          const currAttributeType = this.attrTypeList.find((it) => {
            return it.dictCode === params.attributeType;
          });
          params.attributeTypeName = currAttributeType?.dictName;
          const currAttributeValueType = this.attrValueTypeList.find((it) => {
            return it.dictCode === params.attributeValueType;
          });
          params.attributeValueTypeName = currAttributeValueType?.dictName;
          const res = await editSpec(params);
          if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
            showMsg(res);
            this.loading = false;
            return;
          }
          let msg = this.$t("productManagement.spec.form.success.add");
          if (this.isEdit) {
            msg = this.$t("productManagement.spec.form.success.edit");
          }
          showMsg({
            flag: 0,
            msg,
          });
          this.loading = false;
          this.back();
        }
      });
    },
    back() {
      this.$router.back()
    },
    reset() {
      this.form = {
        ...this.form,
        attributeName: "",
        attributeType: undefined,
        attributeValueType: undefined,
        state: "1",
        remark: "",
        sort: null,
      };
    },
    init() {
      const { id, state, isRequired, isWholeLine } = this.$route.params;
      if (!id) return;
      this.form = {
        id: id,
        attributeCode: this.$route.params.attributeCode,
        attributeName: this.$route.params.attributeName,
        attributeType: this.$route.params.attributeType,
        attributeValueType: this.$route.params.attributeValueType,
        attributeParentCode: this.$route.params.attributeParentCode,
        remark: this.$route.params.remark,
        sort: this.$route.params.sort,
        isRequired: isRequired === "1",
        isWholeLine: isWholeLine === "1",
        state: state,
      };
    },
    async getAttrType() {
      const res = await getDic({
        code: "attr_type",
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) return;
      this.attrTypeList = res.data.filter((it) => it.state);
    },
    async getAttrValueType() {
      const res = await getDic({
        code: "attr_value_type",
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) return;
      this.attrValueTypeList = res.data.filter((it) => it.state);
    },
  },
  computed: {
    isEdit () {
      return !!this.$route.params.id
    }
  },
  mounted() {
    this.getAttrType();
    this.getAttrValueType();
    this.init();
  },
};
</script>

<style lang="less" scoped>
.product-edit {
  padding-top: 60px;
}
.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  & > * {
    margin-right: @ec-gutter;
  }
}
.ant-input-number {
  width: 100%;
}
.product-form-tips {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  margin-right: 25px;
  position: absolute;
  z-index: 1;
  left: calc(100% + 10px);
  color: #A6AAB2;
  line-height: 1.2;
  min-width: 200px;
}
.product-form-attribute-name {
  /deep/.ant-form-item-children {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
</style>
