<template>
  <div>
    <ECTable :data-source="dataList" :columns="columns" :loading="loading" :pagination="false">
      <template slot="delFlag" slot-scope="text, record">
        <a-switch :checked="!record.delFlag" readonly />
      </template>
      <template slot="logo" slot-scope="text, record">
        <img :src="record.logo" class="express-img"/>
      </template>
      <template slot="index" slot-scope="text, record, index">
        {{ index }}
      </template>
    </ECTable>
  </div>
</template>

<script>
import ECTable from "@/components/ECTable";
import {getExpressList} from "@/pages/express/api";
import {FETCH_CODE} from "@/config";
import { showMsg } from "@/utils";

export default {
  name: "ExpressList",
  components: {ECTable},
  data() {
    return {
      dataList: [],
      columns: [
        {
          key: "index",
          title: this.$t("express.cost.cols.index"),
          align: "center",
          scopedSlots: { customRender: "index" }
        },
        {
          dataIndex: "name",
          align: "center",
          title: this.$t("express.company.list.cols.name"),
        },
        {
          dataIndex: "logo",
          title: "logo",
          align: "center",
          scopedSlots: { customRender: "logo" }
        },
        {
          dataIndex: "delFlag",
          align: "center",
          title: this.$t("express.company.list.cols.start"),
          scopedSlots: { customRender: "delFlag" }
        },
      ],
      loading: false
    };
  },
  methods: {
    getCompanyList () {
      if (this.loading) {
        return;
      }
      this.loading = true
      getExpressList().then(res => {
        if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
           showMsg({ flag: FETCH_CODE.ERROR.KEY, msg: res.msg })
          this.loading = false
          return;
        }
        this.dataList = res.data || []
        this.loading = false
      })
    }
  },
  mounted() {
    this.getCompanyList()
  },
};
</script>

<style lang="less" scoped>
.express-img {
  width: 50px;
  height: 50px;
  object-fit: contain;
}
</style>
