<template>
  <div class="product-edit">
    <a-form-model
        ref="productCategory"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
    >
      <a-form-model-item
          ref="categoryName"
          :label="$t('productManagement.category.form.categoryName.label')"
          prop="categoryName"
      >
        <a-input
            v-model="form.categoryName"
            :placeholder="$t('productManagement.category.form.categoryName.placeholder')"
        />
      </a-form-model-item>
      <a-form-model-item
          :label="$t('productManagement.category.form.categoryParentCode.label')"
          prop="categoryParentCode"
      >
        <a-select
            :getPopupContainer="trigger => trigger.parentNode"
            v-model="form.categoryParentCode"
            :placeholder="$t('productManagement.category.form.categoryParentCode.placeholder')"
            allowClear
        >
          <a-select-option
              :value="i.categoryCode"
              v-for="i in categoryList"
              :key="i.categoryCode"
          >
            {{ i.categoryName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item
          ref="sort"
          :label="$t('productManagement.category.form.sort.label')"
          prop="sort"
      >
        <a-input-number
            v-model="form.sort"
            :max="999999"
            :placeholder="$t('productManagement.category.form.sort.placeholder')"
        />
      </a-form-model-item>
      <a-form-model-item
          ref="state"
          :label="$t('productManagement.category.form.state.label')"
          prop="state"
      >
        <a-switch v-model:checked="form.state" />
      </a-form-model-item>
      <a-form-model-item
        ref="icon"
        :label="$t('productManagement.category.form.icon.label')"
        prop="icon"
        class="product-form-item-tips"
      >
        <ec-form-upload
          :format="['image/jpeg', 'image/jpg', 'image/png']"
          :fileList.sync="fileList"
          :size-max="51200"
          @change="onFileUploadChange"
        />
        <div class="product-form-tips">
          <a-icon type="exclamation-circle" theme="filled" class="ec-color-warning" />
          {{ $t("file.formatLimit", { format: "jpg/png", size: "50kb" }) }}
        </div>
      </a-form-model-item>
      <a-form-model-item
          ref="description"
          :label="$t('productManagement.category.form.description.label')"
          prop="description"
      >
        <a-textarea
            v-model="form.description"
            :auto-size="{ minRows: 3, maxRows: 5 }"
            :placeholder="$t('productManagement.category.form.description.placeholder')"
        />
      </a-form-model-item>
      <a-form-model-item
        ref="remark"
        :label="$t('productManagement.category.form.remark.label')"
        prop="remark"
      >
        <a-textarea
          v-model="form.remark"
          :auto-size="{ minRows: 3, maxRows: 5 }"
          :placeholder="$t('productManagement.category.form.remark.placeholder')"
        />
      </a-form-model-item>
    </a-form-model>
    <div class="buttons">
      <a-button @click="back">
        {{ $t("productManagement.category.form.back") }}
      </a-button>
      <a-button @click="reset">
        {{ $t("productManagement.category.form.reset") }}
      </a-button>
      <a-button type="primary" @click="submit">
        {{ $t("productManagement.category.form.submit") }}
      </a-button>
    </div>
  </div>
</template>

<script>
import { getCategory, editCategory } from "../api";
import { FETCH_CODE } from "@/config/fetch";
import { showMsg } from "@/utils";
import EcFormUpload from "@/components/EcFormUpload.vue";

export default {
  components: { EcFormUpload },
  data() {
    return {
      form: {
        id: "",
        categoryCode: "",
        categoryName: "",
        categoryParentCode: undefined,
        state: true,
        description: "",
        icon: null,
        remark: null,
        sort: 0,
        isLeaf: false,
      },
      fileList: [],
      categoryList: [],
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },
      rules: {
        categoryName: [
          {
            required: true,
            message: this.$t("productManagement.category.form.categoryName.validation.required"),
            trigger: "blur",
          },
          {
            max: 100,
            message: this.$t("productManagement.category.form.categoryName.validation.max"),
            trigger: "blur",
          },
        ],
        categoryParentCode: [],
        sort: [
          {
            required: true,
            message: this.$t("productManagement.category.form.sort.validation.required"),
            trigger: "blur",
          },
          {
            type: "number",
            message: this.$t("productManagement.category.form.sort.validation.number"),
            trigger: "blur",
          },
        ],
        state: [
          {
            required: true,
            message:  this.$t("productManagement.category.form.state.validation.number"),
            trigger: "blur",
          },
        ],
        description: [
          {
            max: 500,
            message: this.$t("productManagement.category.form.description.validation.max"),
            trigger: "blur",
          },
        ],
        remark: [
          {
            max: 500,
            message: this.$t("productManagement.category.form.remark.validation.max"),
            trigger: "blur",
          },
        ],
      },
      loading: false,
    };
  },
  methods: {
    submit() {
      if (this.loading) return;
      this.$refs.productCategory.validate(async (valid) => {
        if (valid) {
          this.loading = true;
          const res = await editCategory({
            id: this.form.id,
            categoryCode: this.form.categoryCode,
            categoryName: this.form.categoryName,
            categoryParentCode: this.form.categoryParentCode,
            sort: Number(this.form.sort),
            state: this.form.state,
            description: this.form.description,
            remark: this.form.remark,
            icon: this.form.icon,
            isLeaf: !!this.form.categoryParentCode,
          });
          if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
            showMsg(res);
            this.loading = false;
            return;
          }
          let msg = this.$t("productManagement.category.form.success.add");
          if (this.isEdit) {
            msg = this.$t("productManagement.category.form.success.edit");
          }
          showMsg({
            flag: 0,
            msg,
          });
          this.loading = false;
          this.back();
        }
      });
    },
    back() {
      this.$router.back()
    },
    reset() {
      this.form = {
        ...this.form,
        categoryName: "",
        description: "",
        remark: "",
        icon: null,
        categoryParentCode: undefined,
        state: true,
      };
      this.fileList = [];
    },
    async getCategoryList() {
      const res = await getCategory({
        categoryName: "",
        pageSize: 999999,
        pageNum: 1,
      });
      this.categoryList = res.data.records || [];
    },
    init() {
      const { id, state, categoryParentCode, isLeaf } = this.$route.params;
      if (!id) return;
      this.form = {
        id: id,
        sort: +this.$route.params.sort,
        categoryCode: this.$route.params.categoryCode,
        categoryName: this.$route.params.categoryName,
        categoryParentCode: categoryParentCode,
        description: this.$route.params.description,
        icon: this.$route.params.icon,
        remark: this.$route.params.remark,
        state: state === "1",
        isLeaf: isLeaf === "1",
      };
      if (this.form.icon) {
        this.fileList = [{
          uid: "-1",
          name: "icon.png",
          status: "done",
          url: this.form.icon,
        }];
      }
    },
    async onFileUploadChange(fileList) {
      const file = fileList[0];
      if (!file || !file.response) return;
      const { data } = file.response;
      if (!data) return;
      this.form.icon = data;
    },
  },
  computed: {
    isEdit () {
      return !!this.$route.params.id
    }
  },
  mounted() {
    this.getCategoryList();
    this.init();
  },
};
</script>

<style lang="less" scoped>
.product-edit {
  padding-top: 60px;
}
.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  & > * {
    margin-right: @ec-gutter;
  }
}
.ant-input-number {
  width: 100%;
}
.product-form-tips {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  position: absolute;
  z-index: 1;
  left: 130px;
  color: #A6AAB2;
  line-height: 1.2;
}
.product-form-item-tips {
  /deep/.ant-form-item-children {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
</style>
