<template>
  <div>
    <ListFilter
      :formList="formList"
      @filter="onSearch"
      @reset="onReset"
      :loading="loading"
    >
      <a-form-model-item
        slot="supplierCode"
        :label="$t('orderManagement.order.list.filters.supplier.label')"
      >
        <a-select
          :getPopupContainer="trigger => trigger.parentNode"
          size="large"
          v-model="form.supplierCode"
          :placeholder="
            $t('orderManagement.order.list.filters.supplier.placeholder')
          "
          allowClear
        >
          <a-select-option
            v-for="op in supplierList"
            :key="op.supplierCode"
            :value="op.supplierCode"
          >
            {{ op.supplierName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item
        slot="orderAmount"
        :label="$t('orderManagement.order.list.cols.payPrice')"
      >
        <div class="order-list-filters-amount">
          <a-input
            size="large"
            v-model="form.orderAmountMin"
            :placeholder="
              $t(
                'orderManagement.order.list.filters.orderAmount.placeholderMin'
              )
            "
            allowClear
          />
          -
          <a-input
            size="large"
            v-model="form.orderAmountMax"
            :placeholder="
              $t(
                'orderManagement.order.list.filters.orderAmount.placeholderMax'
              )
            "
            allowClear
          />
        </div>
      </a-form-model-item>
      <a-form-model-item
        slot="state"
        :label="$t('orderManagement.order.list.filters.state.label')"
      >
        <a-select
          :getPopupContainer="trigger => trigger.parentNode"
          size="large"
          v-model="form.state"
          :placeholder="
            $t('orderManagement.order.list.filters.state.placeholder')
          "
          allowClear
        >
          <a-select-option
            v-for="op in orderStateList"
            :key="op.dictCode"
            :value="op.dictCode"
          >
            {{ op.dictName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item
        slot="productType"
        :label="$t('orderManagement.order.list.filters.productType.label')"
      >
        <a-select
          :getPopupContainer="trigger => trigger.parentNode"
          size="large"
          v-model="form.productType"
          :placeholder="
            $t('orderManagement.order.list.filters.productType.placeholder')
          "
          allowClear
        >
          <a-select-option
            v-for="op in productTypeList"
            :key="op.dictCode"
            :value="op.dictCode"
          >
            {{ op.dictName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
    </ListFilter>
    <ECTable
      showTitle
      :title="$t('orderManagement.order.list.title')"
      :columns="tableColumns"
      :data-source="dataList"
      bordered
      rowKey="id"
      :loading="loading"
      :pagination="{ total, current, pageSize }"
      @change="onTablePageChange"
    >
      <template slot="index" slot-scope="text, item, index">
        {{ index + 1 }}
      </template>
      <template slot="payPrice" slot-scope="text, item">
        <!--福币支付-->
        <template v-if="item.payType === 'only_points'">
          {{ pointsShowFormatter(item.payPoints + item.serviceChargePoints)
          }}{{ platformConf.moneyName }}
        </template>
        <!--现金支付-->
        <template v-if="item.payType === 'only_price'">
          {{ pointsShowFormatter(item.payPrice) }}{{ $t("currency.price") }}
        </template>
        <!--福币+现金-->
        <ul v-if="item.payType === 'points_price'" style="margin: 0;">
          <li>
            {{ pointsShowFormatter(item.payPoints + item.serviceChargePoints)
            }}{{ platformConf.moneyName }}
          </li>
          <li>
            +{{ pointsShowFormatter(item.payPrice) }}{{ $t("currency.price") }}
          </li>
        </ul>
        <!--成长积分支付-->
        <template v-if="item.payType === 'growth_points'">
          {{ pointsShowFormatter(item.payGrowthPoints)
          }}{{ $t("currency.growthPoints") }}
        </template>
      </template>
      <!-- 操作列 -->
      <template slot="action" slot-scope="text, record">
        <a-button type="link" class="table-btn" @click="onEdit(record)">
          <a-icon type="eye" />
          {{ $t("button.detail") }}
        </a-button>
      </template>
    </ECTable>
  </div>
</template>

<script>
import ListFilter from "@/pages/home/components/ListFilter";
import ECTable from "@/components/ECTable";
import { getOrderListPage } from "./api";
import { DateFormatFull, FETCH_CODE } from "@/config";
import { getDic } from "@/api";
import moment from "moment";
import { trim } from "lodash";
import { pointsShowFormatter } from "@/utils/tools";
import { mapState } from "vuex";
import { getSupplierAll } from "@/pages/supplier/api";

export default {
  name: "OrderList",
  components: {
    ListFilter,
    ECTable
  },
  data() {
    return {
      formList: [
        {
          label: this.$t("orderManagement.order.list.filters.orderId.label"),
          key: "orderId",
          type: "input",
          placeholder: this.$t(
            "orderManagement.order.list.filters.orderId.placeholder"
          )
        },
        {
          label: this.$t("orderManagement.order.list.filters.supplier.label"),
          slotName: "supplierCode",
          type: "slot",
        },
        {
          label: this.$t(
            "orderManagement.order.list.filters.orderAmount.label"
          ),
          type: "slot",
          slotName: "orderAmount"
        },
        {
          label: this.$t("orderManagement.order.list.filters.createTime.label"),
          key: "createTime",
          type: "time",
          showTime: false,
          placeholder: this.$t(
            "orderManagement.order.list.filters.createTime.placeholder"
          )
        },
        {
          label: this.$t("orderManagement.order.list.filters.state.label"),
          type: "slot",
          slotName: "state"
        },
        {
          label: this.$t(
            "orderManagement.order.list.filters.productName.label"
          ),
          key: "productName",
          type: "input",
          placeholder: this.$t(
            "orderManagement.order.list.filters.productName.placeholder"
          )
        },
        {
          label: this.$t("orderManagement.order.list.filters.name.label"),
          key: "name",
          type: "input",
          placeholder: this.$t(
            "orderManagement.order.list.filters.name.placeholder"
          )
        },
        {
          label: this.$t("orderManagement.order.list.filters.phone.label"),
          key: "phone",
          type: "input",
          placeholder: this.$t(
            "orderManagement.order.list.filters.phone.placeholder"
          )
        },
        {
          label: this.$t(
            "orderManagement.order.list.filters.productType.label"
          ),
          type: "slot",
          slotName: "productType"
        }
      ],
      tableColumns: [
        {
          title: this.$t("orderManagement.order.list.cols.orderId"),
          dataIndex: "orderId",
          align: "left",
          width: 200,
          sorter: true
        },
        {
          title: this.$t("orderManagement.order.list.cols.createTime"),
          dataIndex: "createTime",
          align: "center",
          defaultSortOrder: "descend",
          width: 160,
          sorter: true
        },
        {
          title: this.$t("orderManagement.order.list.cols.payPrice"),
          dataIndex: "payPrice",
          align: "right",
          sorter: true,
          width: 150,
          scopedSlots: { customRender: "payPrice" }
        },
        {
          title: this.$t("orderManagement.order.list.cols.productName"),
          dataIndex: "productName",
          align: "left",
          width: 300,
        },
        {
          title: this.$t("orderManagement.order.list.cols.remark"),
          dataIndex: "remark",
          align: "left",
          width: 200,
        },
        {
          title: this.$t("orderManagement.order.list.cols.stateName"),
          dataIndex: "stateName",
          align: "center",
          width: 120,
        },
        {
          title: this.$t("orderManagement.order.list.cols.discountTypeName"),
          dataIndex: "discountTypeName",
          align: "center",
          width: 110,
        },
        {
          title: this.$t("action"),
          dataIndex: "action",
          key: "action",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 130
        }
      ],
      dataList: [],
      total: 0,
      current: 1,
      pageSize: 10,
      loading: false,
      form: {},
      orderStateList: [],
      productTypeList: [],
      // 排序方式 默认提交时间倒序
      sort: "createTime_desc",
      supplierList: [],
    };
  },
  computed: {
    ...mapState({
      platformConf: state => state.user.platformConf
    })
  },
  mounted() {
    this.getOrderStateList();
    this.getProductTypeList();
    this.getSupplierList();
    this.getList();
  },
  methods: {
    pointsShowFormatter,
    handleTableChange() {},
    onSearch(filterForm) {
      this.current = 1;
      this.getList(filterForm);
    },
    onReset() {
      this.form = {};
    },
    onTablePageChange(page) {
      this.current = page.current;
      this.pageSize = page.pageSize;
      if (page.sorter.order) {
        this.sort = `${page.sorter.field}_asc`;
        if (page.sorter.order === "descend") {
          this.sort = `${page.sorter.field}_desc`;
        }
      } else {
        this.sort = undefined;
      }
      this.getList();
    },
    onEdit(record) {
      this.$router.push({
        name: "OrderDetail",
        params: record
      });
    },
    async getList(filterForm = {}) {
      this.loading = true;
      const params = {
        pageNum: this.current,
        pageSize: this.pageSize,
        // 排序规则
        sort: this.sort,
        // 	订单编号(精准)、商品名称（模糊）、sku名称（模糊）、商品编码（精准）、sku编码（精准）
        search: trim(filterForm.orderId) || trim(filterForm.productName),
        orderId: trim(filterForm.orderId),
        supplierCode: this.form.supplierCode,
        minPayPoints: this.form.orderAmountMin
          ? +this.form.orderAmountMin
          : undefined,
        maxPayPoints: this.form.orderAmountMax
          ? +this.form.orderAmountMax
          : undefined,
        orderStartTime: filterForm.createTime
          ? moment(filterForm.createTime)
              .startOf("date")
              .format(DateFormatFull)
          : undefined,
        orderEndTime: filterForm.createTime
          ? moment(filterForm.createTime)
              .endOf("date")
              .format(DateFormatFull)
          : undefined,
        state: this.form.state,
        consignee: trim(filterForm.name) || trim(filterForm.phone),
        phone: trim(filterForm.phone),
        productType: this.form.productType
      };
      const res = await getOrderListPage(params);
      this.dataList = res.data.records || [];
      this.current = res.data.current;
      this.total = res.data.total;
      this.pageSize = res.data.size;
      this.loading = false;
    },
    async getOrderStateList() {
      const res = await getDic({
        code: "order_state"
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) return;
      this.orderStateList = res.data;
    },
    async getProductTypeList() {
      const res = await getDic({
        code: "product_type"
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) return;
      this.productTypeList = res.data;
    },
    async getSupplierList() {
      const res = await getSupplierAll();
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) return;
      this.supplierList = res.data;
    }
  }
};
</script>

<style lang="less" scoped>
.table-buttons {
  & > * {
    margin-left: @ec-gutter;
  }
}
.order-list-filters-amount {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;
}
</style>
