<template>
  <div>
    <div class="search-head">
      <a-input
        size="large"
        v-model="searchName"
        :placeholder="$t('productManagement.spec.list.search')"
        allowClear
        class="search-input"
        @keydown.enter="onSearch"
      />
      <a-button type="primary" @click="onSearch">
        {{ $t("button.filter") }}
      </a-button>
    </div>
    <ECTable
      showTitle
      :title="$t('productManagement.spec.list.title')"
      :columns="tableColumns"
      :data-source="dataList"
      bordered
      rowKey="id"
      key="id"
      :loading="loading"
      :pagination="{ total, current, pageSize }"
      @change="handleTableChange"
    >
      <div slot="tableHeaderRight" class="table-buttons">
        <a-button type="primary" @click="onAdd">
          {{ $t("productManagement.spec.list.add") }}
        </a-button>
      </div>
      <template slot="state" slot-scope="text, record">
        <span v-if="record.state === true">
           {{ $t("productManagement.spec.list.enable") }}
        </span>
        <span class="ec-color-danger" v-else>
           {{ $t("productManagement.spec.list.disabled") }}
        </span>
      </template>
      <template slot="sort" slot-scope="text, record, index">
        {{ index + 1 }}
      </template>
      <!-- 操作列 -->
      <template slot="action" slot-scope="text, record">
        <div class="table-operations">
          <a-button type="link" class="table-btn" @click="edit(record)">
            <a-icon type="edit" />
            {{ $t("button.edit") }}
          </a-button>
          <a-popconfirm
            :title="$t('button.confirmDelete')"
            :ok-text="$t('button.yes')"
            :cancel-text="$t('button.no')"
            @confirm="onDelete(record)"
          >
            <a-button type="link" class="table-btn danger">
              <a-icon type="delete" />
              {{ $t("button.remove") }}
            </a-button>
          </a-popconfirm>
        </div>
      </template>
    </ECTable>
  </div>
</template>

<script>
import ECTable from "@/components/ECTable";
import { deleteSpec, getSpecList } from "../api";
import { FETCH_CODE } from "@/config";
import { showMsg } from "@/utils";

export default {
  name: "ProductSpecList",
  data() {
    return {
      searchName: "",
      dataList: [],
      tableColumns: [
        {
          title: this.$t("productManagement.spec.list.cols.sort"),
          dataIndex: "sort",
          align: "center",
        },
        {
          title: this.$t("productManagement.spec.list.cols.attributeName"),
          dataIndex: "attributeName",
          align: "left",
        },
        {
          title: this.$t("productManagement.spec.list.cols.remark"),
          dataIndex: "remark",
          align: "left",
        },
        {
          title: this.$t("productManagement.spec.list.cols.attributeTypeName"),
          dataIndex: "attributeTypeName",
          align: "center",
        },
        {
          title: this.$t("productManagement.spec.list.cols.attributeValueTypeName"),
          dataIndex: "attributeValueTypeName",
          align: "center",
        },
        {
          title: this.$t("productManagement.spec.list.cols.state"),
          key: "state",
          scopedSlots: { customRender: "state" },
          align: "center",
        },
        {
          title: this.$t("productManagement.spec.list.cols.createTime"),
          dataIndex: "createTime",
          align: "center",
        },
        {
          title: this.$t("action"),
          dataIndex: "action",
          key: "action",
          align: "center",
          scopedSlots: { customRender: "action" },
          width: 130,
        },
      ],
      current: 1,
      pageSize: 10,
      total: 0,
      loading: false,
    };
  },
  components: {
    ECTable,
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const res = await getSpecList({
        attributeName: this.searchName,
        pageNum: this.current,
        pageSize: this.pageSize,
      });
      this.dataList = res.data.records || [];
      this.current = res.data.current;
      this.total = res.data.total;
      this.pageSize = res.data.size;
      this.loading = false;
    },
    edit(record) {
      this.$router.push({
        name: "ProductSpecEdit",
        params: {
          id: record.id,
          attributeCode: record.attributeCode,
          attributeName: record.attributeName,
          attributeType: record.attributeType,
          attributeValueType: record.attributeValueType,
          attributeParentCode: record.attributeParentCode,
          remark: record.remark,
          sort: record.sort,
          isRequired: record.isRequired ? "1" : "0",
          isWholeLine: record.isWholeLine ? "1" : "0",
          state: record.state ? "1" : "0",
        },
      });
    },
    async onDelete(record) {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const res = await deleteSpec({
        id: record.id,
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        this.loading = false;
        return;
      }
      showMsg({ flag: 0, msg: this.$t("showMsg.success.delete") });
      this.loading = false;
      this.getList();
    },
    onAdd() {
      this.$router.push({
        name: "ProductSpecAdd",
      });
    },
    onSearch() {
      this.current = 1;
      this.getList();
    },
    handleTableChange(page) {
      this.current = page.current;
      this.pageSize = page.pageSize;
      this.getList();
    },
  },
};
</script>
<style lang="less" scoped>
.search-head {
  padding: @ec-gutter20 0;
}
.search-input {
  width: 300px;
  margin-right: @ec-gutter;
}
.table-buttons {
  &> * {
    margin-left: @ec-gutter;
  }
}
.table-operations {
  display: flex;
  justify-content: center;
  align-items: center;

  .table-btn {
    &.danger {
      color: @error-color;
    }
  }
}
</style>
